
import { FilledInput, FormControl, InputLabel } from "@mui/material";
import { useCallback } from "react";
import { NumericFormat } from 'react-number-format';

const DEFAULT_EV = { target: { value: null } };

const SELECT_ON_FOCUS = (ev) => {
  ev?.target?.select();
};

const DECIMAL_SEPARATORS = [",", "."];

const ON_INPUT_REF = (ref) => {
  if (!!ref?.firstChild) {
    ref.firstChild.inputMode = "numeric";
  }
};

export default function TextFieldDecimal(props) {
  const { value, onChange, label, disabled, nullable, integer, decimal, selectOnFocus, prefix, suffix, fullWidth, thousandSeparator } = props;

  const handleChange = useCallback((values) => {
    const value = values.value;

    if (decimal) {
      onChange({ target: values });
    } else if (nullable && value === "") {
      onChange(DEFAULT_EV, null);
    } else {
      onChange(DEFAULT_EV, integer ? parseInt(value || "0") : Number(value || "0"));
    }

  }, [onChange, nullable, integer, decimal]);

  return (
    <FormControl
      variant="filled"
      size="small"
      disabled={disabled}
      fullWidth={fullWidth}
      onFocus={selectOnFocus ? SELECT_ON_FOCUS : undefined}
      inputMode="numeric"
    >
      <InputLabel>{label}</InputLabel>
      <NumericFormat
        value={value === null ? "" : value}
        thousandSeparator={thousandSeparator !== undefined ? thousandSeparator : " "}
        allowedDecimalSeparators={DECIMAL_SEPARATORS}
        decimalSeparator=","
        onValueChange={handleChange}
        customInput={FilledInput}
        decimalScale={integer ? 0 : undefined}
        prefix={prefix}
        suffix={suffix}
        getInputRef={ON_INPUT_REF}
      />
    </FormControl>
  );
}
