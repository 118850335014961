import { makeId } from "@emberly/zenith-client";
import axios from "axios";
import { ActivityEnums, MapIcons, MissionEnums, TaskEnums, TaskEnumsLists } from "./constants";
import moment from "moment/moment";
import { FixNumber, sanitizeNumber } from "./orders";
import Decimal from "decimal.js";
import { HaversineDistance } from "./maphelpers";
import { MakeActivity } from "./activity";


export function MapMissionTasks(mission) {
  return mission.salvageTasks.map((t, i) => ({
    ...t,
    id: `${mission.id}/${t.number}`,
    missionId: mission.id,
    missionNumber: mission.number,
    missionTarget: mission.target,
    missionActors: mission.actors,
    missionDetails: mission.details,
    missionState: mission.state,
    taskIndex: i,
    state: AssumeTaskStateEnum(t)
  }));
}


export function AllTasksCompleted(mission) {
  if (!mission) return true;

  const tasks = mission.salvageTasks;

  for (let i = 0; i < tasks.length; i++) {
    const state = AssumeTaskStateEnum(tasks[i]);
    if (state < TaskEnums.State.Completed) {
      return false;
    }
  }

  return true;
}

export function SetAllTasksCreated(user, mission, updateMissionField) {
  const time = moment.utc().toISOString();

  mission?.salvageTasks?.forEach((task, index) => {
    if (!task.execution.history.created) {
      updateMissionField(
        `salvageTasks.${index}.execution.history.created`,
        {
          created: time,
          lastModified: time,
          lastChangedBy: { id: user.sub, name: user.name }
        }
      );
    }
  });
}

export function MakeStateChange(user, geolocation = null) {

  const time = moment.utc().toISOString();

  return {
    created: time,
    lastModified: time,
    lastChangedBy: { id: user.sub, name: user.name },
    geolocation,
  };
}


export async function UpdateAssignedState(missionTask, assignedState, updateEntity, user, logEvent) {
  let salvageTasks = [];

  salvageTasks[missionTask.taskIndex] = {
    execution: {
      driverAssignedState: assignedState,
      history: {
        responded: MakeStateChange(user)
      }
    }
  };

  await updateEntity({ id: missionTask.missionId, salvageTasks }, [`salvageTasks.${missionTask.taskIndex}.execution.driverAssignedState`, `salvageTasks.${missionTask.taskIndex}.execution.history.responded`]);

  switch (assignedState) {
    case TaskEnums.DriverAssignedState.Accepted:
      logEvent(MakeActivity(ActivityEnums.Category.Mission, ActivityEnums.Type.AcceptedTask, missionTask.missionId, "", "", { id: missionTask.taskId, name: missionTask.taskName }));
      break;

    case TaskEnums.DriverAssignedState.Rejected:
      logEvent(MakeActivity(ActivityEnums.Category.Mission, ActivityEnums.Type.RejectedTask, missionTask.missionId, "", "", { id: missionTask.taskId, name: missionTask.taskName }));
      break;

    default:
      console.log("invalid assigned state");
      break;
  }
}


export function AssumeStorageState(mission) {
  if (!mission || !mission.storageTask) return MissionEnums.StorageState.None;
  if (!mission.storageTask.delivered) return MissionEnums.StorageState.Stored;
  return MissionEnums.StorageState.Delivered;
}

export function GetDaysStored(storageTask) {
  const storedTime = storageTask.stored;
  const deliveryTime = storageTask.deliveryTime;
  const days = !!storedTime ? moment(!!deliveryTime ? deliveryTime : undefined).add(1, "days").startOf("day").diff(moment(storedTime).startOf("day"), "days") : 0;
  return days <= 0 ? 0 : Math.max(0, days - 1);
}

export function GetStoragePrice(storageTask) {
  const daysElapsed = GetDaysStored(storageTask);
  return daysElapsed > 0 ? FixNumber(new Decimal(sanitizeNumber(storageTask.rate.value)).mul(daysElapsed)) : 0
}

export function AssumeTaskStateEnum(task) {
  const history = task?.execution?.history;

  if (!history) return TaskEnums.State.None;

  if (!!history.acknowledged) {
    return TaskEnums.State.Acknowledged;
  } else if (!!history.completed) {
    return TaskEnums.State.Completed;
  } else if (!!history.loaded) {
    return TaskEnums.State.Loaded;
  } else if (!!history.arrived) {
    return TaskEnums.State.Arrived;
  } else if (!!history.started) {
    return TaskEnums.State.Started;
  } else if (!!history.created) {
    return TaskEnums.State.Created;
  }

  return TaskEnums.State.None;
}

export function AssumeTaskState(task) {
  const history = task?.execution?.history;

  if (!history) return null;

  if (!!history.acknowledged) {
    return history.acknowledged;
  } else if (!!history.completed) {
    return history.completed;
  } else if (!!history.loaded) {
    return history.loaded;
  } else if (!!history.arrived) {
    return history.arrived;
  } else if (!!history.started) {
    return history.started;
  } else if (!!history.created) {
    return history.created;
  }

  return null;
}


export function GetTaskDriverState(driverAssignedState, state, t, shorthand = false) {

  if (driverAssignedState === TaskEnums.DriverAssignedState.Accepted) {
    switch (state) {
      case TaskEnums.State.Created:
      case TaskEnums.State.None:
        return t(`task:enums:driverAssignedState:Accepted`);

      case TaskEnums.State.Started:
        return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Started`);

      case TaskEnums.State.Arrived:
        return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Arrived`);

      case TaskEnums.State.Loaded:
        return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Loaded`);

      case TaskEnums.State.Completed:
        return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Completed`);

      case TaskEnums.State.Acknowledged:
        return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Acknowledged`);

      default:
        return "";
    }
  } else {
    return t(`task:enums:driverAssignedState:${TaskEnumsLists.DriverAssignedState[driverAssignedState]}`)
  }

}

export function GetTaskDriverStateColor(driverAssignedState, state, typography = true) {

  switch (state) {
    case TaskEnums.State.None:
    case TaskEnums.State.Created:
      return driverAssignedState === TaskEnums.DriverAssignedState.Accepted ? (typography ? "success.main" : "success") : driverAssignedState === TaskEnums.DriverAssignedState.Rejected ? (typography ? "error.main" : "error") : (typography ? "warning.main" : "warning");

    case TaskEnums.State.Completed:
    case TaskEnums.State.Acknowledged:
      return (typography ? "success.main" : "success");

    default:
      return typography ? "textPrimary" : undefined;
  }
}

export function GetTaskDriverStateIcon(driverAssignedState, state) {
  switch (state) {
    case TaskEnums.State.None:
    case TaskEnums.State.Created:
    case TaskEnums.State.Started:
      return driverAssignedState === TaskEnums.DriverAssignedState.Accepted ? MapIcons.Pins.Moving : MapIcons.Pins.Issue;

    case TaskEnums.State.Arrived:
    case TaskEnums.State.Loaded:
      return MapIcons.Pins.Person;

    case TaskEnums.State.Completed:
    case TaskEnums.State.Acknowledged:
      return MapIcons.Pins.Resolved;

    default:
      return MapIcons.Pins.Issue;
  }
}


export function PaletteColorToHexColor(theme, colorName) {

  const { palette } = theme;

  if (!!palette[colorName]?.light) {
    return palette[colorName]?.light?.toUpperCase() || palette.secondary.light;
  }

  return palette.secondary.light;
}


export function GetTaskStateColor(state) {

  if (state === TaskEnums.State.Created) {
    return "warning";
  } else if (state >= TaskEnums.State.Completed) {
    return "success";
  }

  return undefined;
}


export function MakeEmptyTask(num = 1, name = null) {
  return {
    taskId: makeId(),
    taskName: name,
    taskType: 1,
    number: num,
    route: {
      waypoints: []
    },
    execution: {
      history: {},
      driver: { id: null, name: null },
      vehicle: { id: null, name: null },
      driverAssignedState: 0
    },
    state: 0,
    resolvedAtLocation: false,
    description: "",
    comment: "",
    keyPlacement: ""
  };
}

export function MakeEmptyStorage(num = 1) {
  return {
    taskId: makeId(),
    taskName: null,
    taskType: 2,
    number: num,
    comment: "",
    keyNumber: "",
    protected: false,
    delivered: false,
    rate: { value: "", currency: 1 },
    warehouse: {
      id: null,
      name: null,
      location: null
    }
  };
}

export function isImage(ext) {
  return ext === "jpeg" || ext === "jpg" || ext === "png" || ext === "webp" || ext === "gif";
}

export function MakeFile(f) {
  const extension = f.name.substr(f.name.lastIndexOf(".") + 1).toLowerCase().trim();

  return {
    id: makeId(),
    complete: false,
    name: f.name,
    size: f.size,
    mime: f.type,
    type: isImage(extension) ? 1 : 0,
    extension
  };
}

export async function UploadMissionFile(file, policy, onUploadProgress) {
  try {
    const form = new FormData();

    Object.keys(policy.postPolicy.fields).forEach(key => {
      if (key !== "postUrl") {
        form.append(key, policy.postPolicy.fields[key]);
      }
    });

    form.append("file", file);

    await axios.post(
      policy.postPolicy["postUrl"],
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      }
    );

  } catch (err) {
    console.log("error uploading resource file to handle");
    console.log(err);
  }

}


export function GetTargetDetails(mission) {
  const targetType = mission.target.type;
  return targetType === 1 ? mission.target.carDetails : (targetType === 2 ? mission.target.boatDetails : null);
}



export function ShouldAskForUpdatedPosition(geolocation, waypoint = null) {
  if (!geolocation || !geolocation.coords || !geolocation.coords.longitude || !geolocation.coords.latitude) return false;
  if (!waypoint) return true;

  const wCoordinates = waypoint.coordinates;

  if (!wCoordinates || wCoordinates?.length < 2) return true;

  const dtSeconds = (Date.now() - geolocation.timestamp) / 1000;

  if (dtSeconds > 65) {
    return false;
  }

  const wLongitude = wCoordinates[0];
  const wLatitude = wCoordinates[1];

  const gLongitude = geolocation.coords.longitude;
  const gLatitude = geolocation.coords.latitude;
  const gAcc = geolocation.coords.accuracy || 0;

  const distance = Math.max(0, HaversineDistance(wLongitude, wLatitude, gLongitude, gLatitude) - gAcc);

  return distance > 150; // IF distance larger than 100 meters, correct position
}

