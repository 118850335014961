import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../../providers/MissionProvider";
import { Add as AddIcon } from "@mui/icons-material";

export default function CreateMissionButtonRow(props) {
  const { t } = useTranslation();
  const { showServiceInfo, addServiceInfo, showFilesCard, setShowFilesCard, locked } = useMission()

  return (
    <Grid item xs={12}>

      {!showServiceInfo ? (
        <Button startIcon={<AddIcon />} size="large" onClick={addServiceInfo} disabled={locked}>{t("mission:addServiceDetails")}</Button>
      ) : null}

      {!showFilesCard ? (
        <Button startIcon={<AddIcon />} size="large" onClick={() => setShowFilesCard(true)} disabled={locked}>{t("mission:addFilesCard")}</Button>
      ) : null}

    </Grid>
  );
}