import { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Chip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MissionEnums, MissionEnumsLists } from "../../../common/constants";
import { Compare } from "@emberly/zenith-client";
import EntityTable from "../../common/tables/EntityTable";
import moment from "moment/moment";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useStation } from "../../../providers/StationProvider";
import { FullTimeView } from "../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    grid: {
      maxWidth: "1600px"
    },
    driverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px"
    },
    noDriverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px",
      color: theme.palette.text.secondary
    }
  })
);

const query = {
  path: "state",
  comparer: Compare.LTE,
  value: MissionEnums.State.Draft,
  name: "mission_drafts"
};

export default function DraftsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { createMissionDraft } = useStation();
  const navigate = useNavigate();

  const onRowClick = useCallback((params) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        headerName: t("drafts:table:mission"),
        flex: 0
      },
      {
        field: "created",
        headerName: t("common:tables:created"),
        type: "dateTime",
        renderCell: (params) => <FullTimeView time={params.value} />,
        flex: 0,
        minWidth: 120,
      },
      {
        field: "type",
        headerName: t("common:tables:type"),
        valueGetter: (params) => t(`mission:enums:type:${MissionEnumsLists.Type[params.row.details.type]}`),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "cause",
        headerName: t("common:tables:cause"),
        valueGetter: params => params.row.details?.cause?.name || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "details.tags",
        headerName: t("common:tables:tags"),
        valueGetter: params => params.row.details?.tags?.map(t => t.name).join() || "",
        flex: 1,
        minWidth: 120,
        renderCell: params => (
          <Grid container spacing={0.5}>
            {params.row.details?.tags?.map((tag, key) => <Grid item key={key}><Chip label={tag.name} size="small" /></Grid>)}
          </Grid>
        )
      },
      {
        field: "object",
        headerName: t("common:tables:object"),
        valueGetter: (params) => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details ? `${details.make} ${details.model}` : "-";
        },
        flex: 1,
        minWidth: 120,
      },
      {
        field: "details",
        headerName: t("common:tables:details"),
        valueGetter: params => params.row.details.description || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "state",
        headerName: t("common:tables:state"),
        valueGetter: () => "-",
        flex: 1,
        minWidth: 120,
      }
    ]
  }, [t, classes]);

  return (
    <ResponsivePage
      title={t("drafts:title")}
      secondaryAction={t("drafts:createMission")}
      onSecondaryAction={createMissionDraft}
    >
      <EntityTable
        tableId="drafts"
        type="Mission"
        filter={query}
        columns={columns}
        onRowClick={onRowClick}
        emptyStateTitle={t("drafts:emptyStateTitle")}
        emptyStateDescription={t("drafts:emptyStateDescription")}
        sortDescendingByDefault
        xs={12}
      />
    </ResponsivePage>
  );
}