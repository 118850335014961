import { getGridStringOperators, getGridDateOperators, getGridBooleanOperators, getGridNumericOperators, getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import EntityTableToolbar from "./EntityTableToolbar";
import NoRowsOverlay from "./NoRowsOverlay";

export const PAGESIZE = 25;

export const INITIAL_STATE = {
  pagination: {
    pageSize: PAGESIZE,
  },
};


export const COMPONENTS = { Toolbar: EntityTableToolbar, NoRowsOverlay: NoRowsOverlay };


export const SX = {
  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "2px" },
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "8px" },
  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "12px" },
};


export const autoHeight = () => "auto";


export const EDITABLE_PROPERTY = { newEditingApi: true };


export function GetFilterOperator(column) {
  switch (column.type) {

    case "boolean":
      return getGridBooleanOperators();

    case "date":
      return getGridDateOperators();

    case "dateTime":
      return getGridDateOperators(true);

    case "number":
      return getGridNumericOperators();

    case "singleSelect":
      return getGridSingleSelectOperators();

    case "string":
    default:
      return getGridStringOperators();
  }
}