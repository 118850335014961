import { Grid, List, SwipeableDrawer, Collapse } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../../providers/DeviceProvider";
import SidebarListItem, { SidebarButton, SidebarCategoryItem } from "./SidebarListItem";
import { useCallback, useState, useMemo, useRef, useEffect } from "react";
import SidebarBottomCard from "./SidebarBottomCard";
import { useStation } from "../../../providers/StationProvider";
import { useDriver } from "../../../providers/DriverProvider";
import { useScrollState } from "../../../hooks/useScrollState";
import { useLocation } from "react-router-dom";
import { show } from "@intercom/messenger-js-sdk";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      "& .MuiDrawer-paper": {
        height: "100%",
        width: "100%",
        overflow: "hidden"
      }
    },
    grid: {
      overflow: "auto",
      padding: theme.spacing(0.5),
      flexGrow: 1,
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    },
    logoContainer: {
      position: "relative",
      width: "100%",
      img: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1.5),
        height: "28px"
      }
    },
    list: {
      width: "100%",
      padding: 0,
      paddingLeft: theme.spacing(1.5)
    }
  })
);

export default function Sidebar(props) {
  const { className, open, setOpen } = props;
  const { classes } = useStyles();
  const { slim, isIos } = useDevice();
  const { t } = useTranslation();
  const { isStationAdmin, isAccountant, isGlobalAdmin, isDriver } = useStation();

  const handleClose = useCallback(() => setOpen(false), []);
  const { activeMissions } = useDriver();
  const { scrollRef, scrollBottom, dispatchEvent } = useScrollState(!slim || open);

  const defaultPages = useMemo(() => [
    isDriver ? { path: "/mymissions", title: t("sidebar:myMissions"), subText: activeMissions.length !== 0 ? activeMissions.length : undefined } : null,
    { path: "/mycashsettlement", title: t("sidebar:myCashSettlement") },
  ], [t, activeMissions, slim, isDriver]);

  const missionPages = useMemo(() => [
    { path: "/dispatch", title: t("sidebar:dispatch") },
    { path: "/tasks", title: t("sidebar:allTasks") },
    { path: "/activemissions", title: t("sidebar:activeMissions") },
    { path: "/missions", title: t("sidebar:allMissions") },
    { path: "/drivers", title: t("sidebar:drivers") },
    { path: "/storage", title: t("sidebar:storage") },
    { path: "/drafts", title: t("sidebar:drafts") },
  ], [t]);

  const accountingPages = useMemo(() => [
    { path: "/invoicing", title: t("sidebar:invoicing") },
    { path: "/cashorders", title: t("sidebar:cashOrders") },
    { path: "/cashsettlements", title: t("sidebar:cashSettlements") },
    { path: "/orders", title: t("sidebar:orders") },
  ], [t]);

  const registerPages = useMemo(() => [
    { path: "/log", title: t("sidebar:activityLog") },
    { path: "/causes", title: t("sidebar:cause") },
    { path: "/vehicles", title: t("sidebar:serviceVehicle") },
    { path: "/tags", title: t("sidebar:missionTag") },
    { path: "/products", title: t("sidebar:product") },
    { path: "/contacts", title: t("sidebar:contact") },
    { path: "/agreements", title: t("sidebar:agreement") },
    { path: "/warehouses", title: t("sidebar:warehouse") },
    { path: "/startlocations", title: t("sidebar:startLocation") },
    { path: "/vatcodes", title: t("sidebar:vatCodes") },
    { path: "/productgroups", title: t("sidebar:productGroups") },
  ], [t]);

  const settingsPages = useMemo(() => [
    isStationAdmin ? { path: "/station/settings", title: t("sidebar:station") } : null,
    isStationAdmin ? { path: "/station/users", title: t("sidebar:users") } : null,
    { path: "/station/export", title: t("sidebar:exportSettings") },
    { path: "/station/integrations", title: t("sidebar:integrations") },
    isGlobalAdmin ? { path: "/station/globaladmin", title: t("sidebar:globalAdmin") } : null,
  ], [t, isStationAdmin, isGlobalAdmin]);

  return (
    <SwipeableDrawer
      className={`${classes.root} ${className}`}
      anchor="left"
      open={open || !slim}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      disableDiscovery={isIos}
      variant={slim ? undefined : "persistent"}
    >
      <Grid container direction="row" className={classes.grid} ref={scrollRef}>
        <Grid item xs={12}>

          <Grid className={classes.logoContainer} container direction="row" justifyContent="flex-start" alignItems="center">
            <img src="/assets/icons/logo.svg" />
          </Grid>


          <SidebarListItem
            to="/"
            text={t("sidebar:home")}
            onClick={handleClose}
          />


          <Category
            handleClose={handleClose}
            title={t("sidebar:myPagesCategory")}
            dispatchEvent={dispatchEvent}
            pages={defaultPages}
            startOpen
          />

          <Category
            handleClose={handleClose}
            title={t("sidebar:missionsCategory")}
            dispatchEvent={dispatchEvent}
            pages={missionPages}
            startOpen
          />

          {
            isAccountant ? (
              <Category
                handleClose={handleClose}
                dispatchEvent={dispatchEvent}
                title={t("sidebar:accountingCategory")}
                pages={accountingPages}
                startOpen={!isStationAdmin}
              />
            ) : null
          }

          {
            isStationAdmin || isAccountant ? (
              <Category
                handleClose={handleClose}
                dispatchEvent={dispatchEvent}
                title={t("sidebar:registersCategory")}
                pages={registerPages}
              />
            ) : null
          }

          {
            isStationAdmin || isAccountant || isGlobalAdmin ? (
              <Category
                handleClose={handleClose}
                dispatchEvent={dispatchEvent}
                title={t("sidebar:settingsCategory")}
                pages={settingsPages}
              />
            ) : null
          }

          {
            slim ? (
              <SidebarButton text={t("sidebar:help")} onClick={show} />
            ) : null
          }


        </Grid>
      </Grid>

      <SidebarBottomCard shadow={!scrollBottom} />

    </SwipeableDrawer>
  );
}


function Category(props) {
  const { title, pages, handleClose, startOpen, dispatchEvent } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(!!startOpen);
  const [active, setActive] = useState(false);
  const ref = useRef();
  const { pathname } = useLocation();

  const onToggle = useCallback(() => {
    if (!categoryLocked(ref.current)) {
      setOpen(t => !t);
      setActive(false);
    } else {
      setActive(true);
    }
    dispatchEvent();
  }, [dispatchEvent]);

  useEffect(() => {
    if (categoryLocked(ref.current)) {
      setActive(true)
      setOpen(true);
    } else {
      setOpen(false);
      setActive(false);
    }
  }, [ref, pathname]);

  return (
    <>
      <SidebarCategoryItem expanded={open} active={active} text={title} onClick={onToggle} />
      <Collapse in={open}>
        <List ref={ref} className={classes.list}>
          {pages.map((page, index) => !!page ? <SidebarListItem key={index} to={page.path} text={page.title} onClick={handleClose} subText={page.subText} /> : null)}
        </List>
      </Collapse>
    </>
  );
}

function categoryLocked(e) {
  if (!e) return false;
  return !!e.querySelector(".active");
}