import { makeStyles } from "tss-react/mui";
import { Grid, List, Typography, } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import SectionItem from "../../common/inputs/SectionItem";
import { useAuth } from "@emberly/zenith-client";
import { useEffect, useState } from "react";
import axios from "axios";
import NumberCard from "../../common/display/NumberCard";


const useStyles = makeStyles()(
  (theme) => ({
    list: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500
      }
    }
  })
);

export default function GlobalAdminPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth();
  const [statistics, setStatistics] = useState(null);


  useEffect(() => {

    // Fetch all users in organization
    const fetchStatistics = async () => {
      try {
        const res = await axios.get("/api/globaladmin/statistics", {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setStatistics(res.data);

      } catch (err) {
        console.log(err);
      }
    };

    fetchStatistics();

  }, [getAccessTokenSilently]);

  return (
    <ResponsivePage
      xs={12}
      sm={12}
      md={8}
      lg={5}
      xl={4}
      xxl={4}
      title={t("globalAdmin:header")}
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">{t("globalAdmin:description")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid item container xs={12} justifyContent="stretch" spacing={3} direction="row">
          <NumberCard
            xs={6} md={4} lg={4}
            title={t("globalAdmin:totalOrganizations")}
            loading={!statistics}
            value={statistics?.totalOrganizations || "-"}
          />

          <NumberCard
            xs={6} md={4} lg={4}
            title={t("globalAdmin:totalMissions")}
            loading={!statistics}
            value={statistics?.totalMissions || "-"}
          />

          <NumberCard
            xs={12} md={4} lg={4}
            title={t("globalAdmin:totalUsers")}
            loading={!statistics}
            value={statistics?.totalUsers || "-"}
          />
        </Grid>
      </Grid>

      <ToggleSurface xs={12}>
        <List className={classes.list}>

          <SectionItem
            to="createstation"
            primary={t("globalAdmin:createStationTitle")}
            secondary={t("globalAdmin:createStationDescription")}
          />

          <SectionItem
            to="overview"
            primary={t("globalAdmin:overviewTitle")}
            secondary={t("globalAdmin:overviewDescription")}
            disabled
          />

        </List>

      </ToggleSurface>

    </ResponsivePage>
  );
}

