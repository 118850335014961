import { useMemo } from "react";
import { useEntities, Compare, distinct, useView } from "@emberly/zenith-client";

export function useOrdersWithMissions(query) {

  const { entities: orders, loading: ordersLoading } = useView("OrderMissionView", query);

  const missionIds = useMemo(() => distinct(orders, t => t.missionId), [orders]);

  const { entities: relatedOrders, loading: relatedOrdersLoading } = useEntities("Order", useMemo(() => ({
    path: "missionId",
    comparer: Compare.IN,
    value: missionIds
  }), [missionIds]), missionIds.length !== 0);

  const showLoading = (ordersLoading && orders.length === 0) || relatedOrdersLoading && missionIds.length !== 0;

  const missions = useMemo(() => {
    const map = new Map();

    orders.forEach(order => {
      if (!map.has(order.missionId)) {
        map.set(order.missionId, order.mission);
      }
    })

    return map.values().toArray();
  }, [orders]);

  return {
    orders,
    relatedOrders,
    showLoading,
    missionIds,
    missions
  };
}