import { makeStyles } from "tss-react/mui";
import { Grid, Stack, Box, Table, TableHead, TableBody, TableCell, TableRow, LinearProgress, Pagination, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useUserManagement } from "../../../../providers/UserManagementProvider";
import UserItem from "./UserItem";

const useStyles = makeStyles()(
  (theme) => ({
    box: {
      position: "relative"
    },
    paginationBox: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end"
    },
    progress: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%"
    },
  })
);

const pageSize = 20;

export default function UserTable(props) {
  const { slim } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { users, usersLoading, roles } = useUserManagement();

  const [query, setQuery] = useState("");
  const [role, setRole] = useState("all");
  const [page, setPage] = useState(1);

  const filteredUsers = useMemo(() => {
    if (!query && role === "all") return users;

    const fquery = query.toLowerCase();

    return users.filter(
      user => (
        (role === "all" || !!user.roles.find(r => r.id === role)) &&
        (!query || user.email?.toLowerCase().includes(fquery) || user.name?.toLowerCase().includes(fquery))
      )
    );
  }, [users, query, role]);


  const pageCount = Math.ceil(filteredUsers.length / pageSize);
  const pageIdx = page - 1;

  return (
    <Stack className={classes.stack}>
      <Box className={classes.box}>
        <Grid container direction="row" spacing={1}>

          <Grid item xs={8}>

            <FormControl fullWidth size="small">
              <InputLabel id="query-label">{t("userManagement:users:search")}</InputLabel>
              <OutlinedInput
                label={t("userManagement:users:search")}
                onChange={ev => (setQuery(ev.target.value), setPage(1))}
                value={query}
                fullWidth
                size="small"
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="role-select-label">{t("userManagement:users:role")}</InputLabel>
              <Select
                label={t("userManagement:users:role")}
                labelId="role-select-label"
                value={role}
                onChange={(ev) => (setRole(ev.target.value), setPage(1))}
              >
                <MenuItem value="all">{t("userManagement:users:all")}</MenuItem>
                {roles.map((r, key) => <MenuItem key={key} value={r.id}>{t(`roles:${r.name}`)}</MenuItem>)}
              </Select>
            </FormControl>


          </Grid>

        </Grid>

      </Box>

      <Box className={classes.box}>
        {
          usersLoading ? (
            <LinearProgress variant="indeterminate" className={classes.progress} />
          ) : null
        }
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t("userManagement:users:user")}
              </TableCell>
              <TableCell colSpan={2}>
                {t("userManagement:users:roles")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredUsers
                .slice(pageIdx * pageSize, Math.min(pageIdx * pageSize + pageSize, filteredUsers.length))
                .map((user, key) => <UserItem key={key} user={user} slim={slim} />)
            }
          </TableBody>
        </Table>
      </Box>
      {
        filteredUsers.length > pageSize ? (
          <Box className={classes.paginationBox}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={(ev, v) => setPage(v)}
            />
          </Box>
        ) : null
      }
    </Stack>
  );
}