import { makeStyles } from "tss-react/mui";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, IconButton, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useStation } from "../../../../providers/StationProvider";
import mapboxgl from "mapbox-gl";
import { useTranslation } from "react-i18next";
import { ArrowBack as BackIcon, Place as PlaceIcon, MyLocation as MyLocationIcon } from "@mui/icons-material";
import { useDriver } from "../../../../providers/DriverProvider";
import { pulsingDot } from "./PulsingDot";

const useStyles = makeStyles()(
  (theme) => ({
    dialogTitle: {
      padding: theme.spacing(1),
      alignItems: "center",
      display: "flex"
    },
    pickerActions: {
      padding: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      marginTop: theme.spacing(-4),
      background: theme.palette.background.default,
      zIndex: 10,
      boxShadow: "0px 0px 14px 0px #0000001A",
      "& button": {
        height: "56px"
      }
    },
    mapContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      "& .map": {
        width: `calc(100% + ${theme.spacing(6)})`,
        height: "100%",
        marginLeft: theme.spacing(-3),
        "& .mapboxgl-ctrl-logo": {
          marginBottom: "6px"
        }
      },
      "& .place": {
        position: "absolute",
        top: "calc(50% - 40px)",
        left: "calc(50% - 20px)",
        width: "40px",
        height: "40px",
        zIndex: 100,
        transition: "transform 0.15s",
      },
      "& .placemoving": {
        transform: "translate(0,-4px)",
      },
      "& .dot": {
        position: "absolute",
        top: "calc(50% - 14px)",
        left: "calc(50% - 10px)",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        background: "rgba(0,0,0,0.2)",
        zIndex: 99,
        transition: "transform 0.15s, opacity 0.15s",
        transform: "scale(0,0)",
        opacity: 1,
      },
      "& .dotmoving": {
        transform: "scale(1,1)",
        opacity: "0.5"
      }
    },
    reCenterButton: {
      position: "absolute",
      bottom: theme.spacing(4),
      right: theme.spacing(-0.5),
      zIndex: 121,
      background: `${theme.palette.background.default} !important`,
      color: theme.palette.common.black,
      boxShadow: "0px 0px 14px 0px #0000001A",
      borderRadius: "50%"
    }

  })
);

export default function MobileLocationPicker(props) {
  const { value, onConfirm, onCancel, loading } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { geolocation, getGeolocation } = useDriver();
  const geoRef = useRef(geolocation);
  geoRef.current = geolocation;
  const theme = useTheme();

  const { station } = useStation();
  const mapRef = useRef(null);
  const [pickerCoordinates, setPickerCoordinates] = useState(null);
  const [moving, setMoving] = useState(false);

  const onMapMounted = useCallback((component) => {
    try {
      if (!component) return;

      const gloc = geoRef.current?.coords;
      const hasGeolocation = !!gloc?.latitude && !!gloc?.longitude

      component.innerHTML = "";
      const hasExistingCoords = !!(value?.coordinates || hasGeolocation || station?.location?.coordinates);
      const existingCoords = hasExistingCoords ? (value?.coordinates || (hasGeolocation ? [gloc.longitude, gloc.latitude] : station?.location?.coordinates)) : null;

      if (hasExistingCoords) {
        setPickerCoordinates(existingCoords);
      }

      const map = new mapboxgl.Map({
        container: component, // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: hasExistingCoords ? existingCoords : [8.2, 60], // starting position [lng, lat]
        zoom: hasExistingCoords ? 14.5 : 6, // starting zoom
        attributionControl: false
      });

      mapRef.current = map;

      map.on("load", () => {
        // Add features
        if (hasGeolocation) {

          map.addImage("pulsing-dot", pulsingDot(map, theme.palette.info.dark), { pixelRatio: 2 });

          map.addSource("dot-point", {
            "type": "geojson",
            "data": {
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "geometry": {
                    "type": "Point",
                    "coordinates": [gloc.longitude, gloc.latitude]
                  }
                }
              ]
            }
          });

          map.addLayer({
            "id": "layer-with-pulsing-dot",
            "type": "symbol",
            "source": "dot-point",
            "layout": {
              "icon-image": "pulsing-dot"
            }
          });
        }
      });

      map.on("touchstart", () => {
        setMoving(true);
      });

      map.on("touchend", () => {
        setPickerCoordinates(map.getCenter().toArray());
        setMoving(false);
      });

    } catch (err) {
      console.log(err);
    }
  }, [value, station, theme]);

  useEffect(() => {
    if (!!mapRef.current) {
      const map = mapRef.current;

      const updateLocation = async () => {
        try {
          const glocenv = await getGeolocation();
          const gloc = glocenv.coords;

          if (!!gloc?.latitude && !!gloc?.longitude && !!map) {
            map?.getSource("dot-point")?.setData({
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "geometry": {
                    "type": "Point",
                    "coordinates": [gloc.longitude, gloc.latitude]
                  }
                }
              ]
            });
          }
        } catch { }
      };

      updateLocation();
      const timer = setInterval(updateLocation, 20000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [getGeolocation, mapRef.current]);

  const onReCenterMap = useCallback(() => {
    const gloc = geolocation.coords;

    mapRef.current?.flyTo({
      center: [gloc.longitude, gloc.latitude],
      zoom: 14.5,
      padding: 32
    });

    setPickerCoordinates([gloc.longitude, gloc.latitude]);

  }, [geolocation]);

  const onPickerClose = useCallback(() => {
    mapRef.current?.remove();
    onCancel();
  }, [onCancel]);

  const onPickerConfirm = useCallback(() => {
    mapRef.current?.remove();
    onConfirm(pickerCoordinates);
  }, [pickerCoordinates, onConfirm]);

  return (
    <Dialog
      open
      fullWidth
      fullScreen
      onClose={onPickerClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0px",
        },
      }}
    >
      {loading ? (
        <LinearProgress variant="indeterminate" />
      ) : null}

      <DialogTitle className={classes.dialogTitle}>

        <IconButton onClick={onPickerClose} color="inherit">
          <BackIcon />
        </IconButton>

        {t("common:location:pickerTitle")}
      </DialogTitle>

      <DialogContent>

        <div className={classes.mapContainer}>
          <div ref={onMapMounted} className="map"></div>
          <PlaceIcon className={`place ${moving ? "placemoving" : ""}`} color="info" />
          <div className={`dot ${moving ? "dotmoving" : ""}`}></div>

          {
            !!geolocation?.coords ? (
              <IconButton onClick={onReCenterMap} className={classes.reCenterButton} size="large">
                <MyLocationIcon />
              </IconButton>
            ) : null
          }
        </div>

      </DialogContent>

      <DialogActions className={classes.pickerActions}>
        <Button disabled={!pickerCoordinates || loading} onClick={onPickerConfirm} variant="contained" color="primary" size="large" fullWidth>{t("common:location:pickerButton")}</Button>
      </DialogActions>

    </Dialog>
  );
}


