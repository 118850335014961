import { makeStyles } from "tss-react/mui";
import { Stack, Box, Table, TableHead, TableBody, TableCell, TableRow, LinearProgress, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUserManagement } from "../../../../providers/UserManagementProvider";
import InviteItem from "./InviteItem";
import { useState } from "react";

const useStyles = makeStyles()(
  (theme) => ({
    box: {
      position: "relative"
    },
    paginationBox: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end"
    },
    progress: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%"
    },
  })
);

const pageSize = 10;

export default function InvitesTable(props) {
  const { slim } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { invites, invitesLoading } = useUserManagement();
  const [page, setPage] = useState(1);
  const pageCount = Math.ceil(invites.length / pageSize);
  const pageIdx = page - 1;

  return (
    <Stack className={classes.stack}>
      <Box className={classes.box}>
        {
          invitesLoading ? (
            <LinearProgress variant="indeterminate" className={classes.progress} />
          ) : null
        }
        <Table>
          <TableHead>
            <TableRow>

              <TableCell>
                {t("userManagement:invites:user")}
              </TableCell>
              <TableCell>
                {t("userManagement:invites:roles")}
              </TableCell>
              <TableCell colSpan={2}>
                {t("userManagement:invites:expires")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invites
              .slice(pageIdx * pageSize, Math.min(pageIdx * pageSize + pageSize, invites.length))
              .map((invite, key) => <InviteItem key={key} invite={invite} slim={slim} />)}
          </TableBody>
        </Table>
      </Box>
      {
        invites.length > pageSize ? (
          <Box className={classes.paginationBox}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={(ev, v) => setPage(v)}
            />
          </Box>
        ) : null
      }
    </Stack>
  );
}