import { makeStyles } from "tss-react/mui";
import { useDevice } from "../../../../providers/DeviceProvider";
import { Grid, Typography, Button } from "@mui/material";
import { InfoOutlined as InfoIcon, Add as AddIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      background: theme.palette.background.grey,
      width: "100%",
      height: "auto"
    },
  })
);

export default function SubPageEmptyState(props) {
  const { title, description, onClick, buttonText, disabled } = props;
  const { classes } = useStyles();
  const { slim } = useDevice()

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <Grid item container spacing={1} justifyContent="center" alignItems="center">

          <Grid item>
            <InfoIcon />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" textAlign="center">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" textAlign="center">
              {description}
            </Typography>
          </Grid>

          <Grid item xs={slim ? 12 : undefined}>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} size="large" fullWidth={slim} onClick={onClick} disabled={disabled}>
              {buttonText}
            </Button>
          </Grid>

        </Grid>
      </div>
    </Grid>
  );
}
