import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, InputAdornment, IconButton, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TableBody, Table, TableRow, TableCell, Button, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Search as SearchIcon } from "@mui/icons-material";
import { useEntityField, Defaults, useAuth, retryAxiosGet, Location } from "@emberly/zenith-client";
import { MissionEnumsLists } from "../../../../../common/constants";
import { makeStyles } from "tss-react/mui";
import { useCallback, useState } from "react";
import { useDevice } from "../../../../../providers/DeviceProvider";
import TextFieldDecimal from "../../../../common/inputs/TextFieldDecimal";
import { FormatLocation } from "../../../../../common/maphelpers";


const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
      },
    },
    sectionHeaderRow: {
      "& td": {
        marginTop: theme.spacing(3)
      }
    }
  })
);


export default function CarDetails(props) {
  const { locked } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { accessToken } = useAuth();
  const { slim } = useDevice();
  const registration = useEntityField("target.carDetails.registration", Defaults.String);
  const chassisNumber = useEntityField("target.carDetails.chassisNumber", Defaults.String);
  const make = useEntityField("target.carDetails.make", Defaults.String);
  const model = useEntityField("target.carDetails.model", Defaults.String);
  const color = useEntityField("target.carDetails.color", Defaults.String);
  const driveTrain = useEntityField("target.carDetails.driveTrain", Defaults.Enum);
  const fuel = useEntityField("target.carDetails.fuel", Defaults.Enum);
  const weightInKG = useEntityField("target.carDetails.weightInKG", Defaults.Number, true, true);
  const year = useEntityField("target.carDetails.year", Defaults.Number, true, true);
  const owner = useEntityField("actors.owner", Defaults.Object);
  const ownerLocation = useEntityField("actors.owner.location", Defaults.Location);

  const [dialogOpen, setDialogOpen] = useState(false);
  const onCloseDialog = useCallback(() => {
    setRegistrationError(false);
    setRegistrationNotFound(false);
    setDialogOpen(false);
    setRegData(null);
  }, []);

  const [loading, setLoading] = useState(false);
  const [regData, setRegData] = useState(null);
  const [registrationNotFound, setRegistrationNotFound] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);

  const fetchRegistration = useCallback(async () => {
    try {
      setRegData(null);
      setLoading(true);
      setDialogOpen(true);

      const res = await retryAxiosGet(
        `/api/mission/vehicleregistration?registration=${registration.value}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (regCodesMatch(res.data.registration, registration.value)) {
        setRegData(res.data);
      } else {
        setRegistrationNotFound(true);
      }

    } catch (err) {
      const errCode = err?.response?.status || 404;

      if (errCode === 404) {
        setRegistrationNotFound(true);
      } else {
        setRegistrationError(true);
      }

    } finally {
      setLoading(false);
    }
  }, [registration.value, accessToken]);


  const onConfirmRegistration = useCallback((overwrite) => {

    if (overwrite || !chassisNumber.value) {
      chassisNumber.onChange(null, regData.chassisNumber);
    }

    if (overwrite || !make.value) {
      make.onChange(null, regData.make);
    }

    if (overwrite || !model.value) {
      model.onChange(null, regData.model);
    }

    if (overwrite || !color.value) {
      color.onChange(null, regData.color);
    }

    if (overwrite || !weightInKG.value) {
      weightInKG.onChange(null, regData.weightInKG);
    }

    if (overwrite || !year.value) {
      year.onChange(null, regData.year);
    }

    if (overwrite || !driveTrain.value) {
      driveTrain.onChange(null, regData.driveTrain);
    }

    if (overwrite || !fuel.value) {
      fuel.onChange(null, regData.fuel);
    }

    if (!!regData.ownerData && (overwrite || !owner?.value?.name)) {
      owner.onChange(null, { name: [regData.ownerData.firstName, regData.ownerData.middleName || "", regData.ownerData.lastName].filter(t => !!t).join(" ") });
    }

    if (!!regData.ownerData && !!regData.ownerData.address && (overwrite || !ownerLocation.value || ownerLocation.value?.isEmpty)) {
      ownerLocation.onChange(null, new Location(regData.ownerData.address));
    }

    onCloseDialog();

  }, [regData, chassisNumber, make, model, color, weightInKG, year, driveTrain, fuel, owner, ownerLocation, onCloseDialog]);

  const hasData = !!chassisNumber.value || !!make.value || !!model.value || !!color.value || !!weightInKG.value || !!year.value || !!driveTrain.value || !!fuel.value || !!owner?.value?.name || (!!ownerLocation.value && !ownerLocation.value?.isEmpty);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          label={t("mission:cards:target:registration")}
          value={registration.value}
          onChange={registration.onChange}
          variant="filled"
          fullWidth
          size="small"
          disabled={locked}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={fetchRegistration} disabled={!registration.value}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label={t("mission:cards:target:chassisNumber")}
          value={chassisNumber.value}
          onChange={chassisNumber.onChange}
          variant="filled"
          fullWidth
          disabled={locked}
          size="small"
        />
      </Grid>


      <Grid item xs={12} md={6}>
        <TextField
          label={t("mission:cards:target:make")}
          value={make.value}
          onChange={make.onChange}
          variant="filled"
          fullWidth
          disabled={locked}
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label={t("mission:cards:target:model")}
          value={model.value}
          onChange={model.onChange}
          variant="filled"
          fullWidth
          disabled={locked}
          size="small"
        />
      </Grid>

      <Grid item xs={4}>
        <TextFieldDecimal
          label={t("mission:cards:target:year")}
          value={year.value === null ? "" : year.value}
          onChange={year.onChange}
          variant="filled"
          fullWidth
          nullable
          integer
          disabled={locked}
          size="small"
          thousandSeparator=""
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label={t("mission:cards:target:color")}
          value={color.value}
          onChange={color.onChange}
          variant="filled"
          fullWidth
          disabled={locked}
          size="small"
        />
      </Grid>

      <Grid item xs={4}>
        <TextFieldDecimal
          label={t("mission:cards:target:weight")}
          value={weightInKG.value === null ? "" : weightInKG.value}
          onChange={weightInKG.onChange}
          variant="filled"
          integer
          nullable
          fullWidth
          suffix=" kg"
          disabled={locked}
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth size="small" variant="filled" disabled={locked}>
          <InputLabel>{t("mission:cards:target:driveTrain")}</InputLabel>
          <Select value={driveTrain.value} onChange={driveTrain.onChange}>
            {MissionEnumsLists.DriveTrain.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:driveTrain:${type}`)}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth size="small" variant="filled" disabled={locked}>
          <InputLabel>{t("mission:cards:target:fuel")}</InputLabel>
          <Select value={fuel.value} onChange={fuel.onChange}>
            {MissionEnumsLists.FuelType.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:fuelType:${type}`)}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      {
        dialogOpen ? (
          <Dialog
            open
            onClose={onCloseDialog}
            fullWidth
            fullScreen={slim}
            maxWidth="xs"
          >
            <DialogTitle>
              {t("mission:cards:target:searchDialog:title")} {registration.value || "-"}
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    {registrationError ? t("mission:cards:target:searchDialog:error") : registrationNotFound ? `${t("mission:cards:target:searchDialog:notFound")} ${registration?.value || "-"}` : t("mission:cards:target:searchDialog:description")}
                  </Typography>
                </Grid>

                {
                  !(registrationError || registrationNotFound) ? (
                    <Grid item xs={12}>
                      <Table className={classes.table}>
                        <TableBody>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:chassisNumber")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2"> {!!regData ? (regData.chassisNumber || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:make")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? (regData.make || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:model")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? (regData?.model || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:year")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? (regData?.year || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:color")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? (regData?.color || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:weight")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? (regData?.weightInKG || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:driveTrain")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? t(`mission:enums:driveTrain:${MissionEnumsLists.DriveTrain[regData?.driveTrain || 0]}`) : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:fuel")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData ? t(`mission:enums:fuelType:${MissionEnumsLists.FuelType[regData?.fuel || 0]}`) : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>


                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:ownerName")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData && !!regData.ownerData ? (`${regData?.ownerData?.firstName} ${regData?.ownerData?.middleName || ""} ${regData?.ownerData?.lastName}` || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>


                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary">{t("mission:cards:target:ownerAddress")}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">{!!regData && !!regData.ownerData ? (FormatLocation(regData.ownerData.address) || "-") : <Skeleton width={120} height={20} />}</Typography>
                            </TableCell>
                          </TableRow>


                          {
                            !!regData?.leaseData ? (
                              <>
                                <TableRow>
                                  <TableCell>
                                    <Typography variant="body2" color="textSecondary">{t("mission:cards:target:leaseName")}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body2">{!!regData && !!regData.leaseData ? (`${regData?.leaseData?.firstName} ${regData?.leaseData?.middleName || ""} ${regData?.leaseData?.lastName}` || "-") : <Skeleton width={120} height={20} />}</Typography>
                                  </TableCell>
                                </TableRow>


                                <TableRow>
                                  <TableCell>
                                    <Typography variant="body2" color="textSecondary">{t("mission:cards:target:leaseAddress")}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body2">{!!regData && !!regData.leaseData ? (FormatLocation(regData.leaseData.address) || "-") : <Skeleton width={120} height={20} />}</Typography>
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : null
                          }



                        </TableBody>
                      </Table>
                    </Grid>
                  ) : null
                }

              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onCloseDialog}>
                {t("mission:cards:target:searchDialog:cancel")}
              </Button>

              {
                !(registrationError || registrationNotFound) ? (
                  <>
                    <Button onClick={() => onConfirmRegistration(false)} variant={hasData ? "outlined" : "contained"} disabled={loading}>
                      {t("mission:cards:target:searchDialog:confirm")}
                    </Button>

                    {
                      hasData ? (
                        <Button onClick={() => onConfirmRegistration(true)} variant="contained" disabled={loading}>
                          {t("mission:cards:target:searchDialog:confirmOverwrite")}
                        </Button>
                      ) : null
                    }
                  </>
                ) : null
              }

            </DialogActions>
          </Dialog >
        ) : null
      }
    </>

  );
}


export function regCodesMatch(a, b) {
  const na = a.replace(" ", "").toLowerCase();
  const nb = b.replace(" ", "").toLowerCase();
  return na === nb;
}