import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { Compare } from "@emberly/zenith-client";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import EntityTable from "../../../common/tables/EntityTable";
import FlagChip from "../../../common/core/FlagChip";

const Query = {
  name: "cash_exports",
  path: "orderCount",
  comparer: Compare.GT,
  value: 0
};

export default function CashJournalsSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("cashJournals:fieldNumber"),
        flex: 0
      },
      {
        field: "journalLineCount",
        type: "number",
        headerName: t("cashJournals:fieldJournalLineCount"),
        minWidth: 120,
      },
      {
        field: "orderCount",
        type: "number",
        headerName: t("cashJournals:fieldOrderCount"),
        minWidth: 120,
      },
      {
        field: "totalSum.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.totalSum?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.totalSum?.value)} ${getCurrencyShorthand(t, params.row.totalSum?.currency)}`,
        headerName: t("cashJournals:fieldTotalSum"),
        minWidth: 120,
      },
      {
        field: "sender.name",
        valueGetter: (params) => params.row.sender?.name || "-",
        headerName: t("cashJournals:fieldSender"),
        flex: 0.75,
      },
      {
        field: "exported",
        type: "singleSelect",
        headerName: t("cashJournals:fieldState"),
        minWidth: 120,
        flex: 0.75,
        renderCell: (params) => <FlagChip value={params.value} label={params.value ? t("common:exported") : t("common:notExported")} />,
        valueOptions: [
          { label: t("common:exported"), value: true },
          { label: t("common:notExported"), value: false },
        ]
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);

  return (
    <EntityTable
      tableId="cash_exports"
      type="CashExport"
      filter={Query}
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("cashJournals:emptyStateTitle")}
      emptyStateDescription={t("cashJournals:emptyStateDescription")}
      sortDescendingByDefault
      serverSidePaging
      xs={12}
    />
  );
}
