import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useEntityField, useEntities, Defaults } from "@emberly/zenith-client";
import DateAndTimePicker from "../inputs/DateAndTimePicker";
import StorageDetailsCard from "../../pages/mission/cards/tasks/common/StorageDetailsCard";
import { useMission } from "../../../providers/MissionProvider";
import TextFieldDecimal from "../inputs/TextFieldDecimal";

export default function StorageSection() {
  const { t } = useTranslation();

  const comment = useEntityField(`storageTask.comment`, Defaults.String);
  const stored = useEntityField(`storageTask.stored`, Defaults.String);
  const warehouse = useEntityField(`storageTask.warehouse`, Defaults.Null);
  const keyNumber = useEntityField(`storageTask.keyNumber`, Defaults.String);
  const reminder = useEntityField(`storageTask.reminder`, Defaults.String);
  const isProtected = useEntityField(`storageTask.protected`, Defaults.Boolean);
  const rate = useEntityField(`storageTask.rate.value`, Defaults.String);
  const { mission, locked } = useMission();
  const { entities: warehouses } = useEntities("Warehouse");

  const onSelectWarehouse = useCallback(ev => {
    const entity = warehouses.find(t => t.id === ev.target.value);
    if (!!entity?.location) {
      warehouse.onChange(null, { id: ev.target.value, name: entity.name, location: { ...entity.location, title: entity.name } });
    }
  }, [warehouse, warehouses]);

  const warehouseDoesntExist = !!warehouse.value?.id && !warehouses.find(t => t.id === warehouse.value.id);

  return (
    <Grid item container spacing={1} justifyContent="flex-start" alignItems="flex-start" direction="row">

      <Grid item container lg={12} xl={6} spacing={1}>
        <Grid item xs={12}>
          <DateAndTimePicker
            disabled={locked}
            label={t("mission:cards:tasks:storageTask:stored")}
            value={stored.value}
            onChange={stored.onChange}
            fromNow
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth size="small" variant="filled" disabled={locked}>
            <InputLabel>{t("mission:cards:tasks:storageTask:warehouse")}</InputLabel>
            <Select
              onChange={onSelectWarehouse}
              value={warehouse.value?.id || ""}
            >
              {warehouseDoesntExist ? (
                <MenuItem value={warehouse.value.id}>{warehouse.value.name}</MenuItem>
              ) : null}

              {warehouses.map((c, key) => <MenuItem value={c.id} key={key}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={locked}
            label={t("mission:cards:tasks:storageTask:comment")}
            variant="filled"
            multiline
            fullWidth
            size="small"
            value={comment.value}
            onChange={comment.onChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={locked}
            label={t("mission:cards:tasks:storageTask:keyNumber")}
            variant="filled"
            multiline
            fullWidth
            size="small"
            value={keyNumber.value}
            onChange={keyNumber.onChange}
          />
        </Grid>

        <Grid item xs={12}>
          <DateAndTimePicker
            disabled={locked}
            label={t("mission:cards:tasks:storageTask:reminder")}
            value={reminder.value}
            onChange={reminder.onChange}
            fromNow
          />
        </Grid>
      </Grid>


      <Grid item container lg={12} xl={6} spacing={1}>

        <Grid item xs={12}>
          <FormControlLabel
            label={t("mission:cards:tasks:storageTask:protected")}
            control={<Checkbox size="small" checked={isProtected.value} onChange={isProtected.onChange} disabled={locked} />}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldDecimal
            disabled={locked}
            label={t("mission:cards:tasks:storageTask:rate")}
            variant="filled"
            fullWidth
            selectOnFocus
            decimal
            size="small"
            value={rate.value}
            onChange={rate.onChange}
          />
        </Grid>

        <Grid item xs={12}>
          <StorageDetailsCard
            storageTask={mission.storageTask}
          />
        </Grid>
      </Grid>

    </Grid>
  );
}
