import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody, TableRow, TableCell, Divider, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FullTimeView } from "../../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
        "& .MuiSkeleton-rounded": {
          minWidth: "32px",
        }
      },
    },
  })
);

export default function SettlementInfoCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { loading, settlement } = props;
  
  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">{t("cashSettlementInfo:title")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementInfo:signed")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{loading ? <Skeleton variant="rounded" /> : <FullTimeView time={settlement.created} />}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementInfo:signedBy")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{loading ? <Skeleton variant="rounded" /> : (settlement.sender.name || "-")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementInfo:approved")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{loading ? <Skeleton variant="rounded" /> : !!settlement.approved && !!settlement.approvedDate ? <FullTimeView time={settlement.approvedDate} /> : "-"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementInfo:approvedBy")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{loading ? <Skeleton variant="rounded" /> : (settlement.approvedBy?.name || "-")}</Typography>
                </TableCell>
              </TableRow>



            </TableBody>
          </Table>
        </Grid>

      </Grid>
    </div>
  );
}
