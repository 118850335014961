import { useState, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { ToggleSurface } from "../core/Surface";
import { DataGridPro, nbNO, enUS } from "@mui/x-data-grid-pro";
import { useView } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";
import { transpile } from "../../../common/filtertranspiler";
import { usePersistentFilter } from "../../../hooks/usePersistentFilter";
import { useStyles } from "./styles";
import { PAGESIZE, INITIAL_STATE, COMPONENTS, SX, autoHeight, EDITABLE_PROPERTY, GetFilterOperator } from "./constants";

export default function EntityViewTable(props) {
  const { className, tableId, xs, sm, md, lg, xl, xxl, title, columns, type, emptyStateTitle, emptyStateDescription, filter, onRowClick, editable, processRowUpdate, disableRowHover, serverSidePaging, sortDescendingByDefault } = props;
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  const [page, setPage] = useState(serverSidePaging ? 0 : -1);
  const [pageSize, setPageSize] = useState(PAGESIZE);

  const {
    sortModel,
    filterOverride,
    setSortModel,
    setFilterOverride,
    onReset,
    isPinned,
    visibleColumns,
    setVisibleColumns,
    pinnedColumns,
    setPinnedColumns,
    isCurrent
  } = usePersistentFilter(tableId);

  const sortByPath = sortModel.length === 1 ? sortModel[0].field : null;
  const sortDescending = sortModel.length === 1 ? sortModel[0].sort !== "asc" : sortDescendingByDefault;

  const filterQuery = useMemo(() => {
    if (serverSidePaging && !!filterOverride?.items?.length) {
      return transpile(columns, filterOverride, filter);
    } else {
      return filter;
    }
  }, [filter, filterOverride, serverSidePaging, columns]);

  const { entities, loading, totalCount } = useView(type, filterQuery, isCurrent, page, pageSize, sortByPath, sortDescending);
  const showLoader = loading && entities.length === 0;

  const renderColumns = useMemo(() => {
    if (!serverSidePaging) return columns;
    return columns.map(column => ({ ...column, filterOperators: GetFilterOperator(column) }));
  }, [columns, serverSidePaging]);

  const onHandleRowClick = useCallback((params, ev) => {
    if (window.getSelection()?.type !== "Range") {
      ev.preventDefault();
      if (!!onRowClick) {
        onRowClick(params, ev);
      }
    }
  }, [onRowClick]);

  const onSortModelChange = useCallback((sortModel) => {
    setSortModel(sortModel.length > 0 ? [sortModel[0]] : []);
  }, [setSortModel]);

  return (
    <ToggleSurface
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}
      outerClassName={className}
      title={title}
    >
      <Grid item xs={12} container>
        <div className={classes.gridInnerContainer}>
          <DataGridPro
            disableColumnReorder
            sx={SX}
            className={`${classes.table} ${disableRowHover ? "" : classes.tableRowHover}`}
            rows={entities}
            loading={showLoader}
            columns={renderColumns}
            getRowHeight={autoHeight}
            onRowClick={onHandleRowClick}
            initialState={INITIAL_STATE}
            rowsPerPageOptions={useMemo(() => entities.length < PAGESIZE ? [Math.max(entities.length, 1), PAGESIZE, 50, 100, 150] : [PAGESIZE, 50, 100, 150], [entities.length])}
            disableSelectionOnClick
            autoHeight
            paginationMode={serverSidePaging ? "server" : "client"}
            pagination
            filterModel={!!filterOverride ? filterOverride : undefined}
            filterMode={serverSidePaging ? "server" : "client"}
            onFilterModelChange={setFilterOverride}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            onPageChange={serverSidePaging ? setPage : undefined}
            onPageSizeChange={serverSidePaging ? setPageSize : undefined}
            rowCount={serverSidePaging ? Math.max(totalCount, 0) : undefined}
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={setVisibleColumns}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={setPinnedColumns}
            components={COMPONENTS}
            componentsProps={{
              noRowsOverlay: {
                title: emptyStateTitle,
                description: emptyStateDescription,
              },
              toolbar: {
                canSearch: !serverSidePaging,
                isPinned,
                tableId,
                onReset,
              }
            }}
            localeText={(i18n.language === "no" ? nbNO : enUS)?.components.MuiDataGrid.defaultProps.localeText}
            experimentalFeatures={editable ? EDITABLE_PROPERTY : undefined}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={console.log}
          />
        </div>
      </Grid>
    </ToggleSurface>
  );
}
