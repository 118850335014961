import { Alert, AlertTitle, Button, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, TextField, Typography, } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useEffect, useState } from "react";
import { WarningAmber as WarningIcon } from "@mui/icons-material";
import { Task, useAuth } from "@emberly/zenith-client";
import axios from "axios";
import ConfirmDialog from "../../common/inputs/ConfirmDialog";
import { useNotification } from "../../../providers/NotificationProvider";

export default function CreateStationPage() {
  const { t } = useTranslation();
  const { getAccessTokenSilently, switchOrganization } = useAuth();
  const { alert } = useNotification();

  const [stations, setStations] = useState([]);

  const [stationDisplayName, setStationDisplayName] = useState("");
  const [stationName, setStationName] = useState("");
  const [createMode, setCreateMode] = useState("empty");
  const [targetStation, setTargetStation] = useState(null);

  const [includeExportSettings, setIncludeExportSettings] = useState(true);
  const [includeProducts, setIncludeProducts] = useState(true);
  const [includeMissionDetails, setIncludeMissionDetails] = useState(false);
  const [includeCauses, setIncludeCauses] = useState(false);

  const [working, setWorking] = useState(false);

  useEffect(() => {

    // Fetch all users in organization
    const fetchStations = async () => {
      try {
        const res = await axios.get("/api/globaladmin/stations", {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setStations(res.data);

      } catch (err) {
        console.log(err);
      }
    };

    fetchStations();

  }, [getAccessTokenSilently]);


  const [confirmAction, setConfirmAction] = useState(null);
  const [successAction, setSuccessAction] = useState(null);

  const onCreateStation = async () => {

    try {
      const duplicate = stations.find(s => s.displayName === stationDisplayName || s.name === stationName);

      if (!!duplicate) {
        await alert(
          t("createStation:duplicateError:title"),
          t("createStation:duplicateError:description"),
          "error"
        );
        return;
      }

      const cAction = new Task();
      setConfirmAction(cAction);

      await cAction.wait();
      setConfirmAction(null)
      setWorking(true);

      const request = {
        stationName,
        stationDisplayName,
        createMode,
        targetStation,
        includeExportSettings,
        includeMissionDetails,
        includeCauses,
        includeProducts
      };

      const res = await axios.post("/api/globaladmin/stations", request, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      });

      setWorking(false);
      const sAction = new Task();
      setSuccessAction(sAction);
      await sAction.wait();

      const createdOrganization = res.data;
      switchOrganization(createdOrganization.id);

    } catch (err) {
      if (err !== "cancelled") {
        console.log(err);

        await alert(
          t("createStation:genericError:title"),
          t("createStation:genericError:description"),
          "error"
        );
      }

    } finally {
      setWorking(false);
      setConfirmAction(null);
      setSuccessAction(null);
    }
  }

  return (
    <ResponsivePage
      xs={12}
      sm={12}
      md={8}
      lg={8}
      xl={6}
      xxl={4}
      title={t("createStation:header")}
      backButton
    >
      <Grid item xs={12}>
        <Typography variant="body1">{t("createStation:description")}</Typography>
      </Grid>

      <ToggleSurface
        title={t("createStation:title")}
      >
        <Grid item container spacing={3}>

          {
            working ? (
              <Grid item xs={12}>
                <LinearProgress variant="indeterminate" />
              </Grid>
            ) : null
          }

          <Grid item xs={12}>
            <TextField
              label={t("createStation:stationDisplayName")}
              helperText={t("createStation:stationDisplayNameHelperText")}
              onChange={ev => setStationDisplayName(ev.target.value)}
              value={stationDisplayName}
              variant="filled"
              fullWidth
              size="small"
              disabled={working}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("createStation:stationName")}
              helperText={t("createStation:stationNameHelperText")}
              onChange={ev => setStationName(ev.target.value)}
              value={stationName}
              variant="filled"
              fullWidth
              size="small"
              disabled={working}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{t("createStation:createMode")}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={createMode}
                onChange={(_, v) => setCreateMode(v)}
              >
                <FormControlLabel value="empty" control={<Radio disabled={working} />} label={t("createStation:createEmpty")} />
                <FormControlLabel value="copy" control={<Radio disabled={working} />} label={t("createStation:createCopy")} />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={createMode === "copy"}>
              <Grid item container spacing={3}>

                <Grid item xs={12}>
                  <Alert
                    color="error"
                    icon={<WarningIcon fontSize="inherit" />}
                  >
                    <AlertTitle>{t("createStation:copyWarningTitle")}</AlertTitle>
                    {t("createStation:copyWarningDescription")}
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="filled" disabled={stations.length === 0}>
                    <InputLabel>{t("createStation:copyTargetStation")}</InputLabel>
                    <Select onChange={ev => setTargetStation(stations.find(s => s.id === ev.target.value))} value={targetStation?.id || ""} disabled={working}>
                      {stations.map((station, idx) => <MenuItem selected={targetStation?.id === station.id} value={station.id} key={idx}>{station.displayName}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>{t("createStation:copySettingsTitle")}</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        label={t("createStation:includeExportSettings")}
                        control={
                          <Checkbox
                            disabled={working}
                            checked={includeExportSettings}
                            onChange={ev => setIncludeExportSettings(ev.target.checked)}
                          />
                        }
                      />
                      <FormControlLabel
                        label={t("createStation:includeProducts")}
                        control={
                          <Checkbox
                            disabled={!includeExportSettings || working}
                            checked={includeProducts && includeExportSettings}
                            onChange={ev => setIncludeProducts(ev.target.checked)}
                          />
                        }
                      />
                      <FormControlLabel
                        label={t("createStation:includeMissionDetails")}
                        control={
                          <Checkbox
                            disabled={working}
                            checked={includeMissionDetails}
                            onChange={ev => setIncludeMissionDetails(ev.target.checked)}
                          />
                        }
                      />
                      <FormControlLabel
                        label={t("createStation:includeCauses")}
                        control={
                          <Checkbox
                            disabled={working}
                            checked={includeCauses}
                            onChange={ev => setIncludeCauses(ev.target.checked)}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

              </Grid>
            </Collapse>
          </Grid>


          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={onCreateStation} disabled={!stationName || !stationDisplayName || working}>{t("createStation:create")}</Button>
            </Grid>
          </Grid>

        </Grid>
      </ToggleSurface>


      <ConfirmDialog
        task={confirmAction}
        title={t("createStation:confirmDialog:title")}
        description={t("createStation:confirmDialog:description")}
        confirmText={t("createStation:confirmDialog:confirmText")}
      />

      <ConfirmDialog
        task={successAction}
        title={t("createStation:successDialog:title")}
        description={t("createStation:successDialog:description")}
        confirmText={t("createStation:successDialog:confirmText")}
      />

    </ResponsivePage>
  );
}
