


export function inViewport(element) {
  if (!element) return false;
  if (1 !== element.nodeType) return false;

  const html = document.documentElement;
  const rect = element.getBoundingClientRect();

  return (
    !!rect &&
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.left <= html.clientWidth &&
    rect.top <= html.clientHeight
  );
}

export function positive(value) {
  return value.startsWith("-") ? value.substring(1) : value;
} 

export function country(t, code) {
  if (!code) return "";
  return t(`country:${code}`, code);
}


export function arrayEquals(a, b) {
  return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}

export function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}


export function copyToClipboard(content) {
  navigator.clipboard.writeText(content);
}