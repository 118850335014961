import { ActivityEnums } from "./constants";



export function MakeActivity(activityCategory, activityType, resourceId, value = "", description = "", target = null) {
  return {
    resourceId,
    activityCategory,
    activityType,
    target,
    description,
    value,
  };
}


export function GetCategoryEnum(category) {
  switch (category) {
    case "Mission":
      return ActivityEnums.Category.Mission;
    case "Billing":
      return ActivityEnums.Category.Billing;
    case "Other":
      return ActivityEnums.Category.Other;

    default:
      return ActivityEnums.Category.None;
  }
}


export function GetResourceLink(item) {
  switch (item.activityCategory) {

    case ActivityEnums.Category.Mission:
      return `/missions/${item.resourceId}`;

    case ActivityEnums.Category.Billing:
      return GetResourceBillingLink(item);

    case ActivityEnums.Category.Other:
      return "";

    default:
      return "";
  }
}




export function GetResourceBillingLink(item) {
  switch (item.activityType) {

    case ActivityEnums.Type.InvoiceJournalCreated:
      return `/invoicing/journals/${item.resourceId}`;

    case ActivityEnums.Type.CashJournalCreated:
      return `/cashorders/journals/${item.resourceId}`;

    case ActivityEnums.Type.InvoiceQueued:
    case ActivityEnums.Type.InvoiceReOpened:
    case ActivityEnums.Type.PaymentRequested:
    case ActivityEnums.Type.PaymentConfirmed:
      return `/orders/all/${item.resourceId}`;

    case ActivityEnums.Type.OrdersSettled:
      case ActivityEnums.Type.SettlementApproved:
      return `/cashsettlements/archive/${item.resourceId}`;

    case ActivityEnums.Type.SettlementsApproved:
      return "/cashsettlements/archive/";

    default:
      return "";
  }
}