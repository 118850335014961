import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";


export default function ConfirmDialog(props) {
  const { task, title, description, confirmText, color } = props;
  const { t } = useTranslation();

  const open = !!task && !task.completed;

  return (
    <Dialog
      open={open}
      onClose={() => task.throw()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => task.throw("cancelled")}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color={color || "primary"} onClick={() => task.complete()} autoFocus>
          {confirmText || t("common:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}