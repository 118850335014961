import { createContext, useContext, useEffect, useMemo } from "react";
import { useEntity } from "@emberly/zenith-client";
import { CalcToPaySum, CanChangePaymentMethod, GetOrderState } from "../common/orders";
import { useMission } from "./MissionProvider";
import { useStation } from "./StationProvider";
import { OrderEnums } from "../common/constants";

export const OrderContext = createContext();
export const useOrder = () => useContext(OrderContext);

export const OrderProvider = (props) => {
  const { entity: order, updateEntityField, deferUpdateEntityField } = useEntity()
  const { orders, ordersLoading } = useMission();
  const { priceUnit } = useStation();

  const open = CanChangePaymentMethod(order.payment);

  const hasValue = useMemo(() => {
    return !!order?.orderLines?.length || !!order?.deductible?.price?.value || !!orders.find(t => t.deductible?.order?.id === order?.id || t?.vatTransfer?.order?.id === order?.id);
  }, [order, orders]);
  
  useEffect(() => {
    if (!!order && !!orders && !ordersLoading) {

      const timer = setTimeout(() => {
        if (GetOrderState(order) === OrderEnums.OrderState.Open) {
          const sum = CalcToPaySum(order, orders).toString();
          if (sum !== order.payment.calculatedTotal.value) {
            updateEntityField("payment.calculatedTotal", { value: sum, currency: priceUnit });
          }
        }
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [order, orders, updateEntityField, priceUnit, ordersLoading]);

  return (
    <OrderContext.Provider
      value={{
        order,
        open,
        hasValue,
        updateOrderField: updateEntityField,
        deferUpdateOrderField: deferUpdateEntityField
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

