import { IntegrationEnums } from "../../../../common/constants";
import config from "../../../../config";


export const IntegrationLibrary = new Map([
  [IntegrationEnums.Service.Tripletex, {
    icon: "tripletex.png",
    name: "Tripletex",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10111512-slik-setter-du-opp-integrasjonen-mellom-assist-og-tripletex",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true,
  }],
  [IntegrationEnums.Service.Duett, {
    icon: "duett.png",
    name: "Duett",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10104027-slik-setter-du-opp-integrasjon-mellom-assist-og-duett",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
    },
    mappings: {
      VatCode: [
        "vat",
        "food",
        "low",
        "noVat",
      ]
    },
    defaultMappings: {
      VatCode: (entity) => {
        switch (entity.code) {
          case "3": return "vat";
          case "31": return "food";
          case "33": return "low";
          case "5":
          case "7": return "noVat";
          default: return "";
        }
      }
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.PowerOfficeGo, {
    icon: "powerofficego.png",
    name: "PowerOfficeGo",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10111545-slik-setter-du-opp-integrasjonen-mellom-assist-og-poweroffice",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.Unimicro, {
    icon: "unimicro.png",
    name: "Unimicro",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.UnimicroSMB, {
    icon: "unimicro.png",
    name: "Unimicro SMB",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.EikaRegnskap, {
    icon: "eika.png",
    name: "Eika Regnskap",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.AzetsComplete, {
    icon: "azets.jpg",
    name: "Azets Complete",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.DNBRegnskap, {
    icon: "dnb.png",
    name: "DNB Regnskap",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.SparebankenSor, {
    icon: "sparebankensor.png",
    name: "Sparebanken Sør Regnskap",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.Sparebank1, {
    icon: "sparebank1.png",
    name: "Sparebank 1 Regnskap",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist", // TODO?
    hasApiKey: true,
    hasUserKey: false,
    regions: [
      "Sparebank1SR",
      "Sparebank1Ost",
      "Sparebank1SO",
      "Sparebank1NN",
      "Sparebank1HV",
      "Sparebank1NM",
      "Sparebank1OA",
      "Sparebank1RH",
      "Sparebank1G",
      "Sparebank1SS",
      "Sparebank1LS",
    ],
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true
  }],
  [IntegrationEnums.Service.UnimicroDev, {
    icon: "unimicro.png",
    name: "Unimicro Test",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://help.getassist.app/nb/articles/10102771-hvordan-sette-opp-integrasjon-mellom-unimicro-og-assist", 
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      treatProductsAsProductGroups: false,
      createProductsIfNotExists: true,
      transferOrdersToInvoice: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true,
    hidden: config.isProd
  }],
]);
