import { useState, useCallback, useMemo, useEffect } from "react";
import { Grid } from "@mui/material";
import { ToggleSurface } from "../core/Surface";
import { DataGridPro, nbNO, enUS } from "@mui/x-data-grid-pro";
import { useEntities } from "@emberly/zenith-client";
import { Add as AddIcon } from "@mui/icons-material";
import EntityEditDialog from "../inputs/EntityEditDialog";
import { useTranslation } from "react-i18next";
import { useResponsivePage } from "../core/ResponsivePage";
import { transpile } from "../../../common/filtertranspiler";
import { usePersistentFilter } from "../../../hooks/usePersistentFilter";
import { useStyles } from "./styles";
import { PAGESIZE, INITIAL_STATE, COMPONENTS, SX, autoHeight, EDITABLE_PROPERTY, GetFilterOperator } from "./constants";


export default function EntityTable(props) {
  const { className, tableId, xs, sm, md, lg, xl, xxl, title, columns, type, fields, emptyStateTitle, emptyStateDescription, createTitle, editTitle, filter, onRowClick, createButton, onCreated, editable, processRowUpdate, disableRowHover, serverSidePaging, sortDescendingByDefault } = props; // TODO
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { setActionButton } = useResponsivePage();
  
  const [page, setPage] = useState(serverSidePaging ? 0 : -1);
  const [pageSize, setPageSize] = useState(PAGESIZE);
  const [dialog, setDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    if (!!createButton) {
      setActionButton({
        text: t("entityTable:add"),
        icon: <AddIcon />,
        onClick: () => {
          setSelectedEntity(null);
          setDialog(true);
        }
      });

      return () => {
        setActionButton(null);
      };
    }
  }, [createButton, setActionButton, t]);

  const {
    sortModel,
    filterOverride,
    setSortModel,
    setFilterOverride,
    onReset,
    isPinned,
    visibleColumns,
    setVisibleColumns,
    pinnedColumns,
    setPinnedColumns,
    isCurrent
  } = usePersistentFilter(tableId);

  const sortByPath = sortModel.length === 1 ? sortModel[0].field : null;
  const sortDescending = sortModel.length === 1 ? sortModel[0].sort !== "asc" : sortDescendingByDefault;

  const filterQuery = useMemo(() => {
    if (serverSidePaging && !!filterOverride?.items?.length) {
      return transpile(columns, filterOverride, filter);
    } else {
      return filter;
    }
  }, [filter, filterOverride, serverSidePaging, columns]);

  const { createEntity, deleteEntity, updateEntity, entities, loading, totalCount } = useEntities(type, filterQuery, isCurrent, page, pageSize, sortByPath, sortDescending);
  const showLoader = loading && entities.length === 0;

  const onHandleRowClick = useCallback((params, ev) => {
    if (window.getSelection()?.type !== "Range") {
      ev.preventDefault();
      if (!!onRowClick) {
        onRowClick(params, ev);
      } else {
        setSelectedEntity(entities.find(t => t.id === params.id));
        setDialog(true);
      }
    }
  }, [entities, onRowClick]);

  const onDialogClose = useCallback(() => {
    setDialog(null);
  }, []);

  const onCreateBegin = useCallback(() => {
    setSelectedEntity(null);
    setDialog(true);
  }, []);

  const onCreateEntity = useCallback(async (values) => {
    setUpdating(true);
    const e = await createEntity(values);
    setSelectedEntity(null);
    setDialog(null);
    setUpdating(false);
    if (typeof onCreated === "function") {
      onCreated(e);
    }
  }, [createEntity, onCreated]);

  const onUpdateEntity = useCallback(async (values, updatedFields) => {
    setUpdating(true);
    await updateEntity(values, updatedFields);
    setSelectedEntity(null);
    setDialog(null);
    setUpdating(false);
  }, [updateEntity]);

  const onDeleteEntity = useCallback(async () => {
    setUpdating(true);
    await deleteEntity(selectedEntity.id);
    setSelectedEntity(null);
    setDialog(null);
    setUpdating(false);
  }, [selectedEntity, deleteEntity]);

  const onSortModelChange = useCallback((sortModel) => {
    setSortModel(sortModel.length > 0 ? [sortModel[0]] : []);
  }, [setSortModel]);

  const renderColumns = useMemo(() => {
    if (!serverSidePaging) return columns;
    return columns.map(column => ({ ...column, filterOperators: GetFilterOperator(column) }));
  }, [columns, serverSidePaging]);

  return (
    <ToggleSurface
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}
      outerClassName={className}
      title={title}
    >
      <Grid item xs={12} container>
        <div className={classes.gridInnerContainer}>
          <DataGridPro
            disableColumnReorder
            sx={SX}
            className={`${classes.table} ${disableRowHover ? "" : classes.tableRowHover}`}
            rows={entities}
            loading={showLoader}
            columns={renderColumns}
            getRowHeight={autoHeight}
            onRowClick={onHandleRowClick}
            initialState={INITIAL_STATE}
            rowsPerPageOptions={useMemo(() => entities.length < PAGESIZE ? [Math.max(entities.length, 1), PAGESIZE, 50, 100, 150] : [PAGESIZE, 50, 100, 150], [entities.length])}
            disableSelectionOnClick
            autoHeight
            paginationMode={serverSidePaging ? "server" : "client"}
            pagination
            filterModel={!!filterOverride ? filterOverride : undefined}
            filterMode={serverSidePaging ? "server" : "client"}
            onFilterModelChange={setFilterOverride}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            onPageChange={serverSidePaging ? setPage : undefined}
            onPageSizeChange={serverSidePaging ? setPageSize : undefined}
            rowCount={serverSidePaging ? Math.max(totalCount, 0) : undefined}
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={setVisibleColumns}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={setPinnedColumns}
            components={COMPONENTS}
            componentsProps={{
              noRowsOverlay: {
                onCreateBegin,
                title: emptyStateTitle,
                description: emptyStateDescription,
                canCreate: !!createButton,
                button: t("entityTable:add")
              },
              toolbar: {
                canSearch: !serverSidePaging,
                isPinned,
                tableId,
                onReset,
              }
            }}
            localeText={(i18n.language === "no" ? nbNO : enUS)?.components.MuiDataGrid.defaultProps.localeText}
            experimentalFeatures={editable ? EDITABLE_PROPERTY : undefined}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={console.log}
          />
        </div>
      </Grid>

      {
        dialog ? (
          <EntityEditDialog
            createTitle={createTitle}
            editTitle={editTitle}
            canDelete={!!selectedEntity}
            entity={selectedEntity}
            updating={updating}
            fields={fields}
            open={dialog}
            onCancel={onDialogClose}
            onCreate={onCreateEntity}
            onUpdate={onUpdateEntity}
            onDelete={onDeleteEntity}
          />
        ) : null
      }

    </ToggleSurface>
  );
}
