import { useState, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DataGridPro, nbNO, enUS } from "@mui/x-data-grid-pro";
import EntityEditDialog from "../inputs/EntityEditDialog";
import { useTranslation } from "react-i18next";
import { usePersistentFilter } from "../../../hooks/usePersistentFilter";
import { PAGESIZE, INITIAL_STATE, COMPONENTS, SX, autoHeight } from "./constants";

const useStyles = makeStyles()(
  (theme) => ({
    gridInnerContainer: {
      flexGrow: 1,
      height: "auto",
      "& .MuiDataGrid-virtualScrollerContent": {
        minHeight: "240px !important"
      }
    },
    gridInnerContainerAutoHeight: {
      flexGrow: 1,
      height: "auto",
    },
    table: {
      border: "none",
      minHeight: "100%",
      "& .MuiDataGrid-row": {
        "&:hover": {
          background: theme.palette.action.hover
        }
      },
      "& .MuiDataGrid-cell:focus": {
        outline: "none"
      },
      "& ::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "& ::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.25)",
      },
    },
  })
);

export default function SimpleTable(props) {
  const { tableId, updating, onDelete, onUpdate, loading, rows, columns, fields, emptyStateTitle, emptyStateDescription, editTitle, onRowClick, apiRef, grow, getRowClassName, checkboxSelection, onSelectionModelChange, selectionModel, disablePaging } = props;
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  const [dialog, setDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const showLoader = loading && rows.length === 0;

  const {
    sortModel,
    filterOverride,
    setSortModel,
    setFilterOverride,
    onReset,
    isPinned,
    visibleColumns,
    setVisibleColumns,
    pinnedColumns,
    setPinnedColumns,
  } = usePersistentFilter(tableId);

  const onHandleRowClick = useCallback((params, ev) => {
    if (window.getSelection()?.type !== "Range") {
      ev.preventDefault();
      if (!!onRowClick) {
        onRowClick(params, ev);
      } else {
        setSelectedRow(rows.find(t => t.id === params.id));
        setDialog(true);
      }
    }
  }, [rows, onRowClick]);

  const onDialogClose = useCallback(() => {
    setDialog(null);
  }, []);

  const onHandleUpdate = useCallback(async (values, updatedFields) => {
    onUpdate(selectedRow, values, updatedFields);
    setSelectedRow(null);
    setDialog(null);
  }, [onUpdate, selectedRow]);

  const onHandleDelete = useCallback(async () => {
    onDelete(selectedRow);
    setSelectedRow(null);
    setDialog(null);
  }, [selectedRow, onDelete]);

  return (
    <Grid item xs={12} container>
      <div className={grow ? classes.gridInnerContainerAutoHeight : classes.gridInnerContainer}>
        <DataGridPro
          checkboxSelection={checkboxSelection}
          disableColumnReorder
          apiRef={apiRef}
          sx={SX}
          className={classes.table}
          rows={rows}
          loading={showLoader}
          columns={columns}
          getRowHeight={autoHeight}
          onRowClick={onHandleRowClick}
          initialState={INITIAL_STATE}
          getRowClassName={getRowClassName}
          columnVisibilityModel={visibleColumns}
          onColumnVisibilityModelChange={setVisibleColumns}

          pinnedColumns={pinnedColumns}
          onPinnedColumnsChange={setPinnedColumns}
          
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectionModel}
          
          filterModel={!!filterOverride ? filterOverride : undefined}
          onFilterModelChange={setFilterOverride}

          sortModel={sortModel}
          onSortModelChange={setSortModel}
          disableSelectionOnClick

          rowsPerPageOptions={useMemo(() => rows.length < PAGESIZE ? [Math.max(rows.length, 1), PAGESIZE, 50, 100, 150] : [PAGESIZE, 50, 100, 150], [rows.length])}
          paginationMode="client"
          pagination={!disablePaging}

          autoHeight
          components={COMPONENTS}
          componentsProps={{
            noRowsOverlay: {
              title: emptyStateTitle,
              description: emptyStateDescription,
            },
            toolbar: {
              canSearch: true,
              isPinned,
              tableId,
              onReset,
            }
          }}
          localeText={(i18n.language === "no" ? nbNO : enUS)?.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
      {
        dialog ? (
          <EntityEditDialog
            editTitle={editTitle}
            canDelete={!!selectedRow}
            entity={selectedRow}
            updating={updating}
            fields={fields}
            open={dialog}
            onCancel={onDialogClose}
            onUpdate={onHandleUpdate}
            onDelete={onHandleDelete}
          />
        ) : null
      }
    </Grid>
  );
}







