import { Grid, Alert, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity, Defaults, useAuth, useEntityById } from "@emberly/zenith-client";
import InvoiceInformationCard from "./information/InvoiceInformationCard";
import { ActivityEnums, OrderEnums } from "../../../../common/constants";
import InvoiceReadyInformationCard from "./information/InvoiceReadyInformationCard";
import JournalizeInvoiceButton from "./inputs/JournalizeInvoiceButton";
import { useCallback, useMemo, useState } from "react";
import { CalcToPaySum, GetOrderState, UpdateCustomer, UpdateSender, UpdateStateChanged } from "../../../../common/orders";
import { useOrder } from "../../../../providers/OrderProvider";
import { useMission } from "../../../../providers/MissionProvider";
import { useStation } from "../../../../providers/StationProvider";
import { MakeActivity } from "../../../../common/activity";
import MultilineButton from "../../inputs/MultilineButton";
import MissingExportSettingsWarning from "../MissingExportSettingsWarning";


export default function InvoiceFlow() {
  const { t } = useTranslation();
  const { entity, updateEntityField } = useEntity();
  const { user } = useAuth();
  const { order, hasValue } = useOrder();
  const { orders } = useMission();
  const { priceUnit, isAccountant, logEvent } = useStation();

  const [wizard, setWizard] = useState(false);
  const [hasExportSettings, setHasExportSettings] = useState(true);

  const state = entity.payment.invoice?.state || Defaults.Enum;
  const { entity: customerEntity } = useEntityById("Contact", entity.customer?.id, state === OrderEnums.InvoiceState.None && !!entity.customer.id);

  const contactPerson = entity?.payment?.invoice?.contactPerson;
  const contactPersonDoesntExist = !!contactPerson?.id && !customerEntity?.contactPersons?.find(t => t.id === contactPerson.id);

  const {
    isDeductibleTransferClosed,
  } = useMemo(() => {
    const transferDeductibleEntity = orders.find(t => t.deductible?.order?.id === order.id);

    return {
      isDeductibleTransferClosed: !transferDeductibleEntity || GetOrderState(transferDeductibleEntity) >= OrderEnums.OrderState.Ready,
    };
  }, [orders, order]);


  const {
    outgoingDeductibleTransferClosed,
  } = useMemo(() => {
    const outgoingDeductibleTransfer = !!order.deductible?.order?.id ? orders.find(t => t.id === order.deductible?.order?.id) : null;

    return {
      outgoingDeductibleTransferClosed: !!outgoingDeductibleTransfer && GetOrderState(outgoingDeductibleTransfer) >= OrderEnums.OrderState.Ready,
    };
  }, [orders, order]);


  const hasValidContact = entity.customer?.isReferenced;
  const cannotProceed = !hasValidContact || !isAccountant || !isDeductibleTransferClosed;

  const cannotReopen = !isAccountant || outgoingDeductibleTransferClosed;

  const onChangeContactPerson = useCallback((ev) => {
    const contactId = ev.target.value;
    const contact = customerEntity.contactPersons.find(t => t.id === contactId);
    updateEntityField("payment.invoice.contactPerson", contact);
  }, [updateEntityField, customerEntity]);


  const onProceed = useCallback(() => {
    updateEntityField("payment.invoice.state", OrderEnums.InvoiceState.Queued);
    UpdateStateChanged(updateEntityField);
    UpdateSender(user, updateEntityField);
    UpdateCustomer(customerEntity, updateEntityField);
    updateEntityField("payment.calculatedTotal", { value: CalcToPaySum(order, orders), currency: priceUnit });
    logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.InvoiceQueued, `${entity?.missionId}/${entity?.id}`));
  }, [updateEntityField, user, order, orders, priceUnit, customerEntity, entity, logEvent]);

  const onReopen = useCallback(() => {
    UpdateStateChanged(updateEntityField);
    updateEntityField("payment.invoice.state", OrderEnums.InvoiceState.None);
    logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.InvoiceReOpened, `${entity?.missionId}/${entity?.id}`));
  }, [updateEntityField, entity, logEvent]);


  return (
    <>
      {
        state === OrderEnums.InvoiceState.None ? (
          <>
            {
              customerEntity?.contactPersons?.length !== 0 || !!contactPerson ? (
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="filled">
                    <InputLabel>{t("order:invoiceFlow:contactPerson")}</InputLabel>
                    <Select
                      onChange={onChangeContactPerson}
                      value={contactPerson?.id || ""}
                    >
                      <MenuItem value="">{t("order:invoiceFlow:noContactPerson")}</MenuItem>

                      <Divider />

                      {contactPersonDoesntExist ? (
                        <MenuItem value={contactPerson.id}>{contactPerson.name}</MenuItem>
                      ) : null}

                      {customerEntity?.contactPersons?.map((c, key) => <MenuItem value={c.id} key={key}>{c.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null
            }

            <Grid item xs={12}>
              <InvoiceInformationCard />
            </Grid>

            {!hasValidContact ? (
              <Grid item xs={12}>
                <Alert color="warning">
                  {t("order:invoiceFlow:missingContact")}
                </Alert>
              </Grid>
            ) : null}


            {!isDeductibleTransferClosed ? (
              <Grid item xs={12}>
                <Alert color="warning">
                  {t("order:invoiceFlow:relatedOrderConflicting")}
                </Alert>
              </Grid>
            ) : null}


            <Grid item xs={12}>
              <MultilineButton fullWidth variant="contained" color="primary" disabled={cannotProceed || !hasValue} onClick={onProceed}>
                {t("order:invoiceFlow:readyForExport")}
              </MultilineButton>
            </Grid>

          </>
        ) : (
          <>
            <Grid item xs={12}>
              <InvoiceReadyInformationCard />
            </Grid>

            {
              state === OrderEnums.InvoiceState.Queued || wizard ? (
                <>

                  {
                    !hasExportSettings ? (
                      <Grid item xs={12}>
                        <MissingExportSettingsWarning/>
                      </Grid>
                    ) : null
                  }

                  <Grid item xs={12} md={isAccountant ? 6 : 12}>
                    <MultilineButton fullWidth variant="contained" color="neutral" onClick={onReopen} disabled={cannotReopen || wizard}>
                      {t("order:invoiceFlow:reopen")}
                    </MultilineButton>
                  </Grid>

                  {
                    isAccountant ? (
                      <Grid item xs={12} md={6}>
                        <JournalizeInvoiceButton setWizard={setWizard} setHasExportSettings={setHasExportSettings} />
                      </Grid>
                    ) : null
                  }

                </>
              ) : null
            }
          </>
        )
      }



    </>
  );
}
