import { makeStyles } from "tss-react/mui";
import { CircularProgress, Grid, Typography } from "@mui/material";
import Surface from "../core/Surface";
import { NavLink } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      minHeight: "100%",
      "& .MuiCollapse-wrapperInner, .MuiCollapse-wrapper": {
        minHeight: "100%"
      }
    },
    content: {
      height: "100%"
    },
    link: {
      textDecoration: "none",
      color: "inherit"
    },
    inlineIcon: {
      marginBottom: "-2px"
    },
    loader: {
      margin: "4px"
    }
  })
);

export default function NumberCard(props) {
  const { title, to, linkText, value, loading, xs, sm, md, lg, xl, xxl } = props;
  const { classes } = useStyles();

  return (
    <Surface xs={xs || 6} sm={sm} md={md || 4} lg={lg || 3} xl={xl} xxl={xxl} outerClassName={classes.root} className={classes.root}>
      <Grid item container xs={12} spacing={1} direction="column" justifyContent="center" alignItems="center" alignSelf="center">

        <Grid item>
          <Typography variant="subtitle1" textAlign="center">{title}</Typography>
        </Grid>

        <Grid item>
          {loading ? (
            <CircularProgress variant="indeterminate" className={classes.loader} />
          ) : (
            <Typography variant="h3" textAlign="center">{value}</Typography>
          )}
        </Grid>
        
        {
          !!to ? (
            <Grid item>
              <NavLink to={to} className={classes.link}>
                <Typography variant="body2" color="info.main" textAlign="center">{linkText} <ArrowForwardIcon fontSize="inherit" className={classes.inlineIcon} /></Typography>
              </NavLink>
            </Grid>
          ) : null
        }

      </Grid>
    </Surface>
  );
}