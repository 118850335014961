import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox, Divider, FormControl, InputLabel, Select, MenuItem, FormHelperText, ListItemText, ListSubheader, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEntityField, Defaults, useEntities } from "@emberly/zenith-client";
import { useMission } from "../../../../../../providers/MissionProvider";
import CollapseGridItem from "../../../../../common/core/CollapseGridItem";
import TextFieldDecimal from "../../../../../common/inputs/TextFieldDecimal";
import { useStation } from "../../../../../../providers/StationProvider";
import { makeStyles } from "tss-react/mui";

const pickLastValid = (a, b) => typeof b === "number" ? b : a;


const useStyles = makeStyles()(
  (theme) => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    startLocationSelect: {
      "& .MuiSelect-select": {
        paddingTop: theme.spacing(2)
      }
    }
  })
);


export default function OverrideRouteDetailsDialog(props) {
  const { open, onClose, path, includeDistanceFromStation } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();


  const { entities: startLocations } = useEntities("StartLocation");
  const { value: routeData } = useEntityField(`${path}.route`, Defaults.Object);
  const { updateMissionField } = useMission();
  const { station } = useStation();

  const [overriddenStartLocation, setOverriddenStartLocation] = useState(routeData.overriddenStartLocation || null);
  const hasOverriddenStartLocation = !!overriddenStartLocation;


  const [overriddenDistanceInRoute, setOverriddenDistanceInRoute] = useState(typeof routeData.overriddenDistanceInRouteKM === "number" ? pickLastValid(routeData.distanceInRouteKM, routeData.overriddenDistanceInRouteKM) : null);
  const hasOverriddenDistanceInRoute = typeof overriddenDistanceInRoute === "number";

  const [overriddenDistanceToRoute, setOverriddenDistanceToRoute] = useState(typeof routeData.overriddenDistanceToRouteKM === "number" ? pickLastValid(routeData.distanceToRouteKM, routeData.overriddenDistanceToRouteKM) : null);
  const hasOverriddenDistanceToRoute = typeof overriddenDistanceToRoute === "number";

  const [overrideReason, setOverrideReason] = useState(routeData.overrideReason || "");

  const onConfirm = useCallback(() => {
    updateMissionField(`${path}.route.overriddenStartLocation`, !!overriddenStartLocation ? { id: overriddenStartLocation.id, name: overriddenStartLocation.name, location: overriddenStartLocation.location } : null);
    updateMissionField(`${path}.route.overriddenDistanceToRouteKM`, overriddenDistanceToRoute);
    updateMissionField(`${path}.route.overriddenDistanceInRouteKM`, overriddenDistanceInRoute);
    updateMissionField(`${path}.route.overrideReason`, overrideReason);
    onClose();
  }, [onClose, path, overrideReason, overriddenStartLocation, overriddenDistanceToRoute, overriddenDistanceInRoute, updateMissionField]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t("mission:cards:tasks:map:overrideDialog:title")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>

          {
            !!includeDistanceFromStation ? (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="filled" className={classes.startLocationSelect}>
                    <InputLabel>{t("mission:cards:tasks:map:overrideDialog:overriddenStartLocation")}</InputLabel>
                    <Select
                      size="small"
                      value={overriddenStartLocation?.id || "station"}
                      onChange={(ev) => setOverriddenStartLocation(startLocations.find(t => t.id === ev.target.value))}
                    >
                      <ListSubheader>{t("mission:cards:tasks:map:overrideDialog:station")}</ListSubheader>
                      <MenuItem value="station">
                        <ListItemText
                          primary={station?.displayName}
                          secondary={station?.location?.address || ""}
                        />
                      </MenuItem>
                      {
                        !!startLocations.length ? (
                          <Divider />
                        ) : null
                      }
                      {
                        !!startLocations.length ? (
                          <ListSubheader>{t("mission:cards:tasks:map:overrideDialog:startLocation")}</ListSubheader>
                        ) : null
                      }
                      {
                        !!overriddenStartLocation && !startLocations.find(sl => sl.id === overriddenStartLocation.id) ? (
                          <MenuItem value={overriddenStartLocation.id}>
                            <ListItemText
                              primary={overriddenStartLocation.name}
                              secondary={overriddenStartLocation.description || "-"}
                            />
                          </MenuItem>
                        ) : null
                      }
                      {
                        startLocations.map((sl, key) => (
                          <MenuItem value={sl.id} key={key}>
                            <ListItemText
                              primary={sl.name}
                              secondary={sl.description || "-"}
                            />
                          </MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText>{t("mission:cards:tasks:map:overrideDialog:overriddenStartLocationDescription")}</FormHelperText>
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            ) : null
          }

          <Grid item xs={12}>
            <Typography variant="subtitle2">{t("mission:cards:tasks:map:overrideDialog:overrideDistanceTitle")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceInRoute")}
              control={<Checkbox size="small" checked={hasOverriddenDistanceInRoute} onChange={(ev, checked) => setOverriddenDistanceInRoute(checked ? routeData.distanceInRouteKM : null)} />}
            />
          </Grid>

          <CollapseGridItem in={hasOverriddenDistanceInRoute} xs={12}>
            <TextFieldDecimal
              label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceInRouteLabel")}
              variant="filled"
              value={hasOverriddenDistanceInRoute ? overriddenDistanceInRoute : 0}
              onChange={(ev, v) => setOverriddenDistanceInRoute(v)}
              selectOnFocus
              fullWidth
              size="small"
            />
          </CollapseGridItem>

          {
            includeDistanceFromStation ? (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceToRoute")}
                    control={<Checkbox size="small" checked={hasOverriddenDistanceToRoute} onChange={(ev, checked) => setOverriddenDistanceToRoute(checked ? routeData.distanceToRouteKM : null)} />}
                  />
                </Grid>

                <CollapseGridItem in={hasOverriddenDistanceToRoute} xs={12}>
                  <TextFieldDecimal
                    label={t("mission:cards:tasks:map:overrideDialog:overriddenDistanceToRouteLabel")}
                    variant="filled"
                    value={hasOverriddenDistanceToRoute ? overriddenDistanceToRoute : 0}
                    onChange={(ev, v) => setOverriddenDistanceToRoute(v)}
                    selectOnFocus
                    fullWidth
                    size="small"
                  />
                </CollapseGridItem>
              </>
            ) : null
          }


          <CollapseGridItem in={hasOverriddenStartLocation || hasOverriddenDistanceInRoute || hasOverriddenDistanceToRoute} xs={12}>
            <Divider style={{ marginBottom: "8px" }} />
            <TextField
              label={t("mission:cards:tasks:map:overrideDialog:overrideReasonLabel")}
              variant="filled"
              value={overrideReason}
              onChange={ev => setOverrideReason(ev.target.value)}
              fullWidth
              multiline
              size="small"
            />
          </CollapseGridItem>


        </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("mission:cards:tasks:map:overrideDialog:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t("mission:cards:tasks:map:overrideDialog:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*

   overrideDialog: {
              title: "Overstyr distanse kjørt",
              overriddenDistanceInRoute: "Overstyr distanse i rute",
              overriddenDistanceToRoute: "Overstyr distanse til/fra rute",
              overriddenDistanceInRouteLabel: "Overstyrt verdi - distanse i rute",
              overriddenDistanceToRouteLabel: "Overstyrt verdi - distanse til/fra rute",
              cancel: "Avbryt",
              confirm: "Endre"
            }

            
          <TextField
            style={{ marginTop: "8px" }}
            label={label}
            variant="filled"
            value={value}
            onChange={ev => setValue(ev.target.value)}
            fullWidth
            size="small"
          />

*/