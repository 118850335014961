import { Grid, Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import SurfaceSection from "../../../../common/core/SurfaceSection";
import CarDetails from "./CarDetails";
import BoatDetails from "./BoatDetails";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import { MissionEnumsLists } from "../../../../../common/constants";

export default function TargetSection(props) {
  const { locked } = props;
  const { t } = useTranslation();
  const type = useEntityField("target.type", Defaults.Enum);
  const description = useEntityField("target.description", Defaults.String);

  return (
    <SurfaceSection title={t("mission:cards:target:object")}>

      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="filled" disabled={locked}>
          <InputLabel>{t("mission:cards:target:type")}</InputLabel>
          <Select onChange={type.onChange} value={type.value}>
            {MissionEnumsLists.TargetType.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:targetType:${type}`)}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      {type.value === 1 ? (
        <CarDetails locked={locked} />
      ) : type.value === 2 ? (
        <BoatDetails locked={locked} />
      ) : null}

      <Grid item xs={12}>
        <TextField
          label={t("mission:cards:target:description")}
          value={description.value}
          onChange={description.onChange}
          variant="filled"
          maxRows={6}
          minRows={3}
          fullWidth
          multiline
          disabled={locked}
        />
      </Grid>

    </SurfaceSection>
  );
}