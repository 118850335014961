import { useTranslation } from "react-i18next";
import { Button, Typography, Grid, Chip, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MissionEnumsLists, TaskEnums } from "../../../../common/constants";
import { useDriver } from "../../../../providers/DriverProvider";
import { useCallback, useState } from "react";
import { UpdateAssignedState, GetTaskDriverState, GetTaskDriverStateColor } from "../../../../common/mission";
import { NavLink } from "react-router-dom"
import { FormatLocation } from "../../../../common/maphelpers";
import { useAuth } from "@emberly/zenith-client";
import { useStation } from "../../../../providers/StationProvider";
import { CompactTimeView } from "../../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    link: {
      textDecoration: "none",
      color: "inherit"
    },
    slimRoot: {
      position: "relative"
    },
    root: {
      position: "relative",
      cursor: "pointer",
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "calc(100% + 16px)",
        width: "calc(100% + 16px)",
        top: "-8px",
        left: "-8px",
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: theme.spacing(1),
        content: "''",
        display: "block",
        pointerEvents: "none"
      },
      "&:hover:after": {
        opacity: 1
      }
    },
    rootRejected: {
      position: "relative",
      cursor: "default",
      opacity: 0.65
    },
    descriptionUpper: {
      marginBottom: theme.spacing(0.25)
    },
    timeLabel: {
      marginLeft: theme.spacing(0.5)
    },
    chipContainer: {
      marginTop: theme.spacing(-0.25),
      marginLeft: theme.spacing(-1.25)
    },
    buttonContainer: {
      marginTop: 0
    },
    progress: {
      position: "absolute",
      top: 0,
      right: 0
    }
  })
);

export default function MissionItem(props) {
  const { missionTask, slim } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { updateEntity } = useDriver();
  const { user } = useAuth();
  const { logEvent } = useStation();

  const targetType = missionTask.missionTarget.type;
  const targetDetails = targetType === 1 ? missionTask.missionTarget.carDetails : (targetType === 2 ? missionTask.missionTarget.boatDetails : null);
  const driverAssignedState = missionTask.execution.driverAssignedState;

  const [updating, setUpdating] = useState(false);

  const acceptTask = useCallback(async (ev) => {
    ev.preventDefault();
    try {
      setUpdating(true);
      await UpdateAssignedState(missionTask, TaskEnums.DriverAssignedState.Accepted, updateEntity, user, logEvent);
    } catch (err) {
      console.log(err);
    } finally {
      setUpdating(false);
    }
  }, [updateEntity, missionTask, user, logEvent]);

  const rejectTask = useCallback(async (ev) => {
    ev.preventDefault();
    try {
      setUpdating(true);
      await UpdateAssignedState(missionTask, TaskEnums.DriverAssignedState.Rejected, updateEntity, user, logEvent);
    } catch (err) {
      console.log(err);
    } finally {
      setUpdating(false);
    }
  }, [updateEntity, missionTask, user, logEvent]);

  const location = missionTask.route?.waypoints[0];
  const formattedLocation = FormatLocation(location);

  return (
    <Grid item xs={12}>
      <NavLink to={driverAssignedState === TaskEnums.DriverAssignedState.Rejected ? undefined : `./${missionTask.missionId}/${missionTask.taskId}`} className={classes.link}>
        <Grid container alignItems="baseline" className={driverAssignedState === TaskEnums.DriverAssignedState.Rejected ? classes.rootRejected : (slim ? classes.slimRoot : classes.root)}>
          <Grid item>
            <Typography variant="subtitle1">
              {t(`mission:enums:type:${MissionEnumsLists.Type[missionTask.missionDetails.type]}`)} {t(`mission:enums:targetType:${MissionEnumsLists.TargetType[missionTask.missionTarget.type]}`)} #{missionTask.missionNumber}.{missionTask.number}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color="textSecondary" className={classes.timeLabel}>
              <CompactTimeView time={missionTask.execution.history.created.lastModified} />
            </Typography>
          </Grid>

          <Grid item xs>
            {
              updating ? (
                <CircularProgress variant="indeterminate" className={classes.progress} />
              ) : (
                <Typography textAlign="end" variant={slim ? "subtitle2" : "subtitle1"} color={GetTaskDriverStateColor(driverAssignedState, missionTask.state)}>{GetTaskDriverState(driverAssignedState, missionTask.state, t, true)}</Typography>
              )
            }
          </Grid>



          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" className={classes.descriptionUpper}>
              {!!formattedLocation ? (<>{formattedLocation}<br /></>) : null}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {!!targetDetails ? `${targetDetails?.make || "-"} ${targetDetails?.model || "-"}, ${targetDetails?.registration || "-"} - ` : null}{missionTask?.missionDetails?.cause?.name || ""}
            </Typography>
          </Grid>

          <Grid item xs={12} container spacing={1} className={classes.chipContainer}>
            {missionTask?.missionDetails?.tags?.map((tag, i) => (
              <Grid item key={i}>
                <Chip label={tag.name} size={slim ? "small" : undefined} />
              </Grid>
            ))}
          </Grid>

          {driverAssignedState === TaskEnums.DriverAssignedState.Pending ? (
            <Grid item container xs={12} spacing={1} className={classes.buttonContainer}>

              <Grid item xs={6}>
                <Button
                  fullWidth size="large" color="primary" variant="outlined"
                  onClick={rejectTask}
                  disabled={updating}
                >
                  Avslå
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth size="large" color="primary" variant="contained"
                  onClick={acceptTask}
                  disabled={updating}
                >
                  Godta
                </Button>
              </Grid>

            </Grid>
          ) : null}

        </Grid>
      </NavLink>
    </Grid>
  );
}
