import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import ResponsivePage from "../../common/core/ResponsivePage";
import { EntityFieldTypes, useEntities } from "@emberly/zenith-client";
import { FixNumber, GetVatRate } from "../../../common/orders";
import { getCurrencyShorthand } from "../../../providers/StationProvider";

export default function ProductRegister() {
  const { t } = useTranslation();


  const { entities: vatCodes } = useEntities("VatCode");
  const { entities: productGroups } = useEntities("ProductGroup");

  const columns = useMemo(() => {
    return [
      {
        field: "code",
        headerName: t("product:fieldCode"),
        flex: 1,
        minWidth: 80,
      },
      {
        field: "name",
        headerName: t("product:fieldName"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "price.value",
        valueGetter: (params) => params.row.price.value,        
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.price?.value)} ${getCurrencyShorthand(t, params.row.price?.currency)}`,
        headerName: t("product:fieldPrice"),
        flex: 1,
        minWidth: 80,
      },
      {
        field: "vat",
        type: "number",
        valueGetter: (params) => Number(GetVatRate(params.row.productGroup?.id, productGroups, vatCodes)),
        headerName: t("product:fieldVAT"),
        valueFormatter: (params) => `${params.value}%`,
        flex: 1,
        minWidth: 60,
      },
      {
        field: "productGroup",
        valueGetter: (params) => params.row.productGroup?.name || "-",
        headerName: t("product:fieldProductGroup"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "account",
        valueGetter: (params) => productGroups.find(t => t.id === params.row.productGroup?.id)?.account || "-",
        headerName: t("product:fieldAccount"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: t("product:fieldDescription"),
        flex: 1,
        minWidth: 120,
      }
    ]
  }, [t, productGroups, vatCodes]);

  const fields = useMemo(() => [
    { name: t("product:fieldCode"), path: "code", type: EntityFieldTypes.String, required: true },
    { name: t("product:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("product:fieldPrice"), path: "price.value", type: EntityFieldTypes.String, dataType: "number" },
    { name: t("product:fieldProductGroup"), path: "productGroup", type: EntityFieldTypes.Select, options: productGroups.map(t => ({ id: t.id, name: `${t.code}. ${t.name}` })).sort((a, b) => a.name.localeCompare(b.name)), required: true },
    { name: t("product:fieldDescription"), path: "description", type: EntityFieldTypes.String, multiline: true },
  ], [t, productGroups])

  return (
    <ResponsivePage
      title={t("product:title")}
      xs={12}
      xl={8}
    >
      <EntityTable
        tableId="products"
        type="Product"
        columns={columns}
        fields={fields}
        createTitle={t("product:create")}
        editTitle={t("product:edit")}
        emptyStateTitle={t("product:emptyStateTitle")}
        emptyStateDescription={t("product:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}