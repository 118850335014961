import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      height: "auto",
      minHeight: "100%"
    }
  })
);


export default function MultilineButton(props) {
  const { classes } = useStyles();

  return (
    <Button
      className={`${classes.root} ${props?.className || ""}`}
      fullWidth={props.fullWidth}
      variant={props.variant}
      color={props.color}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </Button>

  );
}
