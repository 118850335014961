import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BuildMutations, GenerateCashExportData } from "../common/cashjournal";
import { useEntityById, useAuth, Compare, useEntities, useEntityFunctions, useMutateState, Task, StatusCode } from "@emberly/zenith-client";
import { useStation } from "../providers/StationProvider";
import { validateExportSettings } from "../common/exportformats/helpers";
import { MakeActivity } from "../common/activity";
import { ActivityEnums } from "../common/constants";

const Query = {
  name: "cashorders_ready_for_export",
  or: [
    {
      and: [
        { path: "payment.terminal.confirmed", comparer: Compare.EQ, value: true },
        { path: "payment.terminal.exported", comparer: Compare.EQ, value: false },
      ]
    },
    {
      and: [
        { path: "payment.request.confirmed", comparer: Compare.EQ, value: true },
        { path: "payment.request.exported", comparer: Compare.EQ, value: false },
      ]
    },
  ]
};

export function useCashExport() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { orgId, priceUnit, logEvent } = useStation();
  const { entities: orders, loading: loadingOrders } = useEntities("OrderMissionView", Query);
  const { entity: exportSettings, loading: loadingExportSettings } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: productGroups, loading: loadingProductGroups } = useEntities("ProductGroup");
  const { entities: vatCodes, loading: loadingVatCodes } = useEntities("VatCode");
  const { createEntity: createExportEntity } = useEntityFunctions("CashExport");

  const loading = loadingOrders || loadingProductGroups || loadingVatCodes || loadingExportSettings;
  
  const mutateState = useMutateState();
  const [working, setWorking] = useState(false);
  const [exportAction, setExportAction] = useState(null);

  const exportAll = useCallback(async (selectionModel) => {
    try {
      setWorking(true);
      const task = new Task();
      setExportAction(task);
      await task.wait();

      const selectedOrders = orders.filter(t => !!selectionModel.find(s => s === t.id));

      // craft initial data
      const { exportEntityData, journalItems, orderUpdates, exportId, exportEntityUpdate, errorCode } = GenerateCashExportData(
        selectedOrders, 
        exportSettings, 
        productGroups, 
        vatCodes, 
        user, 
        priceUnit, 
        t
      );

      if (!!errorCode) {
        return { success: false, exportId, errorCode };
      }

      // prepare export entity
      const exportEntity = await createExportEntity(exportEntityData)

      if (!exportEntity) {
        return { success: false, exportId, errorCode: 0 };
      }

      // map export number to mutations
      const mutations = BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates);

      // transmit mutations
      const response = await mutateState(mutations);
      const success = response?.statusCode === StatusCode.Success;

      if (success) {
        logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.CashJournalCreated, exportId));
      }

      return { success, exportId, errorCode };

    } catch (err) {
      console.log(err);
      return { success: false, exportId: null };
      
    } finally {
      setWorking(false);
    }
  }, [orders, mutateState, vatCodes, productGroups, t, exportSettings, user, priceUnit, createExportEntity, logEvent]);


  return {
    exportAll,
    working,
    orders,
    loading,
    setExportAction,
    exportAction,
    hasExportSettings: validateExportSettings(exportSettings)
  }
}