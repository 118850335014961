import { makeStyles } from "tss-react/mui";
import { Grid, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import OpenOrdersSection from "./subpages/OpenOrdersSection";
import AllOrdersSection from "./subpages/AllOrdersSection";

const useStyles = makeStyles()(
  (theme) => ({
    tabs: {
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
  })
);

export default function OrdersPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { tabId } = useParams();

  const navigate = useNavigate();
  const onTabChange = useCallback((_, val) => navigate(!!tabId ? `../${val}` : `./${val}`, { relative: "path", replace: true }), [navigate, tabId]);

  return (
    <ResponsivePage
      title={t("orders:title")}
      xs={12}
      sm={12}
      lg={12}
      xl={12}
      xxl={10}
    >

      <Grid item xs={12}>
        <Tabs className={classes.tabs} value={tabId || "open"} onChange={onTabChange}>
          <Tab value="open" label={t("orders:openOrdersTab")} />
          <Tab value="all" label={t("orders:allOrdersTab")} />
        </Tabs>
      </Grid>

      {
        !tabId || tabId === "open" ? (
          <OpenOrdersSection/>
        ) : tabId === "all" ? (
          <AllOrdersSection/>
        ) : null
      }

    </ResponsivePage>
  );
}

