import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import ResponsivePage from "../../common/core/ResponsivePage";

export default function ServiceVehicleRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "title",
        headerName: t("serviceVehicle:fieldTitle"),
        flex: 1,
        minWidth: 80,
      },
      {
        field: "active",
        headerName: t("serviceVehicle:fieldActive"),
        valueFormatter: (params) => t(params.value ? "serviceVehicle:fieldActiveLabelTrue" : "serviceVehicle:fieldActiveLabelFalse"),
        flex: 1,
        minWidth: 80,
      },
      {
        field: "driver",
        headerName: t("serviceVehicle:fieldDriver"),
        valueGetter: (params) => params.row.driver?.name || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "description",
        headerName: t("serviceVehicle:fieldDescription"),
        flex: 1,
        minWidth: 120,
      }
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("serviceVehicle:fieldTitle"), path: "title", type: EntityFieldTypes.String, required: true },
    { name: t("serviceVehicle:fieldActive"), path: "active", type: EntityFieldTypes.Boolean, labels: { true: t("serviceVehicle:fieldActiveLabelTrue"), false: t("serviceVehicle:fieldActiveLabelFalse") }, defaultValue: true },
    { name: t("serviceVehicle:fieldDescription"), path: "description", type: EntityFieldTypes.String },
  ], [t])

  return (
    <ResponsivePage
      title={t("serviceVehicle:title")}
      xs={12}
      xl={8}
    >
      <EntityTable
        tableId="service_vehicles"
        type="ServiceVehicle"
        columns={columns}
        fields={fields}
        createTitle={t("serviceVehicle:create")}
        editTitle={t("serviceVehicle:edit")}
        emptyStateTitle={t("serviceVehicle:emptyStateTitle")}
        emptyStateDescription={t("serviceVehicle:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}