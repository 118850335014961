import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CloudUploadOutlined as CloudUploadIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(
  (theme) => ({
    container: {
      position: "absolute",
      height: "100%",
      width: "100%",
      padding: theme.spacing(3),
      pointerEvents: "none",
      border: `3px solid ${theme.palette.text.secondary}`,
      borderRadius: theme.spacing(1),
      borderStyle: "dashed",
      background: theme.palette.background.default,
      zIndex: 10
    },
    grid: {
      width: "100%",
      height: "100%"
    },
    text: {
      maxWidth: "60%"
    }
  })
);

export default function DropTarget() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" justifyContent="center" direction="column" className={classes.grid}>
        <Grid item>
          <CloudUploadIcon />
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" textAlign="center">{t("mission:cards:files:dropTargetTitle")}</Typography>
        </Grid>

        <Grid item justifyContent="center" container>
          <Typography variant="body2" textAlign="center" color="textSecondary" className={classes.text}>{t("mission:cards:files:dropTargetDescription")}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
