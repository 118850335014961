import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import { FullTimeView } from "../../../core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      },
    },
  })
);

export default function RequestInformationCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { request } = entity.payment;

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">

        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("order:requestInformation:title")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:requestInformation:state")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{request?.confirmed ? t("order:requestInformation:stateConfirmed") : t("order:requestInformation:stateRequested")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:requestInformation:service")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{request.service}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:requestInformation:requestedDate")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{request.requested && !!request.requestedDate ? <FullTimeView isDate time={request.requestedDate} /> : "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:requestInformation:confirmedDate")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{request.confirmed && !!request.confirmedDate ? <FullTimeView isDate time={request.confirmedDate} /> : "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:requestInformation:person")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{entity?.payment?.sender?.name || ""}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:requestInformation:settlement")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{request.settled ? t("order:requestInformation:settled") : t("order:requestInformation:notSettled")}</Typography>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>
        
      </Grid>
    </div>
  );
}
