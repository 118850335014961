import { makeStyles } from "tss-react/mui";
import { GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles()(
  (theme) => ({
    container: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1)
    },
    item: {
      display: "flex",
      alignItems: "baseline"
    }

  })
);

const CSV_OPTIONS = {
  delimiter: ";",
  utf8WithBom: true,
};


export default function EntityTableToolbar(props) {
  const { canSearch, onReset, isPinned, tableId } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (

    <GridToolbarContainer className={classes.container}>
      {canSearch ? <GridToolbarQuickFilter /> : <div></div>}
      <span className={classes.item}>
        <GridToolbar csvOptions={CSV_OPTIONS} />
        {
          !!tableId && isPinned ? (
            <Button startIcon={<ClearIcon fontSize="inherit" color="primary" />} size="small" onClick={onReset}>
              {t("common:resetTable")}
            </Button>
          ) : null
        }
      </span>
    </GridToolbarContainer>

  );
}
