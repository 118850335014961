import { makeStyles } from "tss-react/mui";
import { Grid, Typography, TableRow, TableCell, Chip, Avatar, IconButton, MenuItem, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useUserManagement } from "../../../../providers/UserManagementProvider";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { RoleMap } from "../../../../common/constants";
import moment from "moment/moment";
import { FullTimeView } from "../../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    buttonCell: {
      textAlign: "right"
    },
    grid: {
      flexWrap: "nowrap"
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "30vw",
    }
  })
);

export default function InviteItem(props) {
  const { invite, slim } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { deleteInvite, resendInvite } = useUserManagement();
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onDeleteInvite = () => {
    handleMenuClose();
    deleteInvite(invite)
  };

  const onResendInvite = () => {
    handleMenuClose();
    resendInvite(invite);
  };

  const { created, expires, expired } = useMemo(() => {
    const expires = moment(invite.expiresAt);
    const diff = moment().diff(expires);

    return {
      created: moment(invite.createdAt),
      expires,
      expired: diff >= 0,
    }
  }, [invite]);

  return (
    <>
      <TableRow onClick={slim ? onOpenMenu : undefined}>
        <TableCell>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item className={classes.grid}>
              <Avatar alt={invite.email} />
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" className={classes.text}>
                {invite.email}
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.text}>
                <FullTimeView isDate time={created} />
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            {invite.roles?.map((r, key) => (
              <Grid item key={key}>
                <Chip label={t(`roles:${RoleMap[r]}`)} size="small" />
              </Grid>
            ))}
          </Grid>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color={expired ? "error" : undefined}>
            {expired ? t("userManagement:invites:expired") : expires.fromNow()}
          </Typography>
        </TableCell>

        {
          !slim ? (
            <TableCell className={classes.buttonCell}>
              <IconButton size="small" onClick={onOpenMenu}>
                <MoreIcon size="small" />
              </IconButton>
            </TableCell>
          ) : null
        }

      </TableRow>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={onResendInvite}>
          {t("userManagement:invites:resend")}
        </MenuItem>
        <MenuItem onClick={onDeleteInvite} color="error">
          <Typography color="error">
            {t("userManagement:invites:remove")}
          </Typography>
        </MenuItem>
      </Menu>

    </>
  );
}