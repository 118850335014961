import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import TextFieldDecimal from "../../../../common/inputs/TextFieldDecimal";

export default function BoatDetails(props) {
  const { locked } = props;
  const { t } = useTranslation();
  const registration = useEntityField("target.boatDetails.registration", Defaults.String);
  const make = useEntityField("target.boatDetails.make", Defaults.String);
  const model = useEntityField("target.boatDetails.model", Defaults.String);
  const lengthInFeet = useEntityField("target.boatDetails.lengthInFeet", Defaults.Number, true, true);
  const weightInKG = useEntityField("target.boatDetails.weightInKG", Defaults.Number, true, true);
  const year = useEntityField("target.boatDetails.year", Defaults.Number, true, true);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          disabled={locked}
          label={t("mission:cards:target:registration")}
          value={registration.value}
          onChange={registration.onChange}
          variant="filled"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          disabled={locked}
          label={t("mission:cards:target:make")}
          value={make.value}
          onChange={make.onChange}
          variant="filled"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          disabled={locked}
          label={t("mission:cards:target:model")}
          value={model.value}
          onChange={model.onChange}
          variant="filled"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldDecimal
          disabled={locked}
          label={t("mission:cards:target:year")}
          value={year.value === null ? "" : year.value}
          onChange={year.onChange}
          integer
          nullable
          variant="filled"
          fullWidth
          size="small"
          thousandSeparator=""
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldDecimal
          disabled={locked}
          label={t("mission:cards:target:length")}
          value={lengthInFeet.value === null ? "" : lengthInFeet.value}
          onChange={lengthInFeet.onChange}
          integer
          nullable
          variant="filled"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldDecimal
          disabled={locked}
          label={t("mission:cards:target:weight")}
          value={weightInKG.value === null ? "" : weightInKG.value}
          onChange={weightInKG.onChange}
          integer
          nullable
          variant="filled"
          suffix=" kg"
          fullWidth
          size="small"
        />
      </Grid>

    </>
  );
}