import { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { Grid, Button, TextField, Dialog, DialogActions, DialogTitle, DialogContent, Collapse, LinearProgress, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Divider, Typography, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EntityFieldTypes, Defaults, getValueOrDefault, patchDefaultValues, updateField } from "@emberly/zenith-client";
import LocationPicker from "./LocationPicker";
import { makeStyles } from "tss-react/mui";
import EntitySearchPicker from "./EntitySearchPicker";
import TextFieldDecimal from "./TextFieldDecimal";
import CountrySelect from "./CountrySelect";

const useStyles = makeStyles()(
  (theme) => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    listbox: {
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    }
  })
);

function loadInitialValues(entity, updateSet, fields) {
  let delta = { ...entity }
  patchDefaultValues(delta, updateSet, fields);
  return delta;
}

export default function EntityEditDialog(props) {
  const { entity, canDelete, open, fields, onCancel, onDelete, onCreate, onUpdate, updating, createTitle, editTitle } = props; // TODO
  const [hasUpdate, setHasUpdate] = useState(false);
  const [updatedFields, _] = useState(new Set());
  const [updates, setUpdates] = useState(loadInitialValues(entity, updatedFields, fields));

  const { t } = useTranslation();
  const createdRef = useRef(Date.now());
  const { classes } = useStyles();

  const onFieldChanged = useCallback((field, value) => {
    setUpdates(t => ({ ...updateField(t, field.path, value) }));
    updatedFields.add(field.path);
    setHasUpdate(true);
  }, [updatedFields]);

  const isCreate = typeof entity?.id !== "string";

  const requiredConditionsFulfilled = useMemo(() => {
    const requiredFields = fields.filter(t => t.required);
    return !requiredFields.find(t => !getValueOrDefault(updates, t.path, null));
  }, [fields, updates]);

  const onSubmit = useCallback(() => {
    if (isCreate) {
      patchDefaultValues(updates, updatedFields, fields);
      onCreate(updates, updatedFields)
    } else {
      onUpdate(updates, updatedFields);
    }
  }, [isCreate, updates, updatedFields, onUpdate, onCreate, fields]);

  const onKeyUp = useCallback(e => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (Date.now() - createdRef.current > 1000 && requiredConditionsFulfilled) {
        onSubmit();
      }
    }
  }, [onSubmit, requiredConditionsFulfilled]);

  const onFirstInputRef = useCallback((ref) => {
    setTimeout(() => {
      ref?.querySelectorAll("input")[0]?.focus()
      // TODO store the last one
    }, 1);
  }, []);

  return (
    <Dialog
      open={open}
      onKeyUp={onKeyUp}
      fullWidth
      maxWidth="xs"
    >
      <Collapse in={updating}>
        <LinearProgress variant="indeterminate" />
      </Collapse>

      <DialogTitle>
        {isCreate ? createTitle : editTitle}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} ref={onFirstInputRef}>
          {fields.map(
            (field, key) =>
              <Grid item key={key} xs={field.xs || 12}>
                <RegisterField field={field} entity={updates} onChange={onFieldChanged} updating={updating} classes={classes} />
              </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        {canDelete ? (
          <Button
            variant="text"
            color="error"
            onClick={onDelete}
            disabled={updating}
          >
            {t("entityTable:delete")}
          </Button>
        ) : null}
        <Button
          variant="outlined"
          onClick={onCancel}
          disabled={updating}
        >
          {t("entityTable:cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={updating || !hasUpdate || !requiredConditionsFulfilled}
          onClick={onSubmit}
        >
          {t(isCreate ? "entityTable:add" : "entityTable:edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function parseNumber(number, numberType, serialization = "string") {
  try {
    if (!number) return 0;

    if (numberType === "int") {
      return serialization === "number" ? parseInt(number) : `${parseInt(number)}`;
    } else {
      return serialization === "number" ? Number(number) : number;
    }
  } catch {
    return 0;
  }
}

function RegisterField(props) {
  const { field, onChange, entity, updating, classes } = props;

  const fieldActive = !!field.activeIfPath ? getValueOrDefault(entity, field.activeIfPath, Defaults.Boolean) : true
  const name = `${field.name}${field.required ? "*" : ""}`;

  switch (field.type) {
    case EntityFieldTypes.String:
      return (
        field.dataType === "number" ? (
          <TextFieldDecimal
            selectOnFocus
            label={name}
            variant="filled"
            fullWidth
            decimal
            size="small"
            value={getValueOrDefault(entity, field.path, Defaults.String) || ""}
            onChange={(ev) => onChange(field, parseNumber(ev.target.value, field.numberType, field.serialization))}
            disabled={updating || !fieldActive}
          />
        ) : (
          <TextField
            label={name}
            variant="filled"
            fullWidth
            size="small"
            type={field.dataType}
            value={getValueOrDefault(entity, field.path, Defaults.String)}
            onChange={(ev) => onChange(field, ev.target.value)}
            disabled={updating || !fieldActive}
            multiline={field.multiline}
          />
        )
      );

    case EntityFieldTypes.Boolean:
      return (
        !!field.labels?.true ? (
          <FormControl fullWidth size="small" variant="filled">
            <InputLabel>{name}</InputLabel>
            <Select
              value={getValueOrDefault(entity, field.path, typeof field.defaultValue !== "undefined" ? field.defaultValue : Defaults.Boolean)}
              onChange={(ev) => onChange(field, ev.target.value)}
              disabled={updating || !fieldActive}
            >
              <MenuItem value={false}>{field.labels?.false || "False"}</MenuItem>
              <MenuItem value={true}>{field.labels?.true || "True"}</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <FormControlLabel
            label={name}
            control={
              <Checkbox
                size="small"
                checked={getValueOrDefault(entity, field.path, typeof field.defaultValue !== "undefined" ? field.defaultValue : Defaults.Boolean)}
                onChange={(_, checked) => onChange(field, checked)}
                disabled={updating || !fieldActive}
              />
            }
          />
        )
      );

    case EntityFieldTypes.Select:
      return (
        <FormControl fullWidth size="small" variant="filled">
          <InputLabel>{name}</InputLabel>
          <Select
            value={getValueOrDefault(entity, field.path, typeof field.defaultValue !== "undefined" ? field.defaultValue : Defaults.NamedReference)?.id || ""}
            onChange={(ev) => onChange(field, { id: ev.target.value, name: field.options.find(t => t.id === ev.target.value)?.name || "" })}
            disabled={updating || !fieldActive}
          >
            {
              field.options.map((option, index) => (
                <MenuItem value={option.id} key={index}>{option.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      );

    case EntityFieldTypes.Location:
      return (
        <LocationPicker
          picker
          label={name}
          value={getValueOrDefault(entity, field.path, typeof field.defaultValue !== "undefined" ? field.defaultValue : Defaults.Location)}
          onChange={(location) => onChange(field, location)}
          disabled={updating || !fieldActive}
        />
      );

    case EntityFieldTypes.Autocomplete:
      return (
        <Autocomplete
          getOptionLabel={(option) => typeof option === "string" ? option : option.value}
          isOptionEqualToValue={(a, b) => a.value === b.value}
          options={field.suggestions}
          autoComplete
          fullWidth
          freeSolo={typeof field.freeSolo === "boolean" ? field.freeSolo : true}
          filterOptions={(x) => x}
          includeInputInList
          value={getValueOrDefault(entity, field.path, Defaults.String)}
          disabled={updating || !fieldActive}
          noOptionsText="No locations"
          onChange={(_, option) => onChange(field, option?.value || "")}
          onInputChange={(_, newInputValue) => onChange(field, newInputValue)}
          ListboxProps={{
            className: classes.listbox
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={name}
              size="small"
              variant="filled"
              fullWidth
              type={field.dataType}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent"
                }
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
        />
      );

    case EntityFieldTypes.Search:
      return (
        <EntitySearchPicker
          onChange={v => onChange(field, !!v && typeof field.valueGetter === "function" ? field.valueGetter(v) : v)}
          value={getValueOrDefault(entity, field.path, Defaults.Null)}
          type={field.entityType}
          path={field.searchPath}
          label={name}
          disabled={updating || !fieldActive}
        />
      );

    case EntityFieldTypes.Country:
      return (
        <CountrySelect
          onChange={v => onChange(field, !!v && typeof field.valueGetter === "function" ? field.valueGetter(v) : v)}
          value={getValueOrDefault(entity, field.path, field.defaultValue || Defaults.Null)}
          label={name}
          disabled={updating || !fieldActive}
        />
      );

    case EntityFieldTypes.Header:
      return (
        <Typography variant="subtitle1">{field.name}</Typography>
      )

    case EntityFieldTypes.Divider:
      return (
        <Divider className={classes.divider} />
      );

  }

  return (
    <div></div>
  );
}


