import { makeStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { Task, useEntities } from "@emberly/zenith-client";
import IntegrationCard from "./common/IntegrationCard";
import { IntegrationEnumsLists } from "../../../common/constants";
import { IntegrationLibrary } from "./common/library";
import { useCallback, useState } from "react";
import IntegrationDialog from "./common/IntegrationDialog";

const useStyles = makeStyles()(
  (theme) => ({
    header: {
      paddingTop: theme.spacing(2)
    },
  })
);

export default function IntegrationsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entities: integrations, createEntity: createIntegration, updateEntity: updateIntegration, deleteEntity: deleteIntegration } = useEntities("Integration");

  // create
  const [selectedServiceType, setSelectedServiceType] = useState(0);
  const [createAction, setCreateAction] = useState(null);

  // edit
  const [editAction, setEditAction] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const onCreateDialog = useCallback(async (serviceType) => {
    try {

      const action = new Task();
      setSelectedServiceType(serviceType);
      setCreateAction(action);

      const data = await action.wait();
      await createIntegration(data);

    } catch { } finally {
      setCreateAction(null);
      setSelectedServiceType(0);
    }
  }, [createIntegration]);

  const onEditDialog = useCallback(async (entity) => {
    try {

      const action = new Task();
      setSelectedEntity(entity);
      setEditAction(action);

      const data = await action.wait();
      await updateIntegration({ id: entity.id, ...data }, ["friendlyName", "apiKey", "userKey", "settings", "mappings"]);

    } catch (err) {
      if (err === "delete") {
        await deleteIntegration(entity.id);
      }
    } finally {
      setEditAction(null);
      setSelectedEntity(null);
    }
  }, [updateIntegration, deleteIntegration]);

  return (
    <ResponsivePage
      xs={12}
      sm={10}
      title={t("integrations:title")}
    >

      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">{t("integrations:description")}</Typography>
      </Grid>

      {
        integrations.length !== 0 ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.header}>{t("integrations:activeTitle")}</Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              {
                integrations.map((entity, i) =>
                  <Grid key={i} item xs={12} lg={6} xl={4}>
                    <IntegrationCard
                      title={entity.friendlyName}
                      icon={IntegrationLibrary.get(entity.serviceType).icon}
                      description={t(`integrations:library:${IntegrationEnumsLists.Service[entity.serviceType]}:description`)}
                      existing
                      onClick={() => onEditDialog(entity)}
                      available
                    />
                  </Grid>
                )
              }
            </Grid>
          </>
        ) : null
      }

      <Grid item xs={12}>
        <Typography variant="h6" className={classes.header}>{t("integrations:allTitle")}</Typography>
      </Grid>

      <Grid item container xs={12} spacing={2}>
        {
          IntegrationLibrary.entries().filter(([serviceType, integration]) => integration.hidden !== true).map(([serviceType, integration], i) => (
            <Grid key={i} item xs={12} lg={6} xl={4}>
              <IntegrationCard
                title={integration.name}
                icon={integration.icon}
                description={t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:description`)}
                onClick={() => onCreateDialog(serviceType)}
                available={integration.available}
              />
            </Grid>
          )).toArray()
        }
      </Grid>

      {
        !!createAction ? (
          <IntegrationDialog
            serviceType={selectedServiceType}
            task={createAction}
          />
        ) : null
      }

      {
        !!editAction ? (
          <IntegrationDialog
            serviceType={selectedEntity?.serviceType}
            task={editAction}
            entity={selectedEntity}
            existing
          />
        ) : null
      }

    </ResponsivePage>
  );
}