import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useEntities } from "@emberly/zenith-client";
import DashboardMapCard from "./cards/DashboardMapCard";
import NumberCard from "../../common/display/NumberCard";
import { ACTIVE_MISSIONS, ALL_OPEN_ORDERS } from "../../../common/queries";
import { Button, Collapse, Grid } from "@mui/material";
import { Cached as CachedIcon } from "@mui/icons-material";
import { useDevice } from "../../../providers/DeviceProvider";
import { useStation } from "../../../providers/StationProvider";
import config from "../../../config";
import { useSelector } from "react-redux";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      background: "rgb(240, 242, 245)"
    },
    version: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    updatingIcon: {
      animationName: "rotate",
      animationDuration: "3s",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear",
    }
  })
);

const nowrap = { whiteSpace: "nowrap" };

export default function HomePage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { slim } = useDevice();
  const { createMissionDraft } = useStation();
  const { entities: missions, loading: missionsLoading } = useEntities("Mission", ACTIVE_MISSIONS);
  const { entities: orders, loading: ordersLoading } = useEntities("Order", ALL_OPEN_ORDERS);
  const { serviceWorkerUpdated, serviceWorkerRegistration } = useSelector(state => state.app);
  const [updating, setUpdating] = useState(false);

  const showMissionsLoading = missionsLoading && missions.length === 0;
  const showOrdersLoading = ordersLoading && orders.length === 0;

  const updateServiceWorker = useCallback(() => {
    if (!!serviceWorkerRegistration) {
      setUpdating(true);
      const registrationWaiting = serviceWorkerRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", e => {
          if (e.target.state === "activated") {
            setTimeout(() => window.location.reload(), 500);
          }
        });
      }
    }
  }, [serviceWorkerRegistration]);


  const {
    activeServices,
    inStorage
  } = useMemo(() => {
    return {
      activeServices: missions.reduce((acc, mission) => acc + mission.salvageTasks.filter(t => !t.execution?.history?.completed && !t.execution?.history?.acknowledged).length, 0),
      inStorage: missions.reduce((acc, mission) => acc + (!!mission.storageTask && !mission.storageTask.delivered ? 1 : 0), 0)
    }
  }, [missions]);

  return (
    <ResponsivePage
      xs={12}
      xl={12}
      xxl={10}
      title={t("home:title")}
      className={classes.root}
      secondaryAction={t("home:createMission")}
      onSecondaryAction={createMissionDraft}
    >
      <Grid item xs={12}>

        <Grid item container xs={12} justifyContent="stretch" spacing={slim ? 2 : 3}>
          <NumberCard
            title={t("home:cards:activeServices:title")}
            linkText={t("home:cards:activeServices:linkText")}
            to="/dispatch"
            loading={showMissionsLoading}
            value={activeServices}
            xs={6} sm={6} md={6} lg={3}
          />

          <NumberCard
            title={t("home:cards:openMissions:title")}
            linkText={t("home:cards:openMissions:linkText")}
            to="/activemissions"
            loading={showMissionsLoading}
            value={missions.length}
            xs={6} sm={6} md={6} lg={3}
          />

          <NumberCard
            title={t("home:cards:inStorage:title")}
            linkText={t("home:cards:inStorage:linkText")}
            to="/storage"
            loading={showMissionsLoading}
            value={inStorage}
            xs={6} sm={6} md={6} lg={3}
          />

          <NumberCard
            title={t("home:cards:openOrders:title")}
            linkText={t("home:cards:openOrders:linkText")}
            to="/orders"
            loading={showOrdersLoading}
            value={orders.length}
            xs={6} sm={6} md={6} lg={3}
          />

        </Grid>
      </Grid>

      <DashboardMapCard missions={missions} />

      <Grid item container justifyContent="center" xs={12}>
        <Button
          onClick={serviceWorkerUpdated ? updateServiceWorker : undefined}
          className={classes.version}
          disabled={!serviceWorkerUpdated || updating}
          endIcon={serviceWorkerUpdated ? <CachedIcon className={updating ? classes.updatingIcon : undefined} /> : undefined}
        >
          Assist - v{config.version} {serviceWorkerUpdated ? <Collapse in={!updating} orientation="horizontal" style={nowrap}>&nbsp;- {t("home:updateAvailable")}</Collapse> : null}
        </Button>
      </Grid>

    </ResponsivePage>
  );
}
