import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEntityField, Defaults, Task } from "@emberly/zenith-client";
import ConfirmDialog from "../../../../common/inputs/ConfirmDialog";
import { useMission } from "../../../../../providers/MissionProvider";
import moment from "moment/moment";
import StorageSection from "../../../../common/sections/StorageSection";
import { ActivityEnums } from "../../../../../common/constants";

export default function StorageTaskDetails(props) {
  const { path, onDeleted, canDelete } = props;
  const { t } = useTranslation();

  const taskId = useEntityField(`${path}.taskId`, Defaults.String);
  const stored = useEntityField(`${path}.stored`, Defaults.String);
  const delivered = useEntityField(`${path}.delivered`, Defaults.Boolean);
  const deliveryTime = useEntityField(`${path}.deliveryTime`, Defaults.String);
  const { updateMissionField, logMissionEvent, locked } = useMission();

  const [deleteAction, setDeleteAction] = useState(null);
  const [deliverAction, setDeliverAction] = useState(null);

  const onDeleteService = useCallback(async () => {
    try {
      const task = new Task();
      setDeleteAction(task);

      await task.wait();
      onDeleted(taskId.value);
      await updateMissionField("storageTask", null);

    } catch { } finally {
      setDeleteAction(null);
    }

  }, [updateMissionField, onDeleted, taskId.value]);

  const onDeliverService = useCallback(async () => {
    try {
      const task = new Task();
      setDeliverAction(task);

      await task.wait();

      delivered.onChange(null, true);
      deliveryTime.onChange(null, moment.utc().format());
      logMissionEvent(ActivityEnums.Type.StoredObjectDelivered);

    } catch { } finally {
      setDeliverAction(null);
    }

  }, [delivered, deliveryTime, logMissionEvent]);

  return (
    <Grid item container spacing={1} justifyContent="flex-start" alignItems="flex-start" direction="row">
      
      <StorageSection />

      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Button color="primary" variant="contained" disabled={delivered.value || !stored.value || locked} onClick={onDeliverService}>{t("mission:cards:tasks:storageTask:onDeliverTitle")}</Button>
        </Grid>
        <Grid item>
          <Button color="neutral" variant="contained" onClick={onDeleteService} disabled={!canDelete || locked}>{t("mission:cards:tasks:storageTask:onDeleteTitle")}</Button>
        </Grid>
      </Grid>

      <ConfirmDialog
        title={t("mission:cards:tasks:storageTask:onDeliverTitle")}
        description={t("mission:cards:tasks:storageTask:onDeliverDescription")}
        confirmText={t("mission:cards:tasks:storageTask:onDeliverConfirm")}
        task={deliverAction}
      />

      <ConfirmDialog
        title={t("mission:cards:tasks:storageTask:onDeleteTitle")}
        description={t("mission:cards:tasks:storageTask:onDeleteDescription")}
        confirmText={t("mission:cards:tasks:storageTask:onDeleteConfirm")}
        task={deleteAction}
      />
    </Grid>
  );
}
