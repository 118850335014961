import { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Chip, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MissionEnums, MissionEnumsLists, OrderEnums, OrderEnumsLists } from "../../../common/constants";
import { AllTasksCompleted, AssumeStorageState } from "../../../common/mission";
import ResponsivePage from "../../common/core/ResponsivePage";
import { ACTIVE_MISSIONS } from "../../../common/queries";
import { useStation } from "../../../providers/StationProvider";
import SimpleTable from "../../common/tables/SimpleTable";
import { Compare, useEntities } from "@emberly/zenith-client";
import { ToggleSurface } from "../../common/core/Surface";
import { GetMissionOrderState } from "../../../common/orders";
import { TimeView } from "../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    grid: {
      maxWidth: "1600px"
    },
    driverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px"
    },
    noDriverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px",
      color: theme.palette.text.secondary
    }
  })
);

export default function ActiveMissionsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { createMissionDraft } = useStation();
  const navigate = useNavigate();

  const onRowClick = useCallback((params) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);



  const { entities: missions, loading } = useEntities("Mission", ACTIVE_MISSIONS, true, -1, -1, null, true);
  const showLoading = missions.length === 0 && loading;

  const { entities: orders, loading: ordersLoading } = useEntities("Order", useMemo(() => ({
    path: "missionId",
    comparer: Compare.IN,
    value: missions.map(t => t.id)
  }), [missions]), !loading && missions.length !== 0);

  const rows = useMemo(() => missions.map(m => ({ ...m, ordersLoading: ordersLoading || showLoading, orders: orders.filter(t => t.missionId === m.id) })), [missions, orders, ordersLoading]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("activeMissions:table:mission"),
        flex: 0
      },
      {
        field: "created",
        headerName: t("common:tables:created"),
        type: "dateTime",
        renderCell: (params) => <TimeView time={params.value} />,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "details.type",
        headerName: t("common:tables:type"),
        flex: 1,
        minWidth: 120,
        type: "singleSelect",
        valueGetter: (params) => params.row.details.type,
        valueFormatter: (params) => t(`mission:enums:type:${MissionEnumsLists.Type[params.value]}`),
        valueOptions: MissionEnumsLists.Type.map((_, value) => ({
          value,
          label:  t(`mission:enums:type:${MissionEnumsLists.Type[value]}`)
        }))
      },
      {
        field: "object",
        headerName: t("common:tables:object"),
        valueGetter: (params) => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details ? `${details.make} ${details.model}` : "-";
        },
        flex: 1,
        minWidth: 120,
      },
      {
        field: "registration",
        headerName: t("common:tables:registration"),
        valueGetter: params => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details?.registration || "-"
        },
        minWidth: 100,
        flex: 1
      },
      {
        field: "agreement",
        headerName: t("activeMissions:table:agreement"),
        valueGetter: (params) => params.row.actors?.contract?.agreement?.name || "-",
        flex: 1,
        minWidth: 80,
      },
      {
        field: "taskState",
        type: "singleSelect",
        headerName: t("activeMissions:table:taskState"),
        valueGetter: (params) => AllTasksCompleted(params.row),
        renderCell: (params) => params.value ? <Chip color="success" label={t("activeMissions:table:tasksCompleted")} /> : <Chip color="warning" label={t("activeMissions:table:tasksNotCompleted")} />,
        valueOptions: [
          { value: true, label: t("activeMissions:table:tasksCompleted") },
          { value: false, label: t("activeMissions:table:tasksNotCompleted") },
        ],
        flex: 1,
        minWidth: 80,
      },
      {
        field: "storageState",
        headerName: t("activeMissions:table:storageState"),
        type: "singleSelect",
        valueGetter: (params) => AssumeStorageState(params.row),
        renderCell: (params) =>
          params.value === MissionEnums.StorageState.None ?
            <Chip label={t("activeMissions:table:noStorage")} /> :
            params.value === MissionEnums.StorageState.Stored ?
              <Chip color="warning" label={t("activeMissions:table:storageNotCompleted")} /> :
              <Chip color="success" label={t("activeMissions:table:storageCompleted")} />,
        valueOptions: [
          { value: MissionEnums.StorageState.None, label: t("activeMissions:table:noStorage") },
          { value: MissionEnums.StorageState.Stored, label: t("activeMissions:table:storageNotCompleted") },
          { value: MissionEnums.StorageState.Delivered, label: t("activeMissions:table:storageCompleted") },
        ],
        flex: 1,
        minWidth: 120,
      },
      {
        field: "orders.state",
        headerName: t("activeMissions:table:orderState"),
        type: "singleSelect",
        valueGetter: (params) => GetMissionOrderState(params.row.orders),
        renderCell: (params) => params.row.ordersLoading ? <Skeleton width="64px" /> : (
          <Chip color={params.value === OrderEnums.MissionOrderState.Closed ? "success" : params.value === OrderEnums.MissionOrderState.Open ? "warning" : "error"} label={t(`order:enums:missionOrderState:${OrderEnumsLists.MissionOrderState[params.value]}`)} />
        ),
        valueOptions: [
          { value: 0, label: t("order:enums:missionOrderState:None") },
          { value: 1, label: t("order:enums:missionOrderState:Open") },
          { value: 2, label: t("order:enums:missionOrderState:Closed") },
        ],
        flex: 0.75,
        minWidth: 100
      },
      {
        field: "orders.length",
        headerName: t("activeMissions:table:orderCount"),
        type: "number",
        valueGetter: (params) => params.row.orders.length,
        renderCell: (params) => params.row.ordersLoading ? <Skeleton width="24px" /> : params.row.orders.length,
        flex: 0.5,
        minWidth: 100
      },
    ]
  }, [t, classes]);

  return (
    <ResponsivePage
      title={t("activeMissions:title")}
      secondaryAction={t("activeMissions:createMission")}
      onSecondaryAction={createMissionDraft}
    >
      <ToggleSurface xs={12}>
        <SimpleTable
          tableId="active_missions"
          loading={showLoading}
          rows={rows}
          columns={columns}
          onRowClick={onRowClick}
          emptyStateTitle={t("activeMissions:emptyStateTitle")}
          emptyStateDescription={t("activeMissions:emptyStateDescription")}
        />
      </ToggleSurface>
    </ResponsivePage>
  );
}
