import { makeStyles } from "tss-react/mui";
import { Grid, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import DateAndTimePicker from "../inputs/DateAndTimePicker";
import { useMission } from "../../../providers/MissionProvider";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import TextFieldDecimal from "../inputs/TextFieldDecimal";

const useStyles = makeStyles()(
  (theme) => ({
    checkbox: {
      marginLeft: theme.spacing(1)
    },
  })
);

export default function ServiceInfoSection() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showServiceInfo, locked } = useMission();

  const serviceCenter = useEntityField("serviceInfo.serviceCenter", Defaults.String, showServiceInfo);
  const lastService = useEntityField("serviceInfo.lastService", Defaults.String, showServiceInfo);
  const validTo = useEntityField("serviceInfo.validTo", Defaults.String, showServiceInfo);
  const validWarranty = useEntityField("serviceInfo.validWarranty", Defaults.Boolean, showServiceInfo);
  const mileageAtService = useEntityField("serviceInfo.mileageAtService", Defaults.Number, showServiceInfo, true);
  const currentMileage = useEntityField("serviceInfo.currentMileage", Defaults.Number, showServiceInfo, true);

  return (
    <Grid item container spacing={1}>

      <Grid item xs={12}>
        <DateAndTimePicker
          disabled={locked}
          label={t("mission:cards:service:lastService")}
          onChange={lastService.onChange}
          value={lastService.value}
          fromNow
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={locked}
          label={t("mission:cards:service:serviceCenter")}
          onChange={serviceCenter.onChange}
          value={serviceCenter.value}
          variant="filled"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <DateAndTimePicker
          disabled={locked}
          label={t("mission:cards:service:validTo")}
          onChange={validTo.onChange}
          value={validTo.value}
          fromNow
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldDecimal
          disabled={locked}
          label={t("mission:cards:service:mileageAtService")}
          value={mileageAtService.value}
          onChange={mileageAtService.onChange}
          variant="filled"
          nullable
          integer
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldDecimal
          disabled={locked}
          label={t("mission:cards:service:mileage")}
          value={currentMileage.value}
          onChange={currentMileage.onChange}
          nullable
          variant="filled"
          fullWidth
          integer
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label={t("mission:cards:service:validWarranty")}
          control={<Checkbox className={classes.checkbox} size="small" checked={validWarranty.value} onChange={validWarranty.onChange} disabled={locked} />}
        />
      </Grid>

    </Grid>
  );
}
