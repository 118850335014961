import { Grid, Button, MenuItem, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, Alert, Divider, ListItemIcon, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntityFunctions } from "@emberly/zenith-client";
import { useState } from "react";
import { useMission } from "../../../../providers/MissionProvider";
import { CanChangePaymentMethod, MakeInvoiceInfo } from "../../../../common/orders";
import { OrderEnums } from "../../../../common/constants";
import { makeStyles } from "tss-react/mui";


const useStyles = makeStyles()(
  (theme) => ({
    link: {
      color: theme.palette.info.main,
    },
  })
);

export default function VatTransferDialog(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { updateEntityField, entity } = useEntity();
  const { orders, makeOrder, mission } = useMission();
  const { updateEntity } = useEntityFunctions("Order");

  const edit = !!entity.vatTransfer;
  const [transferOrder, setTransferOrder] = useState(edit ? entity.vatTransfer.order.id : "");

  const availableOrders = orders.filter(t => t.id === transferOrder || t.id === entity?.vatTransfer?.order?.id || (t.id !== entity.id && !t.vatTransfer && CanChangePaymentMethod(t.payment) && !orders.find(c => c.vatTransfer?.order?.id === t.id)));
  const selectedOrder = availableOrders.find(t => t.id === transferOrder);
  const isNotInvoice = !!selectedOrder && selectedOrder?.payment?.method !== OrderEnums.PaymentMethod.Invoice;
  const selfIsNotInvoice = entity?.payment?.method !== OrderEnums.PaymentMethod.Invoice;

  const onSubmit = async () => {

    if (selfIsNotInvoice) {
      updateEntity({ id: entity.id, payment: { invoice: MakeInvoiceInfo(mission), method: OrderEnums.PaymentMethod.Invoice } }, ["payment.invoice", "payment.request", "payment.terminal", "payment.method"]);
    }

    if (transferOrder === "create") {
      const order = await makeOrder({ invoice: MakeInvoiceInfo(mission), method: OrderEnums.PaymentMethod.Invoice });

      if (!!order) {
        updateEntityField("vatTransfer", { order: { id: order.id, name: order.name } });
        onClose();
      }
    } else {
      const order = availableOrders.find(t => t.id === transferOrder);
      updateEntityField("vatTransfer", { order: { id: order.id, name: order.name } });

      if (isNotInvoice) {
        updateEntity({ id: order.id, payment: { invoice: MakeInvoiceInfo(mission), method: OrderEnums.PaymentMethod.Invoice } }, ["payment.invoice", "payment.request", "payment.terminal", "payment.method"]);
      }

      onClose();
    }
  };

  const onDelete = () => {
    updateEntityField("vatTransfer", null);
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t("order:vatTransferDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t("order:vatTransferDialog:description")} <a className={classes.link} target="_blank" href="https://help.getassist.app/nb/articles/10111567-mva-overforing-for-bedriftskunder-med-forsikring">{t("order:vatTransferDialog:linkText")}</a>
            </Typography>
          </Grid>

          {
            !!selfIsNotInvoice ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  {t("order:vatTransferDialog:convertSelfToInvoice")}
                </Alert>
              </Grid>
            ) : null
          }

          {
            !!isNotInvoice ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  {t("order:vatTransferDialog:convertToInvoice")}
                </Alert>
              </Grid>
            ) : null
          }

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("order:vatTransferDialog:order")}</InputLabel>
              <Select onChange={ev => setTransferOrder(ev.target.value)} value={transferOrder}>
                <MenuItem value={""}>{t("order:vatTransferDialog:none")}</MenuItem>
                <Divider />
                <MenuItem value="create">
                  {
                    transferOrder !== "create" ? (
                      <ListItemIcon>
                        <AddIcon fontSize="small" />
                      </ListItemIcon>
                    ) : null
                  }
                  {t("order:vatTransferDialog:create")}
                </MenuItem>
                <Divider />
                {availableOrders.map((order, idx) => <MenuItem selected={order.id === transferOrder} value={order.id} key={idx}>{t("order:vatTransferDialog:order")} {order.name}{!!order?.customer?.name ? " - " : ""}{order.customer?.name || ""}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        {!!edit ? (
          <Button onClick={onDelete}>
            {t("order:vatTransferDialog:delete")}
          </Button>
        ) : null}
        <Button onClick={onClose} variant={!!edit ? "outlined" : undefined}>
          {t("order:vatTransferDialog:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!transferOrder}>
          {t("order:vatTransferDialog:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


