import { Skeleton } from "@mui/material";
import mapboxgl from "mapbox-gl";
import { useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  () => ({
    root: {
      position: "relative",
      overflow: "hidden"
    },
    image: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      opacity: 0,
      transition: "opacity 0.25s"
    },
    skeleton: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 99,
      top: 0,
      left: 0,
      transition: "opacity 0.25s"
    },
    skeletonLoaded: {
      opacity: 0,
    },
    imageLoaded: {
      opacity: 1
    }
  })
);

export default function MapboxImage(props) {
  const { geojson, className } = props;
  const { classes } = useStyles();

  const [loaded, setLoaded] = useState(false);

  const geojsonData = useMemo(() => {
    if (typeof geojson === "string") {
      return encodeURIComponent(geojson);
    } else {
      return encodeURIComponent(JSON.stringify(geojson));
    }
  }, [geojson]);


  return (
    <div className={`${classes.root} ${className || ""}`}>
      <img
        className={`${classes.image} ${loaded ? classes.imageLoaded : ""}`}
        onLoad={() => setLoaded(true)}
        src={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(${geojsonData})/auto/500x500?access_token=${mapboxgl.accessToken}`}
      />
      {!loaded ? <Skeleton alt="map section" className={`${classes.skeleton} ${loaded ? classes.skeletonLoaded : ""}`} variant="rectangular" /> : null}
    </div>
  );
}