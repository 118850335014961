import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEntities, Compare } from "@emberly/zenith-client";
import { ActivityEnums, MissionEnums } from "../../../common/constants";
import moment from "moment/moment";
import SimpleTable from "../../common/tables/SimpleTable";
import { ToggleSurface } from "../../common/core/Surface";
import { MakeEmptyStorage, GetDaysStored, GetStoragePrice } from "../../../common/mission";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useStation } from "../../../providers/StationProvider";
import { MakeActivity } from "../../../common/activity";
import { FullTimeView } from "../../common/core/Time";

const query = {
  name: "active_storage_filter",
  and: [
    {
      path: "storageTask",
      comparer: Compare.EXISTS,
      value: true
    },
    {
      path: "state",
      comparer: Compare.EQ,
      value: MissionEnums.State.Created
    },
  ]
};

export default function StoragePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { entities, createEntity, loading } = useEntities("Mission", query);
  const { logEvent } = useStation();
  const showLoading = loading && entities.length === 0;

  const onMissionCreate = useCallback(async () => {
    const mission = await createEntity({ storageTask: MakeEmptyStorage() });
    logEvent(MakeActivity(ActivityEnums.Category.Mission, ActivityEnums.Type.MissionDraftCreated, mission?.id));
    navigate(`../drafts/${mission.id}/1`, { relative: "path" });
  }, [createEntity, navigate, logEvent]);

  const onRowClick = useCallback((params) => {
    navigate(`./${params.id}/${params.row.storageTask.number}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        headerName: t("storage:table:mission"),
        flex: 0
      },
      {
        field: "stored",
        headerName: t("storage:table:stored"),
        type: "dateTime",
        valueGetter: params => params.row.storageTask?.stored,
        renderCell: (params) => <FullTimeView time={params.value} isDate />,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "object",
        headerName: t("common:tables:object"),
        valueGetter: (params) => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details ? `${details.make} ${details.model}` : "-";
        },
        flex: 1,
        minWidth: 120,
      },
      {
        field: "warehouse",
        headerName: t("storage:table:warehouse"),
        valueGetter: params => params.row.storageTask?.warehouse?.name || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "keyNumber",
        headerName: t("storage:table:keyNumber"),
        valueGetter: params => params.row.storageTask?.keyNumber || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "reminder",
        headerName: t("storage:table:reminder"),
        type: "dateTime",
        valueGetter: params => params.row.storageTask?.reminder,
        valueFormatter: (params) => !!params.value ? `${moment(params.value).format("ll")} (${moment(params.value).fromNow()})` : "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "daysInStorage",
        headerName: t("storage:table:daysInStorage"),
        type: "number",
        valueGetter: params => GetDaysStored(params.row.storageTask),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "storageCost",
        headerName: t("storage:table:storageCost"),
        type: "number",
        valueGetter: params => GetStoragePrice(params.row.storageTask),
        valueFormatter: params => `${params.value},-`,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "comment",
        headerName: t("storage:table:comment"),
        valueGetter: params => params.row.storageTask?.comment || "-",
        flex: 1,
        minWidth: 120,
      },
    ]
  }, [t]);

  const storedItems = useMemo(() => {
    return entities.filter(t => t.storageTask?.delivered === false);
  }, [entities]);

  return (
    <ResponsivePage
      title={t("storage:title")}
      secondaryAction={t("storage:createStorage")}
      onSecondaryAction={onMissionCreate}
    >
      <ToggleSurface xs={12}>
        <SimpleTable
          tableId="storage"
          loading={showLoading}
          rows={storedItems}
          columns={columns}
          onRowClick={onRowClick}
          emptyStateTitle={t("storage:emptyStateTitle")}
          emptyStateDescription={t("storage:emptyStateDescription")}
          sortDescendingByDefault
        />
      </ToggleSurface>
    </ResponsivePage>
  );
}