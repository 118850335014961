import { makeStyles } from "tss-react/mui";
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, FormControlLabel, Checkbox, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import SurfaceSection from "../../../../common/core/SurfaceSection";
import { useCallback, useMemo } from "react";
import { MissionEnumsLists } from "../../../../../common/constants";
import AgreementLink from "./AgreementLink";
import TextFieldDecimal from "../../../../common/inputs/TextFieldDecimal";
import { useEntityField, Defaults, useEntities } from "@emberly/zenith-client";

const useStyles = makeStyles()(
  (theme) => ({
    collapse: {
      width: "100%"
    }
  })
);

export default function ContractSection(props) {
  const { locked } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  // Agreement
  const { entities: agreements } = useEntities("Agreement");
  const agreement = useEntityField("actors.contract.agreement", Defaults.Null);

  const agreementDoesNotExist = agreement.value !== null && !agreements.find(t => t.id === agreement.value.id);

  const onSelectAgreement = useCallback(ev =>
    agreement.onChange(null, !ev.target.value ? null : { id: ev.target.value, name: agreements.find(t => t.id === ev.target.value).name }),
    [agreement.onChange, agreements]
  );

  const agreementEntity = useMemo(() => !!agreement.value && !!agreements ? agreements.find(t => t.id === agreement.value.id) : null, [agreement.value, agreements]);

  // General
  const deductible = useEntityField("actors.contract.deductible.value", Defaults.String);
  const reference = useEntityField("actors.contract.reference", Defaults.String);
  const description = useEntityField("actors.contract.description", Defaults.String);

  // Insurance
  const isInsuranceContract = useEntityField("actors.contract.isInsuranceContract", Defaults.Boolean);
  const insuranceState = useEntityField("actors.contract.insurance.state", Defaults.Enum);
  const insuranceType = useEntityField("actors.contract.insurance.type", Defaults.Enum);
  const insurer = useEntityField("actors.contract.insurance.insurer", Defaults.String);

  return (
    <SurfaceSection title={t("mission:cards:actors:contract:title")}>

      <Grid item xs={12}>

        <FormControl fullWidth size="small" variant="filled" disabled={locked}>
          <InputLabel>{t("mission:cards:actors:contract:agreement")}</InputLabel>
          <Select
            onChange={onSelectAgreement}
            value={agreement.value?.id || ""}
          >
            {!!agreement.value ? (
              <MenuItem value="">{t("mission:cards:actors:contract:noAgreement")}</MenuItem>
            ) : null}

            {agreementDoesNotExist ? (
              <MenuItem value={agreement.value.id}>{agreement.value.name}</MenuItem>
            ) : null}

            {agreements.map((t, key) => <MenuItem value={t.id} key={key}>{t.name}</MenuItem>)}
          </Select>
        </FormControl>
        <AgreementLink
          agreementNumber={agreementEntity?.number}
          agreementId={agreementEntity?.id}
          open={!!agreementEntity}
          description={agreementEntity?.description}
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldDecimal
          disabled={locked}
          label={t("mission:cards:actors:contract:deductible")}
          variant="filled"
          value={deductible.value}
          onChange={deductible.onChange}
          decimal
          selectOnFocus
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={locked}
          label={t("mission:cards:actors:contract:reference")}
          variant="filled"
          value={reference.value}
          onChange={reference.onChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={locked}
          label={t("mission:cards:actors:contract:comment")}
          variant="filled"
          value={description.value}
          onChange={description.onChange}
          fullWidth
          multiline
          maxRows={5}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label={t("mission:cards:actors:contract:insurance")}
          control={<Checkbox className={classes.checkbox} size="small" checked={isInsuranceContract.value} onChange={isInsuranceContract.onChange} disabled={locked} />}
        />
      </Grid>

      <Grid item xs={12}>
        <Collapse in={isInsuranceContract.value} className={classes.collapse}>
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <TextField
                disabled={locked}
                label={t("mission:cards:actors:contract:insuranceCompany")}
                value={insurer.value}
                onChange={insurer.onChange}
                variant="filled"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth size="small" variant="filled" disabled={locked}>
                <InputLabel>{t("mission:cards:actors:contract:insuranceType")}</InputLabel>
                <Select onChange={insuranceType.onChange} value={insuranceType.value}>
                  {MissionEnumsLists.InsuranceType.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:insuranceType:${type}`)}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth size="small" variant="filled" disabled={locked}>
                <InputLabel>{t("mission:cards:actors:contract:insuranceState")}</InputLabel>
                <Select onChange={insuranceState.onChange} value={insuranceState.value}>
                  {MissionEnumsLists.InsuranceState.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:insuranceState:${type}`)}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </Collapse>
      </Grid>



    </SurfaceSection>
  );
}
