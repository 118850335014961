import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import moment from "moment/moment";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import EntityTable from "../../../common/tables/EntityTable";

export default function InvoiceJournalEntriesSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "exportNumber",
        type: "number",
        headerName: t("invoiceJournalEntries:fieldNumber"),
        flex: 0
      },
      {
        field: "orderNumber",
        type: "number",
        headerName: t("invoiceJournalEntries:fieldOrderNumber"),
        flex: 0
      },
      {
        field: "item.quantity",
        type: "number",
        valueGetter: (params) => Number(params.row.item?.quantity || "1"),
        valueFormatter: params => FixNumber(params.value),
        headerName: t("invoiceJournalEntries:fieldQuantity"),
        entityType: "decimal",
        flex: 0
      },
      {
        field: "item.discount",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.item?.discount) || 0,
        valueFormatter: (params) => `${params.value}%`,
        headerName: t("invoiceJournalEntries:fieldDiscount"),
        flex: 0
      },
      {
        field: "item.price.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.item?.price?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.item?.price?.value)} ${getCurrencyShorthand(t, params.row.item?.price?.currency)}`,
        headerName: t("invoiceJournalEntries:fieldPrice"),
        minWidth: 120,
      },
      {
        field: "item.code",
        valueGetter: (params) => params.row.item?.code || "-",
        headerName: t("invoiceJournalEntries:fieldProductCode"),
        flex: 0
      },
      {
        field: "item.name",
        valueGetter: (params) => params.row.item?.name || "-",
        headerName: t("invoiceJournalEntries:fieldProductName"),
        flex: 0
      },
      {
        field: "sender.name",
        valueGetter: (params) => params.row.sender?.name || "-",
        headerName: t("invoiceJournalEntries:fieldSender"),
        minWidth: 120,
        flex: 1,
      },
      {
        field: "item.productGroup.vatCode.code",
        valueGetter: (params) => params.row.item?.productGroup?.vatCode?.code|| "-",
        headerName: t("invoiceJournalEntries:fieldVatCode"),
        flex: 1,
      },
      {
        field: "item.productGroup.account",
        valueGetter: (params) => params.row.item?.productGroup?.account || "-",
        headerName: t("invoiceJournalEntries:fieldAccount"),
        flex: 1,
      },
      {
        field: "customer.name",
        valueGetter: (params) => params.row.customer?.name || "-",
        headerName: t("invoiceJournalEntries:fieldCustomer"),
        minWidth: 120,
        flex: 1,
      },
      {
        field: "customer.customerNumber",
        valueGetter: (params) => params.row.customer?.customerNumber || "-",
        headerName: t("invoiceJournalEntries:fieldCustomerNumber"),
        flex: 1,
      },
      {
        field: "customer.organizationNumber",
        valueGetter: (params) => params.row.customer?.organizationNumber || "-",
        headerName: t("invoiceJournalEntries:fieldOrganizationNumber"),
        flex: 1,
      },
      {
        field: "orderDate",
        type: "date",
        valueGetter: (params) => params.row.orderDate,
        valueFormatter: (params) => moment(params.value).format("YYYY.MM.DD"),
        headerName: t("invoiceJournalEntries:fieldOrderDate"),
        minWidth: 120,
        flex: 1,
      },
      {
        field: "customer.billing.address",
        valueGetter: (params) => params.row.customer?.billing?.address || "-",
        headerName: t("invoiceJournalEntries:fieldBillingAddress"),
        minWidth: 120,
        flex: 1,
      },
      {
        field: "customer.billing.area",
        valueGetter: (params) => params.row.customer?.billing?.area || "-",
        headerName: t("invoiceJournalEntries:fieldBillingCity"),
        minWidth: 120,
        flex: 1,
      },
      {
        field: "customer.billing.postNumber",
        valueGetter: (params) => params.row.customer?.billing?.postNumber || "-",
        headerName: t("invoiceJournalEntries:fieldBillingPostNumber"),
        flex: 1,
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.row.exportId}`, { relative: "path" });
  }, [navigate]);

  return (
    <EntityTable
      tableId="invoice_journals"
      type="InvoiceJournal"
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("invoiceJournalEntries:emptyStateTitle")}
      emptyStateDescription={t("invoiceJournalEntries:emptyStateDescription")}
      sortDescendingByDefault
      serverSidePaging
      xs={12}
    />
  );
}
