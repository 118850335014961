import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEntityById, Compare, useEntities, useEntityFunctions, useMutateState, Task, useAuth, StatusCode } from "@emberly/zenith-client";
import { useStation } from "../providers/StationProvider";
import { useOrdersWithMissions } from "./useOrdersWithMissions";
import { ActivityEnums, OrderEnums } from "../common/constants";
import { BuildMutations, GenerateInvoiceExportData } from "../common/invoicejournal";
import { validateExportSettings } from "../common/exportformats/helpers";
import { useMission } from "../providers/MissionProvider";
import { useOrder } from "../providers/OrderProvider";
import { MakeActivity } from "../common/activity";
import { convertInvoiceJournalsToFormat, downloadCSV } from "../common/exportformats";

const ORDERS_QUERY = {
  path: "payment.invoice.state",
  comparer: Compare.EQ,
  value: OrderEnums.InvoiceState.Queued,
  name: "ready_invoice_orders"
};

export function useInvoiceExport() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { orgId, priceUnit, logEvent } = useStation();

  const { orders, missions, relatedOrders, showLoading: ordersLoading } = useOrdersWithMissions(ORDERS_QUERY);
  const { entity: exportSettings, loading: loadingExportSettings } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: productGroups, loading: loadingProductGroups } = useEntities("ProductGroup");
  const { entities: vatCodes, loading: loadingVatCodes } = useEntities("VatCode");
  const { createEntity: createExportEntity } = useEntityFunctions("InvoiceExport");

  const mutateState = useMutateState();
  const [working, setWorking] = useState(false);
  const [exportAction, setExportAction] = useState(null);
  const [exportResult, setExportResult] = useState(null);
  const [dialogAction, setDialogAction] = useState(null);
  
  const exportAll = useCallback(async (selectionModel) => {
    try {
      const eTask = new Task();
      const dTask = new Task();

      setExportAction(eTask);
      setDialogAction(dTask);

      const conf = await eTask.wait();

      setWorking(true);

      const selectedOrders = orders.filter(t => !!selectionModel.find(s => s === t.id));
      const selectedMissions = missions.filter(t => !!selectedOrders.find(s => s.missionId === t.id));

      const { exportId, exportEntityData, exportEntityUpdate, orderUpdates, errorCode, journalItems } = GenerateInvoiceExportData(
        selectedOrders,
        relatedOrders,
        selectedMissions,
        exportSettings,
        productGroups,
        vatCodes,
        user,
        priceUnit,
        conf,
        t
      );

      if (!!errorCode) {
        return { success: false, exportId, errorCode };
      }

      // prepare export entity
      const exportEntity = await createExportEntity(exportEntityData);

      if (!exportEntity) {
        return { success: false, exportId, errorCode: 0 };
      }

      // map export number to mutations
      const mutations = BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates, conf);

      // transmit mutations
      const response = await mutateState(mutations);
      const success = response?.statusCode === StatusCode.Success;


      if (success) {
        logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.InvoiceJournalCreated, exportId));

        if (conf.exportType === "file") {
          const data = convertInvoiceJournalsToFormat(conf.format, journalItems, exportEntityUpdate.data, t);
          downloadCSV(data, `invoiceexport_${conf.friendlyName.toLowerCase()}_${exportEntity.number}.csv`);
        }
      }

      setExportResult(response);
      setWorking(false);

      const openResultPage = await dTask.wait();
      
      return { success, exportId, errorCode, openResultPage };

    } catch (err) {
        console.log(err);
        return { success: false, exportId: null };
      } finally {
        setExportAction(null);
        setDialogAction(null);
        setExportResult(null);
        setWorking(false);
      }
  }, [orders, mutateState, vatCodes, productGroups, t, exportSettings, user, priceUnit, relatedOrders, createExportEntity, missions, logEvent]);

  const loading = ordersLoading || loadingExportSettings || loadingProductGroups || loadingVatCodes;

  return {
    exportAll,
    working,
    orders,
    loading,
    setExportAction,
    exportAction,
    dialogAction,
    exportResult,
    hasExportSettings: validateExportSettings(exportSettings)
  };
}




export function useInvoiceOrderExport() { // TODO next session, apply this logic to the other export for multi select.
  const { t } = useTranslation();
  const { user } = useAuth();
  const { orgId, priceUnit, logEvent } = useStation();
  const { entity: exportSettings, loading: loadingExportSettings } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: productGroups, loading: loadingProductGroups } = useEntities("ProductGroup");
  const { entities: vatCodes, loading: loadingVatCodes } = useEntities("VatCode");
  const { createEntity: createExportEntity } = useEntityFunctions("InvoiceExport");

  const mutateState = useMutateState();
  const [working, setWorking] = useState(false);
  const [exportResult, setExportResult] = useState(null);
  const [exportAction, setExportAction] = useState(null);
  const [dialogAction, setDialogAction] = useState(null);

  const { mission, orders } = useMission();
  const { order } = useOrder();

  const loading = loadingExportSettings || loadingProductGroups || loadingVatCodes;

  const exportOrder = useCallback(async () => {
    try {
      const eTask = new Task();
      const dTask = new Task();
      
      setExportAction(eTask);
      setDialogAction(dTask);

      const conf = await eTask.wait();
      
      setWorking(true);

      const { exportId, exportEntityData, exportEntityUpdate, orderUpdates, errorCode, journalItems } = GenerateInvoiceExportData([order], orders, [mission], exportSettings, productGroups, vatCodes, user, priceUnit, conf, t);

      if (!!errorCode) {
        return { success: false, exportId, errorCode };
      }

      // prepare export entity
      const exportEntity = await createExportEntity(exportEntityData);

      if (!exportEntity) {
        return { success: false, exportId, errorCode: 0 };
      }

      // map export number to mutations
      const mutations = BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates, conf);

      // transmit mutations
      const response = await mutateState(mutations);

      const success = response?.statusCode === StatusCode.Success;

      if (success) {
        logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.InvoiceJournalCreated, exportId));

        if (conf.exportType === "file") {
          const data = convertInvoiceJournalsToFormat(conf.format, journalItems, exportEntityUpdate.data, t);
          downloadCSV(data, `invoiceexport_${conf.friendlyName.toLowerCase()}_${exportEntity.number}.csv`);
        }
      }

      setExportResult(response);
      setWorking(false);

      const openResultPage = await dTask.wait();
      
      return { success, exportId, errorCode, openResultPage };

    } catch (err) {
      console.log(err);
      return { success: false, exportId: null };
    } finally {
      setExportAction(null);
      setDialogAction(null);
      setExportResult(null);
      setWorking(false);
    }
  }, [order, orders, mutateState, vatCodes, productGroups, t, exportSettings, user, priceUnit, createExportEntity, mission, logEvent]);

  return {
    exportOrder,
    working,
    orders,
    loading,
    setExportAction,
    exportResult,
    exportAction,
    dialogAction,
    hasExportSettings: validateExportSettings(exportSettings),
  };
}
