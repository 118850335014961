import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button } from "@mui/material";
import ResponsivePage from "./ResponsivePage";
import { useDevice } from "../../../providers/DeviceProvider";
import { useScrollState } from "../../../hooks/useScrollState";
import { GlobalStyles } from "tss-react";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles()(
  (theme) => ({
    headerWidget: {
      width: "100%",
      height: "auto",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      background: theme.palette.background.default,
      transition: "box-shadow 0.25s",
    },
    headerWidgetScroll: {
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
    },
    footerWidget: {
      padding: theme.spacing(2),
      height: "auto",
      width: "100%",
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      background: theme.palette.background.default,
      transition: "box-shadow 0.25s",
      zIndex: 10,
    },
    footerWidgetScroll: {
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
    },
    hugeButton: {
      height: "56px"
    }
  })
);

const SnackbarOverride = {
  body: {
    ".notistack-SnackbarContainer": {
      bottom: "92px"
    }
  }
};

export default function ResponsiveSubPage(props) {
  const { title, caption, backButton, backPath, primaryAction, secondaryAction, onPrimaryAction, onSecondaryAction, secondaryActionComponent, secondaryActionIcon, xs, sm, lg, xl, xxl, menuItems, stateTag, stateTagColor, widget } = props;
  const { classes } = useStyles();
  const { slim } = useDevice();
  const buttonSizeXS = !!primaryAction && !!secondaryAction ? 6 : 12;
  const { scrollRef, scrollTop, scrollBottom } = useScrollState(slim);

  const showFooter = slim && (!!primaryAction || !!secondaryAction);


  return (
    <ResponsivePage
      xs={xs || 12}
      sm={sm || 12}
      lg={lg || 12}
      xl={xl || 12}
      xxl={xxl || 10}
      widget={widget}
      stateTag={stateTag}
      stateTagColor={stateTagColor}
      scrollRef={scrollRef}
      backButton={backButton}
      backPath={backPath}
      title={!slim ? title : undefined}
      caption={caption}
      primaryAction={!slim ? primaryAction : undefined}
      secondaryAction={!slim ? secondaryAction : undefined}
      onPrimaryAction={!slim ? onPrimaryAction : undefined}
      onSecondaryAction={!slim ? onSecondaryAction : undefined}
      secondaryActionIcon={secondaryActionIcon}
      secondaryActionComponent={secondaryActionComponent}
      menuItems={menuItems}
      headerWidget={
        slim ? (
          <div className={`${classes.headerWidget} ${!scrollTop ? classes.headerWidgetScroll : ""}`}>
            {
              typeof title === "string" ? (
                <Helmet>
                  <title>Assist - {title}</title>
                </Helmet>
              ) : null
            }
            <Typography variant="h4">{title}</Typography>
          </div>
        ) : undefined
      }
      footerWidget={
        showFooter ? (
          <div className={`${classes.footerWidget} ${!scrollBottom ? classes.footerWidgetScroll : ""}`}>

            <GlobalStyles
              styles={SnackbarOverride}
            />

            <Grid container spacing={1}>

              {!!primaryAction ? (
                <Grid item xs={buttonSizeXS}>
                  <Button size="large" variant="outlined" fullWidth onClick={onPrimaryAction} className={classes.hugeButton}>
                    {primaryAction}
                  </Button>
                </Grid>
              ) : null}

              {!!secondaryAction ? (
                <Grid item xs={buttonSizeXS}>
                  <Button size="large" variant="contained" fullWidth onClick={onSecondaryAction} component={secondaryActionComponent} startIcon={secondaryActionIcon} className={classes.hugeButton}>
                    {secondaryAction}
                  </Button>
                </Grid>
              ) : null}

            </Grid>
          </div>
        ) : undefined
      }
    >
      {props.children}
    </ResponsivePage>
  );
}
