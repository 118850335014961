
import { makeStyles } from "tss-react/mui";


export const useStyles = makeStyles()(
  (theme) => ({
    gridInnerContainer: {
      flexGrow: 1,
      height: "auto",
      "& .MuiDataGrid-virtualScrollerContent": {
        minHeight: "240px !important"
      }
    },
    table: {
      border: "none",
      minHeight: "100%",
      "& .MuiDataGrid-cell:focus": {
        outline: "none"
      },
      "& ::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "& ::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.25)",
      },
      "& .MuiDataGrid-cell--editable:hover, .MuiDataGrid-cell--editing": {
        position: "relative",
        outline: "none !important",
        background: `none !important`,
        boxShadow: "none !important",
        "&.MuiDataGrid-cell:before": {
          zIndex: -1,
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          borderRadius: "8px",
          background: theme.palette.common.grey.input,
          content: "''",
          display: "block",
          pointerEvents: "none"
        },
      },
    },
    tableRowHover: {
      "& .MuiDataGrid-row": {
        "&:hover": {
          background: theme.palette.action.hover
        }
      },
    },
  })
);