import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useMissionTask } from "../../../../providers/MissionTaskProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import { Grid, List } from "@mui/material";
import { useMission } from "../../../../providers/MissionProvider";
import { FormatLocation } from "../../../../common/maphelpers";
import SectionItem from "../../../common/inputs/SectionItem";

const useStyles = makeStyles()(
  (theme) => ({
    list: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500
      }
    }
  })
);

export default function MissionTaskSectionsCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { mission, missionTask } = useMissionTask();
  const { orders } = useMission();

  const fileCount = Object.keys(mission.files.uploads).length;
  const validServiceInfo = !!mission.serviceInfo?.serviceCenter;
  const location = missionTask?.route?.waypoints[missionTask.route.waypoints.length - 1];

  return (
    <ToggleSurface
      xs={12}
    >
      <Grid item container spacing={1}>
        <List className={classes.list}>

          <SectionItem
            to="files"
            primary={t("taskSectionsCard:documentation")}
            secondary={fileCount > 1 ? `${fileCount} ${t("taskSectionsCard:pluralFiles")}` : fileCount === 0 ? t("taskSectionsCard:noFiles") : t("taskSectionsCard:singleFile")}
          />

          <SectionItem
            to="assistance"
            primary={t("taskSectionsCard:assistance")}
            secondary={` ${!!location?.address ? FormatLocation(location) : t("taskSectionsCard:assistanceLocationNotAdded")}`}
          />

          <SectionItem
            to="payment"
            primary={t("taskSectionsCard:payment")}
            secondary={`${orders.length} ${t("taskSectionsCard:orders")}`}
          />

          <SectionItem
            to="serviceinfo"
            primary={t("taskSectionsCard:serviceInfo")}
            secondary={!mission.serviceInfo ? t("taskSectionsCard:serviceInfoNotAdded") : (validServiceInfo ?  `${mission.serviceInfo.serviceCenter || ""} - ${mission.serviceInfo.currentMileage || "0"}KM` : t("taskSectionsCard:serviceInfoEmpty"))}
          />

          <SectionItem
            to="storage"
            primary={t("taskSectionsCard:storage")}
            secondary={!mission.storageTask ? t("taskSectionsCard:storageNotAdded") : (!!mission.storageTask?.warehouse?.name ? `${t("taskSectionsCard:storageDescription")} - ${mission.storageTask?.warehouse?.name}` : t("taskSectionsCard:storageInfoMissing") )}
          />

        </List>
      </Grid>
    </ToggleSurface>
  );
}

