import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Task, useAuth } from "@emberly/zenith-client";
import axios from "axios";
import ConfirmDialog from "../components/common/inputs/ConfirmDialog";
import { useTranslation } from "react-i18next";
import UserEditDialog from "../components/pages/usermanagement/common/UserEditDialog";
import UserInviteDialog from "../components/pages/usermanagement/common/UserInviteDialog";
import { useNotification } from "./NotificationProvider";

export const UserManagementContext = createContext();
export const useUserManagement = () => useContext(UserManagementContext);

export const UserManagementProvider = (props) => {
  const { t } = useTranslation();

  const { snackbar } = useNotification();

  const { getAccessTokenSilently } = useAuth();

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  const [invitesLoading, setInvitesLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  // Init once
  useEffect(() => {

    // fetch all invites in organization
    const fetchInvites = async () => {
      try {
        const res = await axios.get("/api/usermanagement/invitations", {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setInvites(res.data);
        setInvitesLoading(false);

      } catch (err) {
        console.log(err);
      }
    };

    // Fetch all users in organization
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/api/usermanagement/users", {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setUsers(res.data);
        setUsersLoading(false);

      } catch (err) {
        console.log(err);
      }
    };

    const fetchRoles = async () => {
      try {
        const res = await axios.get("/api/usermanagement/roles", {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setRoles(res.data);

      } catch (err) {
        console.log(err);
      }
    };

    fetchInvites();
    fetchUsers();
    fetchRoles();

  }, [getAccessTokenSilently]);


  const [deleteAction, setDeleteAction] = useState(null);

  const deleteUser = useCallback(async (user) => {
    try {
      const action = new Task();
      setDeleteAction(action);

      await action.wait();


      setUsersLoading(true);

      const res = await axios.delete(`/api/usermanagement/users/${user.userId}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      });

      if (res.status === 200) {
        setUsers(users.filter(t => t.userId !== user.userId));
        snackbar(`${t("userManagement:actions:userRemoved")} ${user.email}`);
      }

    } catch (err) {
      console.log(err);
    } finally {
      setDeleteAction(null);
      setUsersLoading(false);
    }

  }, [users, snackbar, t, getAccessTokenSilently]);


  const [editAction, setEditAction] = useState(null);
  const [editingUser, setEditingUser] = useState(null);

  const editUser = useCallback(async (user) => {
    try {
      const action = new Task();
      setEditingUser(user);
      setEditAction(action);

      const data = await action.wait();

      setUsersLoading(true);

      const res = await axios.patch("/api/usermanagement/users", data, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      });

      if (res.status === 200) {
        const index = users.findIndex(t => t.userId === user.userId);

        if (index !== -1) {
          users[index] = { ...user, name: data.name || user.name, roles: data.roles?.map(r => roles.find(t => t.id === r)) || user.roles };
          setUsers([...users]);
          snackbar(`${t("userManagement:actions:userEdited")} ${user.email}`);
        }
      }


    } catch (err) {
      console.log(err);
    } finally {
      setUsersLoading(false);
      setEditingUser(null);
      setEditAction(null);
    }

  }, [getAccessTokenSilently, roles, users, snackbar, t]);

  const [inviteAction, setInviteAction] = useState(null);

  const inviteUser = useCallback(async () => {

    try {
      const action = new Task();
      setInviteAction(action);

      const data = await action.wait();

      setInvitesLoading(true);

      const res = await axios.post("/api/usermanagement/invitations", data, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      });

      if (res.status === 200) {
        const invite = res.data;
        setInvites([invite, ...invites]);
        snackbar(`${t("userManagement:actions:inviteSent")} ${data.inviteeEmail}`);
      }

    } catch (err) {
      console.log(err);
    } finally {
      setInvitesLoading(false);
      setInviteAction(null);
    }

  }, [getAccessTokenSilently, invites, snackbar, t]);


  const [deleteInviteAction, setDeleteInviteAction] = useState(null);


  const deleteInvite = useCallback(async (invite) => {
    try {
      const action = new Task();
      setDeleteInviteAction(action);

      await action.wait();

      setInvitesLoading(true);

      const res = await axios.delete(`/api/usermanagement/invitations/${invite.id}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      });

      if (res.status === 200) {
        setInvites(invites.filter(t => t.id !== invite.id));
        snackbar(`${t("userManagement:actions:inviteRemoved")} ${invite.email}`);
      }

    } catch (err) {
      console.log(err);
    } finally {

      setInvitesLoading(false);
      setDeleteInviteAction(null);
    }

  }, [invites, snackbar, t, getAccessTokenSilently]);


  const resendInvite = useCallback(async (invite) => {
    try {

      setInvitesLoading(true);

      const res = await axios.put(`/api/usermanagement/invitations`, invite, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      });

      if (res.status === 200) {
        setInvites([res.data, ...invites.filter(t => t.id !== invite.id)]);
        snackbar(`${t("userManagement:actions:inviteResent")} ${invite.email}`);
      }

    } catch (err) {
      console.log(err);
    } finally {
      setInvitesLoading(false);
    }

  }, [invites, snackbar, t, getAccessTokenSilently]);


  return (
    <UserManagementContext.Provider
      value={{
        users,
        usersLoading,
        invites,
        invitesLoading,
        deleteUser,
        editUser,
        roles,
        inviteUser,
        deleteInvite,
        resendInvite
      }}
    >
      {props.children}
      {
        !!deleteAction ? (
          <ConfirmDialog
            task={deleteAction}
            color="error"
            title={t("userManagement:deleteDialog:title")}
            description={t("userManagement:deleteDialog:description")}
            confirmText={t("userManagement:deleteDialog:confirmText")}
          />
        ) : null
      }
      {
        !!editAction ? (
          <UserEditDialog
            task={editAction}
            user={editingUser}
          />
        ) : null
      }
      {
        !!inviteAction ? (
          <UserInviteDialog
            task={inviteAction}
          />
        ) : null
      }
      {
        !!deleteInviteAction ? (
          <ConfirmDialog
            task={deleteInviteAction}
            color="error"
            title={t("userManagement:deleteInviteDialog:title")}
            description={t("userManagement:deleteInviteDialog:description")}
            confirmText={t("userManagement:deleteInviteDialog:confirmText")}
          />
        ) : null
      }
    </UserManagementContext.Provider>
  );
};

