import Route from "./Route";
import { Location } from "@emberly/zenith-client";


export default class RouteCollection {

  constructor(stationLocation = null) {
    this._stationLocation = stationLocation;
    this._map = new Map();
  }

  setStationLocation(stationLocation) {
    this._stationLocation = stationLocation;
    this._map.forEach((route, key) => {
      if (key.endsWith("/station")) {
        route.setStationLocation(stationLocation);
      }
    });
  }

  getRoute(path, startLocation = null) {
    const key = `${path}/${startLocation?.id || "station"}`;

    if (this._map.has(key)) {
      return this._map.get(key);
    }

    const route = new Route([new Location()], !!startLocation ? new Location({ ...startLocation.location, title: startLocation.name }) : this._stationLocation);
    this._map.set(key, route);

    return route;
  }

  destroy() {
    this._map.forEach(r => {
      r.removeAllListeners();
    });

    this._map.clear();
  }

}