import { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MissionEnumsLists } from "../../../common/constants";
import EntityViewTable from "../../common/tables/EntityViewTable";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useStation } from "../../../providers/StationProvider";
import { Chip, Grid } from "@mui/material";
import { Location } from "@emberly/zenith-client";
import { FullTimeView } from "../../common/core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    grid: {
      maxWidth: "1600px"
    },
    driverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px"
    },
    noDriverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px",
      color: theme.palette.text.secondary
    }
  })
);

export default function AllTasksPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createMissionDraft } = useStation();

  const onRowClick = useCallback((params) => {
    navigate(`./${params.row.missionId}/${params.row.number}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "missionNumber",
        type: "number",
        headerName: t("common:tables:missionNumber"),
        flex: 0,
      },
      {
        field: "number",
        type: "number",
        headerName: t("tasksArchive:table:number"),
        flex: 0,
      },
      {
        field: "execution.history.created.created",
        headerName: t("common:tables:created"),
        type: "dateTime",
        valueGetter: (params) => params.row.execution?.history.created?.created,
        renderCell: (params) => <FullTimeView time={params.value} />,
        flex: 0.5,
        minWidth: 120,
      },
      {
        field: "created",
        headerName: t("tasksArchive:table:missionCreated"),
        type: "dateTime",
        renderCell: (params) => <FullTimeView time={params.value} />,
        flex: 0.5,
        minWidth: 120,
      },
      {
        field: "missionDetails.type",
        headerName: t("common:tables:type"),
        flex: 0.5,
        minWidth: 100,
        type: "singleSelect",
        valueGetter: (params) => params.row.missionDetails.type,
        valueFormatter: (params) => t(`mission:enums:type:${MissionEnumsLists.Type[params.value]}`),
        valueOptions: MissionEnumsLists.Type.map((_, value) => ({
          value,
          label: t(`mission:enums:type:${MissionEnumsLists.Type[value]}`)
        }))
      },
      {
        field: "missionDetails.cause.name",
        headerName: t("common:tables:cause"),
        valueGetter: params => params.row.missionDetails?.cause?.name || "-",
        flex: 0.5,
        minWidth: 100,
      },
      {
        field: "missionDetails.tags.name",
        headerName: t("common:tables:tags"),
        valueGetter: params => params.row.missionDetails?.tags?.map(t => t.name).join() || "",
        flex: 0.5,
        minWidth: 100,
        renderCell: params => (
          <Grid container spacing={0.5}>
            {params.row.missionDetails?.tags?.map((tag, key) => <Grid item key={key}><Chip label={tag.name} size="small" /></Grid>)}
          </Grid>
        )
      },
      {
        field: "execution.driver.name",
        valueGetter: (params) => params.row?.execution?.driver?.name,
        headerName: t("tasksArchive:table:driver"),
        flex: 0.5,
        minWidth: 120
      },
      {
        field: "missionDetails.description",
        headerName: t("common:tables:details"),
        valueGetter: params => params.row.missionDetails.description || "-",
        valueFormatter: params => params.value.length > 40 ? params.value.substring(0, 38) + "..." : params.value,
        flex: 1,
        minWidth: 140
      },
      {
        field: "missionTarget.carDetails.make||missionTarget.boatDetails.make",
        headerName: t("common:tables:make"),
        valueGetter: (params) => {
          const type = params.row.missionTarget.type;
          const details = type === 1 ? params.row.missionTarget.carDetails : (type === 2 ? params.row.missionTarget.boatDetails : null);
          return details?.make || "";
        },
        minWidth: 80,
        flex: 0.75
      },
      {
        field: "missionTarget.carDetails.model||missionTarget.boatDetails.model",
        headerName: t("common:tables:model"),
        valueGetter: (params) => {
          const type = params.row.missionTarget.type;
          const details = type === 1 ? params.row.missionTarget.carDetails : (type === 2 ? params.row.missionTarget.boatDetails : null);
          return details?.model || "-";
        },
        minWidth: 100,
        flex: 0.5
      },
      {
        field: "missionTarget.carDetails.registration||missionTarget.boatDetails.registration",
        headerName: t("common:tables:registration"),
        valueGetter: params => {
          const type = params.row.missionTarget.type;
          const details = type === 1 ? params.row.missionTarget.carDetails : (type === 2 ? params.row.missionTarget.boatDetails : null);
          return details?.registration || "-"
        },
        minWidth: 100,
        flex: 0.5
      },
      {
        field: "route.waypoints.0.place",
        headerName: t("common:tables:place"),
        valueGetter: params => params.row.route.waypoints[0]?.place || "-",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "route.waypoints.0.title||route.waypoints.0.address",
        headerName: t("common:tables:address"),
        valueGetter: params => Location.ShortAddress(params.row.route.waypoints[0]?.title, params.row.route.waypoints[0]?.address),
        minWidth: 100,
        flex: 1
      },
      {
        field: "description",
        headerName: t("common:tables:locationDescription"),
        valueFormatter: (params) => params.value.length > 40 ? `${params.value.substring(0, 39)} ...` : params.value,
        minWidth: 100,
        flex: 1
      },
      {
        field: "execution.vehicle.name",
        headerName: t("common:tables:serviceVehicle"),
        valueGetter: (params) => params.row?.execution?.vehicle?.name || "-",
        minWidth: 100,
        flex: 1
      },  
    ]
  }, [t, classes]);

  return (
    <ResponsivePage
      title={t("tasksArchive:title")}
      secondaryAction={t("tasksArchive:createMission")}
      onSecondaryAction={createMissionDraft}
    >
      <EntityViewTable
        tableId="all_tasks"
        type="MissionTaskView"
        columns={columns}
        onRowClick={onRowClick}
        emptyStateTitle={t("tasksArchive:emptyStateTitle")}
        emptyStateDescription={t("tasksArchive:emptyStateDescription")}
        sortDescendingByDefault
        serverSidePaging
        xs={12}
      />
    </ResponsivePage>
  );
}