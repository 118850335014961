import { Grid } from "@mui/material";
import { ToggleSurface } from "../../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import DetailsSection from "./DetailsSection";
import TargetSection from "./TargetSection";
import { useMission } from "../../../../../providers/MissionProvider";

export default function DetailsCard() {
  const { t } = useTranslation();
  const { locked } = useMission();

  return (
    <ToggleSurface title={t("mission:cards:details:title")} md={12} lg={6}>
      <Grid item container spacing={3}>
        <DetailsSection locked={locked} />
        <TargetSection locked={locked} />
      </Grid>
    </ToggleSurface>
  );
}