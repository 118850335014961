import { makeStyles } from "tss-react/mui";
import { Grid, Typography, TableRow, TableCell, Chip, Avatar, IconButton, MenuItem, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useUserManagement } from "../../../../providers/UserManagementProvider";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { useAuth } from "@emberly/zenith-client";

const useStyles = makeStyles()(
  (theme) => ({
    buttonCell: {
      textAlign: "right"
    },
    grid: {
      flexWrap: "nowrap"
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "30vw",
    }
  })
);

export default function UserItem(props) {
  const { user, slim } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { deleteUser, editUser } = useUserManagement();
  const { user: self } = useAuth();
  const isSelf = self?.sub === user?.userId;

  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onDeleteUser = () => {
    handleMenuClose();
    deleteUser(user);
  };

  const onEditUser = () => {
    handleMenuClose();
    editUser(user);
  };

  return (
    <>
      <TableRow onClick={slim ? onOpenMenu : undefined}>
        <TableCell>
          <Grid container direction="row" alignItems="center" spacing={2} className={classes.grid}>
            <Grid item>
              <Avatar src={user.picture} alt={user.name} />
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" className={classes.text}>
                {user.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.text}>
                {user.email}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            {user.roles?.map((r, key) => (
              <Grid item key={key}>
                <Chip label={t(`roles:${r.name}`)} size="small" />
              </Grid>
            ))}
          </Grid>
        </TableCell>
        {
          !slim ? (
            <TableCell className={classes.buttonCell}>
              <IconButton size="small" onClick={onOpenMenu}>
                <MoreIcon size="small" />
              </IconButton>
            </TableCell>
          ) : null
        }
      </TableRow>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={onEditUser}>
          {t("userManagement:users:edit")}
        </MenuItem>
        <MenuItem onClick={onDeleteUser} color="error" disabled={isSelf}>
          <Typography color="error">
            {t("userManagement:users:delete")}
          </Typography>
        </MenuItem>
      </Menu>

    </>
  );
}