import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import OverrideRouteDetailsDialog from "../../pages/mission/cards/tasks/common/OverrideRouteDetailsDialog";
import { useStation } from "../../../providers/StationProvider";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "absolute",
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      background: theme.palette.background.grey,
      opacity: 0,
      transition: "opacity 0.25s ease",
      height: "auto",
      width: "300px",
      maxWidth: "calc(100vw - 64px)",
      zIndex: 100
    },
    button: {
      marginRight: "-8px"
    },
    animateIn: {
      opacity: 1
    },
    table: {
      width: "100%",
      tableLayout: "fixed",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
        whiteSpace: "nowrap"
      },
      "& td:nth-of-type(1)": {
        width: "140px"
      },
      "& td:last-child": {
        textAlign: "right"
      }
    },
    inRouteIcon: {
      display: "inline-block",
      position: "relative",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      background: theme.palette.common.inRoute,
      marginBottom: "-2px"
    },
    toRouteIcon: {
      display: "inline-block",
      position: "relative",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      background: theme.palette.common.toRoute,
      marginBottom: "-2px"
    },
    invisibleIcon: {
      display: "inline-block",
      position: "relative",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      marginBottom: "-2px"
    },
    overridden: {
      textDecoration: "line-through"
    }
  })
);

const sanitizeNumber = (n) => isNaN(n) || n === -1 ? 0 : n;
const pickLastValid = (a, b) => typeof b === "number" ? b : a;

export default function RouteDetailsCard(props) {
  const { path, locked } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { value: routeData } = useEntityField(`${path}.route`, Defaults.Object);
  const { station } = useStation();

  const includeDistanceFromStation = station?.includeDistanceFromStation || false;

  const originalDistanceInRouteKM = sanitizeNumber(routeData?.distanceInRouteKM);
  const hasOverrideDistanceInRouteKM = typeof routeData?.overriddenDistanceInRouteKM === "number";

  const originalDistanceToRouteKM = sanitizeNumber(routeData?.distanceToRouteKM);
  const hasOverrideDistanceToRouteKM = typeof routeData?.overriddenDistanceToRouteKM === "number";

  const distanceInRouteKM = sanitizeNumber(pickLastValid(routeData?.distanceInRouteKM, routeData?.overriddenDistanceInRouteKM));
  const distanceToRouteKM = sanitizeNumber(pickLastValid(routeData?.distanceToRouteKM, routeData?.overriddenDistanceToRouteKM));

  const [dialog, setDialog] = useState(false);

  const openDialog = useCallback(() => {
    setDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialog(false);
  }, []);

  return (
    <div className={`${classes.root} ${props.in ? classes.animateIn : ""}`}>
      <Grid container alignItems="center">

        <Grid item xs>
          <Typography variant="subtitle1">{t("mission:cards:tasks:map:detailsCardTitle")}</Typography>
        </Grid>

        <Grid item>
          <Button className={classes.button} color="secondary" onClick={openDialog} disabled={locked}>{t("mission:cards:tasks:map:detailsCardEdit")}</Button>
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="body1"><span className={classes.inRouteIcon}></span> {t("mission:cards:tasks:map:distanceInRoute")}</Typography>
                </TableCell>
                <TableCell>
                  {hasOverrideDistanceInRouteKM ? <Typography variant="body1" color="textSecondary" className={classes.overridden}>{originalDistanceInRouteKM} km</Typography> : null}
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{distanceInRouteKM} km</Typography>
                </TableCell>
              </TableRow>

              {
                includeDistanceFromStation ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography color="textSecondary" variant="body1"><span className={classes.toRouteIcon}></span> {t("mission:cards:tasks:map:distanceToRoute")}</Typography>
                      </TableCell>

                      <TableCell>
                        {hasOverrideDistanceToRouteKM ? <Typography variant="body1" color="textSecondary" className={classes.overridden}>{originalDistanceToRouteKM} km</Typography> : null}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{distanceToRouteKM} km</Typography>
                      </TableCell>
                    </TableRow>


                    <TableRow>
                      <TableCell>
                        <Typography color="textSecondary" variant="body1"><span className={classes.invisibleIcon}></span> {t("mission:cards:tasks:map:distanceTotal")}</Typography>
                      </TableCell>
                      <TableCell>
                        {hasOverrideDistanceToRouteKM || hasOverrideDistanceInRouteKM ? <Typography variant="body1" color="textSecondary" className={classes.overridden}>{originalDistanceInRouteKM + originalDistanceToRouteKM} km</Typography> : null}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{distanceInRouteKM + distanceToRouteKM} km</Typography>
                      </TableCell>
                    </TableRow>
                  </>
                ) : null
              }
            </TableBody>
          </Table>
        </Grid>

      </Grid>

      {!!dialog ? (
        <OverrideRouteDetailsDialog
          includeDistanceFromStation={includeDistanceFromStation}
          open
          onClose={closeDialog}
          path={path}
        />
      ) : null}

    </div>
  );
}
