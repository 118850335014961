import { makeStyles } from "tss-react/mui";
import { Grid, Button, Typography } from "@mui/material";
import { ToggleSurface } from "../../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../../../providers/MissionProvider";
import ServiceInfoSection from "../../../../common/sections/ServiceInfoSection";

const useStyles = makeStyles()(
  (theme) => ({
    titleContainer: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
  })
);

export default function ServiceCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showServiceInfo, removeServiceInfo, locked } = useMission();

  return (
    <ToggleSurface
      title={
        <Grid container direction="row" className={classes.titleContainer}>
          <Grid item xs>
            <Typography variant="h5">{t("mission:cards:service:title")}</Typography>
          </Grid>
          <Button onClick={removeServiceInfo} disabled={locked}>{t("mission:cards:service:remove")}</Button>
        </Grid>
      }
      md={12} lg={6} collapse={!showServiceInfo}
    >
      <ServiceInfoSection />
    </ToggleSurface>
  );
}
