import { useEffect, useState, useRef } from "react";
import { makeStyles } from "tss-react/mui";
import mapboxgl from "mapbox-gl";
import { useTheme } from "@mui/material";
import Route from "../../../../common/Route";
import { useStation } from "../../../../providers/StationProvider";
import Surface from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { GeoJsonPoint, GetBounds, GroupByDistance } from "../../../../common/maphelpers";
import { getMissionCauseDescription, getMissionJobDescription, getMissionTargetDescription } from "../../../../providers/MissionProvider";
import { AssumeTaskStateEnum, GetTaskDriverState, GetTaskDriverStateColor, GetTaskDriverStateIcon, PaletteColorToHexColor } from "../../../../common/mission";
import { MapIcons, TaskEnums } from "../../../../common/constants";
import { useNavigate } from "react-router-dom";
import { useEntities } from "@emberly/zenith-client";
import LiveLocationUpdater from "../../../../common/LiveLocationUpdater";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      width: "100%",
      minHeight: "480px",
      height: "65vh",
      "& .map": {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: theme.spacing(1)
      },
      "& .mapboxgl-popup": {
        maxWidth: "320px",
        "& .mapboxgl-popup-content": {
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1),
          maxWidth: "320px"
        },
        "& strong": {
          ...theme.typography.subtitle2
        },
        "& table": {
          verticalAlign: "top",
          "& tr": {
            verticalAlign: "top",
          },
          "& td": {
            ...theme.typography.body2
          },
          "& td:first-of-type": {
            color: theme.palette.text.secondary
          },
          "& td:last-of-type": {
            color: theme.palette.text.primary,
            paddingLeft: theme.spacing(0.5)
          }
        },

        "& td, strong": {
          fontFamily: "\"IBM Plex Sans\", Arial",
        }
      }
    },
  })
);


export default function DashboardMapCard(props) {
  const { missions } = props;
  const { classes } = useStyles();
  const containerRef = useRef(null);
  const theme = useTheme()
  const { station } = useStation();
  const [map, setMap] = useState(null);
  const [liveLocationUpdater, setLiveLocationUpdater] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const zoomSet = useRef(false);

  const { entities: vehicles } = useEntities("ServiceVehicle");

  useEffect(() => {
    try {


      const component = containerRef.current;
      if (!component) return;

      component.innerHTML = "";

      const m = new mapboxgl.Map({
        container: component, // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: station?.location?.coordinates, // starting position [lng, lat]
        zoom: 10.5, // starting zoom
        attributionControl: false
      });

      const live = new LiveLocationUpdater(m);
      zoomSet.current = false;

      m.loadImage(
        "/assets/icons/map/home.png",
        (error, image) => {
          m?.addImage(MapIcons.Pins.Home, image, { sdf: true });
        }
      );

      m.loadImage(
        "/assets/icons/map/issue.png",
        (error, image) => {
          m?.addImage(MapIcons.Pins.Issue, image, { sdf: true });
        }
      );

      m.loadImage(
        "/assets/icons/map/resolved.png",
        (error, image) => {
          m?.addImage(MapIcons.Pins.Resolved, image, { sdf: true });
        }
      );

      m.loadImage(
        "/assets/icons/map/moving.png",
        (error, image) => {
          m?.addImage(MapIcons.Pins.Moving, image, { sdf: true });
        }
      );

      m.loadImage(
        "/assets/icons/map/person.png",
        (error, image) => {
          m?.addImage(MapIcons.Pins.Person, image, { sdf: true });
        }
      );

      m.loadImage(
        "/assets/icons/map/nav-arrow.png",
        (error, image) => {
          m?.addImage(MapIcons.Navigation.Arrow, image, { sdf: true });
        }
      );

      m.loadImage(
        "/assets/icons/map/car.png",
        (error, image) => {
          m?.addImage(MapIcons.Navigation.Car, image, { sdf: true });
        }
      );

      const ro = new ResizeObserver(() => {
        if (m || m.forceKilled !== true) {
          setTimeout(() => {
            try {
              m?.resize();
            } catch { }
          }, 0);
        }
      });

      ro.observe(component);

      m.on("load", () => {

        m.addSource("mission-locations", {
          type: "geojson",
          data: Route.EmptyGeoJson(),
          cluster: true,
          clusterRadius: 35
        });


        m.addLayer({
          type: "symbol",
          source: "mission-locations",
          id: "mission-layer-close",
          filter: ['!', ['has', 'point_count']],
          layout: {
            "icon-size": 0.65,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-allow-overlap": true,
            "text-ignore-placement": true,
            "icon-image": ["get", "icon"],
            "text-field": ["get", "title"],
            "text-font": [
              "Open Sans Semibold",
              "Arial Unicode MS Bold"
            ],
            "text-size": 12,
            "text-offset": [0, -1.25],
            "text-anchor": "bottom"
          },
          paint: {
            "icon-color": ["get", "color"],
            "text-halo-color": "#fff",
            "text-halo-width": 2
          }
        });



        m.addLayer({
          id: 'clusters',
          type: 'circle',
          source: "mission-locations",
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': [
              'step',
              ['get', 'point_count'],
              theme.palette.info.light,
              4,
              theme.palette.warning.light,
              9,
              theme.palette.error.light,
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40
            ]
          }
        });


        m.addLayer({
          type: "symbol",
          source: "mission-locations",
          id: "mission-layer-far",
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          },
        });


        // hover effect
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        const onClick = (e) => {
          const props = e.features[0].properties;
          if (!!props.missionId && !!props.taskNumber) {
            navigate(`/activemissions/${props.missionId}/${props.taskNumber}`, { relative: "path" });
          }
        };

        const onMouseEnter = (e) => {
          m.getCanvas().style.cursor = "pointer";

          const { popupContent } = e.features[0].properties;
          if (!popupContent) return;

          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();

          // Ensure that if the m is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(popupContent).addTo(m);
        };

        const onMouseLeave = () => {
          m.getCanvas().style.cursor = "";
          popup.remove();
        };

        m.on("click", "mission-layer-close", onClick);
        m.on("mouseenter", "mission-layer-close", onMouseEnter);
        m.on("mouseleave", "mission-layer-close", onMouseLeave);

        setLiveLocationUpdater(live.setup());
        setMap(m);
      });

      return () => {
        m.forceKilled = true;
        setMap(null);
        live.destroy();
        m?.remove();
        ro?.disconnect();
      };
    } catch (err) {
      console.log(err);
    }
  }, [theme, station, containerRef, navigate]);

  useEffect(() => {
    if (!!liveLocationUpdater && !!vehicles) {
      liveLocationUpdater.update(vehicles);
    }
  }, [vehicles, liveLocationUpdater]);

  useEffect(() => {
    if (!map || map.forceKilled) return;

    let missionLocations = [];

    if (!!station && station.location?.coordinates?.length >= 2) {
      missionLocations.push(GeoJsonPoint(
        station.location.coordinates,
        {
          title: station.displayName,
          color: theme.palette.success.main,
          icon: MapIcons.Pins.Home
        }
      ));
    }

    const groups = GroupByDistance(
      missions.flatMap(m => m.salvageTasks.map(t => ({ ...t, missionId: m.id }))).filter(t => !!t?.route?.waypoints?.length),
      5,
      task => task.route.waypoints[0]?.coordinates
    );

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      for (let j = 0; j < group.length; j++) {
        const task = group[j];
        const mission = missions.find(m => m.id === task.missionId);

        const missionDescription = getMissionJobDescription(t, mission);
        const missionCauseDescription = getMissionCauseDescription(t, mission);
        const targetDescription = getMissionTargetDescription(mission);

        const location = task.route.waypoints[0];
        const state = AssumeTaskStateEnum(task);
        const driverAssignedState = task.execution.driverAssignedState;
        const color = GetTaskDriverStateColor(driverAssignedState, state, false);

        if (state < TaskEnums.State.Completed && location?.coordinates?.length >= 2) {

          const lon = location.coordinates[0];
          const lat = location.coordinates[1];

          missionLocations.push(GeoJsonPoint(
            [
              lon,
              lat + j * 0.00002,
            ],
            {
              title: `#${mission.number}.${task.number} ${missionCauseDescription}`,
              color: PaletteColorToHexColor(theme, color),
              icon: GetTaskDriverStateIcon(driverAssignedState, state),
              missionId: mission.id,
              taskNumber: task.number,
              popupContent: `<strong>#${mission.number}.${task.number} ${missionDescription}</strong><table><tr><td>${t("home:cards:map:target")}</td><td>${targetDescription}</td></tr><tr><td>${t("home:cards:map:driver")}</td><td>${task.execution?.driver?.name || "-"}</td></tr><tr><td>${t("home:cards:map:state")}</td><td>${GetTaskDriverState(driverAssignedState, state, t)}</td></tr></table>`
            }
          ));
        }
      }
    }

    map?.getSource("mission-locations")?.setData(Route.GeoJson(missionLocations));

    if (missionLocations.length !== 0 && !zoomSet.current) {
      zoomSet.current = true;
      const bounds = GetBounds(missionLocations);

      if (!!bounds) {
        map?.fitBounds(bounds, { padding: 64, maxZoom: 14 });
      }
    }

  }, [missions, map, theme, station, t]);


  return (
    <Surface xs={12} title={t("home:cards:map:title")}>
      <div className={classes.root}>
        <div ref={containerRef} className="map"></div>
      </div>
    </Surface>
  );
}