import { useCallback, useMemo, useState } from "react";
import { useEntities, useMutateState, useAuth, Compare, makeId, distinct, StatusCode } from "@emberly/zenith-client";
import { ActivityEnums, OrderEnums } from "../../../../common/constants";
import { GetOrderState, SumOrdersByPaymentMethod } from "../../../../common/orders";
import { useStation } from "../../../../providers/StationProvider";
import { MakeActivity } from "../../../../common/activity";


export function useUnsettledOrders(settlementId = null) {
  const { user } = useAuth();
  const [working, setWorking] = useState(false);
  const [recentlySettled, setRecentlySettled] = useState(false);
  const mutateState = useMutateState();
  const { priceUnit, logEvent } = useStation();

  const { entities: ordersUnfiltered, loading: loadingOrders } = useEntities("Order", useMemo(
    () => !!settlementId ? ({
        path: "payment.terminal.settlementId",
        value: settlementId,
        comparer: Compare.EQ,
    }) : (
      {
        name: `settlement_orders_${user.sub}`,
        and: [
          {
            path: "payment.sender.id",
            value: user.sub,
            comparer: Compare.EQ,
          },
          {
            path: "payment.terminal.settled",
            value: false,
            comparer: Compare.EQ,
          },
          {
            path: "payment.terminal.confirmed",
            value: true,
            comparer: Compare.EQ,
          }
        ]
      }),
    [user, settlementId])
  );

  const orders = useMemo(() => {
    return ordersUnfiltered.filter(t => GetOrderState(t) === OrderEnums.OrderState.PaymentConfirmed);
  }, [ordersUnfiltered]);

  const { entities: relatedOrders, loading: loadingRelatedOrders } = useEntities("Order", useMemo(
    () => ({
      name: `settlement_related_orders_${user.sub}`,
      path: "missionId",
      comparer: Compare.IN,
      value: distinct(orders, t => t.missionId),
    }),
    [orders, user]
  ), orders.length !== 0);

  const settleOrders = useCallback(async () => {
    try {
      setWorking(true);

      const mobilePaymentSum = SumOrdersByPaymentMethod(orders, relatedOrders, OrderEnums.PaymentMethod.MobilePayment);
      const cashSum = SumOrdersByPaymentMethod(orders, relatedOrders, OrderEnums.PaymentMethod.Cash);
      const cardSum = SumOrdersByPaymentMethod(orders, relatedOrders, OrderEnums.PaymentMethod.Card);
      const totalSum = mobilePaymentSum.plus(cardSum).plus(cashSum);
      
      const settlementId = makeId();
      
      const response = await mutateState([
        {
          type: "CashSettlement",
          create: [
            {
              id: settlementId,
              sender: {
                id: user.sub,
                name: user.name
              },
              cashSum: {
                value: cashSum.toString(),
                currency: priceUnit
              },
              cardSum: {
                value: cardSum.toString(),
                currency: priceUnit
              },
              mobilePaymentSum: {
                value: mobilePaymentSum.toString(),
                currency: priceUnit
              },
              totalSum: {
                value: totalSum.toString(),
                currency: priceUnit
              }
            }
          ]
        },
        {
          type: "Order",
          update: orders.map(order => ({
            data: {
              id: order.id,
              payment: {
                terminal: {
                  settled: true,
                  settlementId
                }
              }
            },
            fields: ["payment.terminal.settled", "payment.terminal.settlementId"]
          }))
        }
      ]);

      const success = response?.statusCode === StatusCode.Success;
      
      setRecentlySettled(success);

      if (success) {
        logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.OrdersSettled, settlementId));
      }

      return success;

    } catch (err) {
      console.log(err);
      setRecentlySettled(false);
    } finally {
      setWorking(false);
    }

  }, [orders, relatedOrders, mutateState, user, priceUnit, logEvent]);

  const loading = loadingOrders || (loadingRelatedOrders && orders.length !== 0)

  return {
    orders,
    relatedOrders,
    loading,
    settleOrders,
    working,
    recentlySettled,
    canSettle: !loading && orders.length !== 0
  };
}
