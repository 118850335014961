import { Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

export default function SectionItem(props) {
  const { to, primary, secondary, disabled } = props;

  return (
    <NavLink to={disabled ? undefined : to}>
      <ListItemButton disabled={disabled}>
        <ListItemText
          primary={primary}
          secondary={secondary}
        />
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
      </ListItemButton>
      <Divider />
    </NavLink>
  );
}
