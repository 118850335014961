import { Grid, Typography, Box, CircularProgress, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SystemSecurityUpdate as SystemSecurityUpdateIcon } from "@mui/icons-material";
import { useState } from "react";
import { unregister } from "../../../serviceWorkerRegistration";

export default function UpdateRequiredPage() {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  const onUpdate = async () => {
    setUpdating(true);

    try {
      unregister();

      if (!!caches) {
        caches.keys().then((names) => {
          for (let name of names) caches.delete(name);
        });
      }

    } catch (err) {
      console.log("error updating service worker", err);
    }

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  return (
    <Box sx={{ height: "100%", width: "100%", position: "absolute" }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
        <Grid item container spacing={1} direction="column" alignItems="center">

          <Grid item>
            {
              updating ? (
                <CircularProgress sx={{ width: "64px", height: "64px" }} color="info" size="large" variant="indeterminate" />
              ) : (
                <SystemSecurityUpdateIcon sx={{ width: "64px", height: "64px" }} />
              )
            }
          </Grid>

          <Grid item >
            <Typography variant="h6" textAlign="center">{updating ? t("updateRequired:updatingTitle") : t("updateRequired:title")}</Typography>
            <Typography variant="body1" textAlign="center" sx={{ width: "360px", maxWidth: "calc(100vw - 32px)" }} color="textSecondary">{updating ? t("updateRequired:updatingDescription") : t("updateRequired:description")}</Typography>
          </Grid>

          <Grid item>
            <Button size="large" variant="contained" sx={{ marginBottom: "78px", marginTop: "8px" }} color="info" disabled={updating} onClick={onUpdate}>
              {t("updateRequired:update")}
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </Box>
  );
}