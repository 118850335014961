import moment from "moment/moment";
import { FixNumberData } from "../orders";

export function convertTo(journalLines) {
  const lines = journalLines.map(line => makeLine(line));
  return lines.join("\n");
}

export function makeLine(journalLine) {

  let line = [];

  // ID
  line.push("Assist");

  // Owner ID
  line.push("");

  // Voucher Date IMP
  line.push( moment(journalLine.confirmedDate).format("YYYYMMDD"));

  // Due Date Imp
  line.push("");

  // Voucher NO
  line.push(journalLine.exportNumber);

  // Cash Reg No
  line.push(Limit(journalLine.confirmedBy?.name || "", 25));

  // Department
  line.push("");

  // Employee
  line.push("");

  // Cash Reg Code
  line.push("");

  // Subledger
  line.push("");

  // Invoice No
  line.push("");

  // XIdentifier
  line.push("");

  // Account
  line.push(journalLine.account);

  // Posting 1
  line.push("");

  // Posting 2
  line.push("");

  // X Posting
  line.push("");

  // Tax Rule
  line.push(journalLine.item.productGroup.vatCode.code);

  // Text
  line.push("");

  // Quantity
  line.push(FixNumberData(journalLine.item.quantity));

  // Amount
  line.push(journalLine.costPrice.value); // TODO calc total without tax

  // Dummy 1
  line.push("");

  // Dummy 2
  line.push("");

  // End of Line
  line.push("x");

  return line.join(";");
}

function Limit(text, limit = 25) {
  if (!text) return "";
  if (text.length <= limit) return text;
  return text.substring(0, limit - 1);
}