import { makeId } from "@emberly/zenith-client";
import Decimal from "decimal.js";
import { MissionEnums, OrderEnums } from "./constants";
import moment from "moment/moment";

const N100 = new Decimal("100");
const N1 = new Decimal("1");

const DEC_SEP = ",";//(0.1).toLocaleString().substring(1, 2);


export function sanitizeNumber(value) {
  if (typeof value === "undefined" || value === null || value === "") return "0";
  return value.replace(",",".").replace(" ","");
}

export function numberToDisplayDecimal(value) {
  return value?.replace(".", ",") || "0";
}

export function MakeNextOrderName(orders) {
  if (orders.length === 0) return "A";
  const last = FindLastOrder(orders);
  const lastIndex = parseInt(last.name, 36);
  return EnsureAlpha(lastIndex + 1).toString(36).toUpperCase();
}

export function FindLastOrder(orders) {
  let order = orders[0];

  for (let i = 1; i < orders.length; i++) {
    const o = orders[i];
    if (o.number > order.number) {
      order = o;
    }
  }

  return order;
}

export function EnsureAlpha(i) {
  if (i % 36 === 0) {
    return i + 10;
  } else {
    return i;
  }
}

export function MakeOrderLineItem(product, price, quantity, discount, productGroups, vatCodes) {
  const productGroup = productGroups.find(t => t.id === product.productGroup?.id );
  const vatCode = vatCodes.find(t => t.id === productGroup?.vatCode?.id);

  return {
    id: product.id,
    name: product.name,
    code: product.code,
    description: product.description,
    price,
    productGroup: {
      id: productGroup.id,
      code: productGroup.code,
      name: productGroup.name,
      account: productGroup.account,
      vatCode: {
        id: vatCode.id,
        code: vatCode.code,
        name: vatCode.name,
        rate: vatCode.rate,
      }
    },
    discount,
    quantity,
    isReferenced: true,
  };
}

export function MakeFreeTextOrderLineItem(name, price, quantity, discount, productGroup, vatCodes) {
  const vatCode = vatCodes.find(t => t.id === productGroup?.vatCode?.id);

  return {
    id: makeId(),
    name,
    price,
    productGroup: {
      id: productGroup.id,
      code: productGroup.code,
      name: productGroup.name,
      account: productGroup.account,
      vatCode: {
        id: vatCode.id,
        code: vatCode.code,
        name: vatCode.name,
        rate: vatCode.rate,
      }
    },
    discount,
    quantity,
    isReferenced: false,
  };
}

export function CalcTotal(price, quantity, discount = "0") {
  const priceNumber = new Decimal(sanitizeNumber(price.value));
  const discountDecimal = new Decimal(sanitizeNumber(discount)).div(N100);
  const quantityDecimal = new Decimal(sanitizeNumber(quantity)); 
  const a = (priceNumber.mul(quantityDecimal).mul(N1.minus(discountDecimal)));
  return a;
}


export function CalcProductionSum(orderLines) {
  let sum = new Decimal("0");

  for (let i = 0; i < orderLines.length; i++) {
    const item = orderLines[i];
    const priceNumber = new Decimal(sanitizeNumber(item.price.value));
    const discountDecimal = new Decimal(sanitizeNumber(item.discount)).div(N100);
    const quantityDecimal = new Decimal(sanitizeNumber(item.quantity));
    const a = (priceNumber.mul(quantityDecimal).mul(N1.minus(discountDecimal)));
    sum = sum.plus(a);
  }

  return sum;
}

export function CalcProductionVat(orderLines) {
  let sum = new Decimal("0");

  for (let i = 0; i < orderLines.length; i++) {
    const item = orderLines[i];
    const priceNumber = new Decimal(sanitizeNumber(item.price.value));
    const discountDecimal = new Decimal(sanitizeNumber(item.discount)).div(N100);
    const vatDecimal = new Decimal(sanitizeNumber(item.productGroup.vatCode.rate)).div(N100);
    const quantityDecimal = new Decimal(sanitizeNumber(item.quantity));
    const a = (priceNumber.mul(quantityDecimal).mul(N1.minus(discountDecimal))).mul(vatDecimal);
    sum = sum.plus(a);
  }

  return sum;
}

export function CalcProductionTotal(orderLines) {

  let sum = new Decimal("0");

  for (let i = 0; i < orderLines.length; i++) {
    const item = orderLines[i];
    const priceNumber = new Decimal(sanitizeNumber(item.price.value));
    const discountDecimal = new Decimal(sanitizeNumber(item.discount)).div(N100);
    const vatDecimal = new Decimal(sanitizeNumber(item.productGroup.vatCode.rate)).div(N100);
    const quantityDecimal = new Decimal(sanitizeNumber(item.quantity));
    const a = (priceNumber.mul(quantityDecimal).mul(N1.minus(discountDecimal))).mul(N1.plus(vatDecimal));
    sum = sum.plus(a);
  }

  return sum;
}



export function CalcToPaySum(entity, orders) {
  const { orderLines } = entity;

  let sum = new Decimal("0");

  for (let i = 0; i < orderLines.length; i++) {
    const item = orderLines[i];
    const priceNumber = new Decimal(sanitizeNumber(item.price.value));
    const discountDecimal = new Decimal(sanitizeNumber(item.discount)).div(N100);
    const quantityDecimal = new Decimal(sanitizeNumber(item.quantity));

    let a = (priceNumber.mul(quantityDecimal).mul(N1.minus(discountDecimal)));

    if (!entity.vatTransfer) {
      const vatDecimal = new Decimal(sanitizeNumber(item.productGroup.vatCode.rate)).div(N100);
      a = a.mul(N1.plus(vatDecimal));
    }

    sum = sum.plus(a);
  }

  if (entity.deductible) {
    sum = sum.plus(new Decimal(sanitizeNumber(entity.deductible.price.value)));
  }

  const deductibleTransferEntity = orders.find(t => t.deductible?.order?.id === entity.id);

  if (!!deductibleTransferEntity) {
    sum = sum.minus(deductibleTransferEntity.deductible.price.value);
  }

  const vatTransferEntity = orders.find(t => t.vatTransfer?.order?.id === entity.id);

  if (!!vatTransferEntity) {
    sum = sum.plus(CalcProductionVat(vatTransferEntity.orderLines));
  }

  return sum;
}

export function SumOrdersByPaymentMethod(orders, relatedOrders, paymentMethod) {
  return orders
    .filter(t => t.payment.method === paymentMethod)
    .map(t => CalcToPaySum(t, relatedOrders))
    .reduce((a, b) => a.plus(b), new Decimal("0"));
}

const CurrencyFormatter = new Intl.NumberFormat('nb-NO', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });

export function FixNumber(n, precision = 2) {
  if (n === undefined || n === null) return FixNumber(new Decimal(0), precision);
  return CurrencyFormatter.format(typeof n === "string" ? sanitizeNumber(n || "0.0") : n.toString()).replace(/\u00A0/g, " ");
}

export function FixNumberData(n, precision = 2) {
  if (n === undefined || n === null) return FixNumber(new Decimal(0), precision);
  const fixed = typeof n === "string" ? new Decimal(sanitizeNumber(n || "0.0")).toFixed(precision) : n.toFixed(2);
  return fixed.substring(0, fixed.length - 1 - precision) + DEC_SEP + fixed.substring(fixed.length - precision);
}

export function SetPaymentInfo(mission, entity, paymentMethod, updateEntityField) {
  const payment = entity.payment;
  
  switch (paymentMethod) {

    case OrderEnums.PaymentMethod.Invoice:
      if (!payment.invoice) {
        updateEntityField("payment.invoice", MakeInvoiceInfo(mission));
      }
      updateEntityField("payment.request", null);
      updateEntityField("payment.terminal", null);
      break;

    case OrderEnums.PaymentMethod.Cash:
    case OrderEnums.PaymentMethod.Card:
    case OrderEnums.PaymentMethod.MobilePayment:
      if (!payment.terminal) {
        updateEntityField("payment.terminal", MakeTerminalInfo(paymentMethod));
      }
      updateEntityField("payment.request", null);
      updateEntityField("payment.invoice", null);
      break;

    case OrderEnums.PaymentMethod.MobilePaymentRequest:
      if (!payment.request) {
        updateEntityField("payment.request", MakeRequestInfo());
      }
      updateEntityField("payment.terminal", null);
      updateEntityField("payment.invoice", null);
      break;

    default:
      updateEntityField("payment.terminal", null);
      updateEntityField("payment.invoice", null);
      updateEntityField("payment.request", null);
  }
}

export function MakeInvoiceInfo(mission) {
  return {
    state: OrderEnums.InvoiceState.None,
    orderDate: moment(mission?.created).utc().toISOString()
  };
}

export function MakeTerminalInfo(paymentMethod) {
  return {
    confirmed: false,
    service: paymentMethod === OrderEnums.PaymentMethod.MobilePayment ? "Vipps" : ""
  };
}

export function MakeRequestInfo() {
  return {
    confirmed: false,
    requested: false,
    service: "Vipps",
  };
}

export function UpdateSender(user, updateEntityField) {
  updateEntityField("payment.sender", { name: user.name, id: user.sub });
}

export function UpdateStateChanged(updateEntityField) {
  updateEntityField("payment.invoice.stateChangedDate", moment.utc().toISOString());
}

export function UpdateCustomer(customerEntity, updateEntityField) {
  if (!!customerEntity) {
    updateEntityField("customer",
      {
        name: customerEntity.name,
        id: customerEntity.id,
        location: customerEntity.location,
        email: customerEntity.email,
        description: customerEntity.description,
        contactNumber: customerEntity.number || customerEntity.contactNumber,
        phoneNumber: customerEntity.phoneNumber,
        customerNumber: customerEntity.customerNumber,
        organizationNumber: customerEntity.organizationNumber,
        isReferenced: !!customerEntity.id,
        billing: customerEntity.billing || {}
      }
    );
  }
}

export function CanChangePaymentMethod(payment) {
  
  switch (payment.method) {
    case OrderEnums.PaymentMethod.Invoice:
      return payment.invoice?.state === OrderEnums.InvoiceState.None;

    case OrderEnums.PaymentMethod.MobilePaymentRequest:
      return !(payment.request?.requested || payment.request?.confirmed);

    case OrderEnums.PaymentMethod.Card:
    case OrderEnums.PaymentMethod.Cash:
    case OrderEnums.PaymentMethod.MobilePayment:
      return !payment.terminal?.confirmed;

    default:
      return true;
  }
}

export function GetPaymentDate(payment) {

  switch (payment.method) {
    case OrderEnums.PaymentMethod.Invoice:
      return payment.invoice?.orderDate;

    case OrderEnums.PaymentMethod.MobilePaymentRequest:
      return payment.request?.confirmedDate;

    case OrderEnums.PaymentMethod.Card:
    case OrderEnums.PaymentMethod.Cash:
    case OrderEnums.PaymentMethod.MobilePayment:
      return payment.terminal?.confirmedDate;

    default:
      return null;
  }
}

export function GetOrderState(order) {

  if (!order) {
    return OrderEnums.OrderState.None;
  }

  const payment = order.payment;
  const { request, invoice, terminal } = payment;

  switch (payment.method) {
    case OrderEnums.PaymentMethod.Invoice:
      return GetOrderStateFromInvoiceState(invoice?.state);

    case OrderEnums.PaymentMethod.MobilePaymentRequest:
      return request.confirmed ? OrderEnums.OrderState.PaymentConfirmed : (request.requested ? OrderEnums.OrderState.PaymentRequested : OrderEnums.OrderState.Open);

    case OrderEnums.PaymentMethod.Card:
    case OrderEnums.PaymentMethod.Cash:
    case OrderEnums.PaymentMethod.MobilePayment:
      return !!terminal?.confirmed ? OrderEnums.OrderState.PaymentConfirmed : OrderEnums.OrderState.Open;

    default:
      return OrderEnums.OrderState.Open;
  }
}

export function GetOrderStateFromInvoiceState(invoiceState) {
  switch (invoiceState) {
    case OrderEnums.InvoiceState.None:
      return OrderEnums.OrderState.Open;

    case OrderEnums.InvoiceState.Queued:
    case OrderEnums.InvoiceState.Pending:
      return OrderEnums.OrderState.Ready;

    case OrderEnums.InvoiceState.Finished:
      return OrderEnums.OrderState.Invoiced;

    default:
      return OrderEnums.OrderState.Open;
  }
}

export function GetOrderStateColor(state) {
  switch (state) {
    case OrderEnums.OrderState.None:
    case OrderEnums.OrderState.Open:
      return undefined;

    case OrderEnums.OrderState.Invoiced:
    case OrderEnums.OrderState.PaymentConfirmed:
      return "success";

    case OrderEnums.OrderState.Ready:
    case OrderEnums.OrderState.PaymentRequested:
      return "info";

    default:
      return undefined;
  }
}




export function GetVatRate(id, productGroups, vatCodes) {
  return GetVatCode(id, productGroups, vatCodes)?.rate || "0";
}

export function GetVatCode(id, productGroups, vatCodes) {
  if (!id) return "0";
  
  const group = productGroups.find(t => t.id === id);
  
  if (!group) return "0";

  return vatCodes.find(t => t.id === group.vatCode?.id);
}


export function GetUserReference(user) {
  return { name: user.name, id: user.sub };
}


export function CanOrderBeDeleted(orderState, order, orders, mission) {
  if (orderState !== OrderEnums.OrderState.Open || mission.state === MissionEnums.State.Archived || !!order.deductible || !!order.vatTransfer) return false;

  if (
    !!orders.find(t => t.deductible?.order?.id === order.id) ||
    !!orders.find(t => t.vatTransfer?.order?.id === order.id)
  ) return false;

  return true;
}


export function GetMissionOrderState(orders) {
  if (orders.length === 0) return OrderEnums.MissionOrderState.None;


  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];

    if (GetOrderState(order) === OrderEnums.OrderState.Open) {
      return OrderEnums.MissionOrderState.Open;
    }
  }


  return OrderEnums.MissionOrderState.Closed;
}