

export const MapIcons = {
  Pins: {
    Home: "custom-home",
    Issue: "custom-issue",
    Resolved: "custom-resolved",
    Moving: "custom-moving",
    Person: "custom-person",
    Warehouse: "custom-warehouse"
  },
  Navigation: {
    Arrow: "nav-arrow",
    Car: "car-front"
  }
}


export const ActivityEnums = {
  Type: {
    None: 0,
    Text: 1,
    ReportState: 2,
    AssignedTask: 3,
    AcceptedTask: 4,
    RejectedTask: 5,
    TaskStarted: 6,
    TaskArrived: 7,
    TaskLoaded: 8,
    TaskCompleted: 9,
    TaskAcknowledged: 10,
    MissionDraftCreated: 11,
    MissionCreated: 12,
    MissionCompleted: 13,
    MissionCancelled: 14,
    MissionDeleted: 15,
    StoredObjectDelivered: 16,

    MissionPlaceholder2: 17,
    MissionPlaceholder3: 18,
    MissionPlaceholder4: 19,
    MissionPlaceholder5: 20,
    MissionPlaceholder6: 21,
    MissionPlaceholder7: 22,
    MissionPlaceholder8: 23,
    MissionPlaceholder9: 24,
    MissionPlaceholder10: 25,
    MissionPlaceholder11: 26,
    MissionPlaceholder12: 27,
    MissionPlaceholder13: 28,
    MissionPlaceholder14: 29,
    MissionPlaceholder15: 30,
    MissionPlaceholder16: 31,
    MissionPlaceholder17: 32,
    MissionPlaceholder18: 33,
    MissionPlaceholder19: 34,
    MissionPlaceholder20: 35,

    InvoiceJournalCreated: 36,
    CashJournalCreated: 37,
    InvoiceQueued: 38,
    InvoiceReOpened: 39,
    PaymentRequested: 40,
    PaymentConfirmed: 41,
    OrdersSettled: 42,
    SettlementsApproved: 43,
    SettlementApproved: 44,


  },
  Category: {
    None: 0,
    Mission: 1,
    Billing: 2,
    Other: 3
  },
}

export const ActivityEnumsLists = {
  Category: [
    "None",
    "Mission",
    "Billing",
    "Other",
  ],
  Type: [
    "None",
    "Text",
    "ReportState",
    "AssignedTask",
    "AcceptedTask",
    "RejectedTask",
    "TaskStarted",
    "TaskArrived",
    "TaskLoaded",
    "TaskCompleted",
    "TaskAcknowledged",
    "MissionDraftCreated",
    "MissionCreated",
    "MissionCompleted",
    "MissionCancelled",
    "MissionDeleted",
    "StoredObjectDelivered",


    "MissionPlaceholder2",
    "MissionPlaceholder3",
    "MissionPlaceholder4",
    "MissionPlaceholder5",
    "MissionPlaceholder6",
    "MissionPlaceholder7",
    "MissionPlaceholder8",
    "MissionPlaceholder9",
    "MissionPlaceholder10",
    "MissionPlaceholder11",
    "MissionPlaceholder12",
    "MissionPlaceholder13",
    "MissionPlaceholder14",
    "MissionPlaceholder15",
    "MissionPlaceholder16",
    "MissionPlaceholder17",
    "MissionPlaceholder18",
    "MissionPlaceholder19",
    "MissionPlaceholder20",

    "InvoiceJournalCreated",
    "CashJournalCreated",
    "InvoiceQueued",
    "InvoiceReOpened",
    "PaymentRequested",
    "PaymentConfirmed",
    "OrdersSettled",
    "SettlementsApproved",
    "SettlementApproved",

  ]
};

export const IntegrationEnums = {
  Service: {
    Unknown: 0,
    Tripletex: 1,
    Duett: 2,
    PowerOfficeGo: 3,
    Unimicro: 4,
    UnimicroSMB: 5,
    EikaRegnskap: 6,
    AzetsComplete: 7,
    DNBRegnskap: 8,
    SparebankenSor: 9,
    Sparebank1: 10,
    UnimicroDev: 11,
  },
  Category: {
    Unknown: 0,
    Accounting: 1,
  }
};


export const IntegrationEnumsLists = {
  Service: [
    "Unknown",
    "Tripletex",
    "Duett",
    "PowerOfficeGo",
    "Unimicro",
    "UnimicroSMB",
    "EikaRegnskap",
    "AzetsComplete",
    "DNBRegnskap",
    "SparebankenSor",
    "Sparebank1",
    "UnimicroDev",
  ]
};


export const ExportEnums = {
  ErrorCode: {
    Ok: 0,
    MissingDeductibleProductGroup: 10,
    MissingVatProductGroup: 11
  },
  ExportType: {
    Unknown: 0,
    CashJournal: 1,
    InvoiceJournal: 2,
  },
  CashExportFormats: {
    XL10: 0,
  },
  InvoiceExportFormats: {
    SO01: 0,
  }
};

export const ExportEnumsLists = {
  CashExportFormats: [
    "XL10"
  ],
  InvoiceExportFormats: [
    "SO01"
  ]
};

export const OrderEnums = {
  Currency: {
    None: 0,
    NOK: 1,
    USD: 2
  },
  PaymentMethod: {
    None: 0,
    Invoice: 1,
    MobilePaymentRequest: 2,
    Cash: 3,
    Card: 4,
    MobilePayment: 5
  },
  InvoiceState: {
    None: 0,
    Queued: 1,
    Pending: 2,
    Finished: 3
  },
  OrderState: {
    None: 0,
    Open: 1,
    Ready: 2,
    Invoiced: 3,
    PaymentRequested: 4,
    PaymentConfirmed: 5
  },
  MissionOrderState: {
    None: 0,
    Open: 1,
    Closed: 2
  }
}


export const OrderEnumsLists = {
  Currency: [
    "None",
    "NOK",
    "USD"
  ],
  PaymentMethod: [
    "None",
    "Invoice",
    "MobilePaymentRequest",
    "Cash",
    "Card",
    "MobilePayment"
  ],
  InvoiceState: [
    "None",
    "Queued",
    "Pending",
    "Finished",
  ],
  OrderState: [
    "None",
    "Open",
    "Ready",
    "Invoiced",
    "PaymentRequested",
    "PaymentConfirmed"
  ],
  MissionOrderState: [
    "None",
    "Open",
    "Closed"
  ]
}


export const TaskEnums = {
  State: {
    None: 0,
    Created: 1,
    Started: 2,
    Arrived: 3,
    Loaded: 4,
    Completed: 5,
    Acknowledged: 6
  },
  DriverAssignedState: {
    Unknown: 0,
    Pending: 1,
    Accepted: 2,
    Rejected: 3
  },
  Type: {
    None: 0,
    Salvage: 1,
    Storage: 2
  }
};

export const TaskEnumsLists = {
  State: [
    "None",
    "Created",
    "Started",
    "Arrived",
    "Loaded",
    "Completed",
    "Acknowledged",
  ],
  DriverAssignedState: [
    "Unknown",
    "Pending",
    "Accepted",
    "Rejected"
  ]
};


export const MissionEnums = {
  State: {
    None: 0,
    Draft: 1,
    Cancelled: 2,
    Created: 3,
    Completed: 4,
    Archived: 5
  },
  StorageState: {
    None: 0,
    Stored: 1,
    Delivered: 2
  },
  Type: {
    Unknown: 0,
    Salvage: 1,
    Towing: 2,
    Other: 3
  }
}

export const MissionEnumsLists = {
  State: [
    "None",
    "Draft",
    "Cancelled",
    "Created",
    "Completed",
    "Archived"
  ],
  Type: [
    "Unknown",
    "Salvage",
    "Towing",
    "Other"
  ],
  FuelType: [
    "Unknown",
    "Petrol",
    "Diesel",
    "Electric",
    "Other"
  ],
  Cause: [
    "Unknown"
  ],
  TargetType: [
    "Unknown",
    "Car",
    "Boat",
    "Other"
  ],
  DriveTrain: [
    "Unknown",
    "FWD",
    "RWD",
    "AWD",
    "Other"
  ],
  LocationState: [
    "Unknown",
    "NoOneAtLocation",
    "InPlace",
    "PoliceWaiting",
    "Phone"
  ],
  Currency: [
    "Unknown",
    "NOK",
    "USD"
  ],
  CurrencyUnit: [
    "Unknown",
    "kr",
    "$"
  ],
  InsuranceState: [
    "Unknown",
    "NotOK",
    "OKWithDeviations",
    "OKAccordingToCustomer",
    "OK",
  ],
  InsuranceType: [
    "Unknown",
    "Liability",
    "PartialCasco",
    "Casco",
    "Other",
  ],
  DriverAssignedState: [
    "Unknown",
    "Pending",
    "Accepted",
    "Rejected",
  ],
  Default: 0
};

export const Roles = {
  GlobalAdmin: "global-admin",
  StationAdmin: "station-admin",
  Driver: "driver",
  Accountant: "accountant",
  Operator: "operator"
};

export const RoleIds = {
  GlobalAdmin: "rol_eqYkEsthAmG4BTv8",
  Operator: "rol_7Io4yhg0aPkLa8rM",
  StationAdmin: "rol_0vUS6IfcdOV9wGRs",
  Driver: "rol_kVta8OMTCvqzePdE",
  Accountant: "rol_P6JVFqQdluoq1ezw"
};

export const RoleMap = {
  "rol_7Io4yhg0aPkLa8rM": "operator",
  "rol_0vUS6IfcdOV9wGRs": "station-admin",
  "rol_kVta8OMTCvqzePdE": "driver",
  "rol_P6JVFqQdluoq1ezw": "accountant",
  "rol_eqYkEsthAmG4BTv8": "global-admin"
};


export const DRIVER_ROLE_ID = "rol_kVta8OMTCvqzePdE";

export const ROLES_CLAIM = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";